import { isValidElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Amount } from 'bl-common/src/elements/Amount'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'

import { FlowCardButtonField, FlowComponent } from '../../types'
import { getFlowValue } from '../../utils'
import * as styles from './styles'

type CardButtonFieldProps = FlowComponent & FlowCardButtonField['props']

export const CardButtonField = ({
  title,
  description,
  onClick,
  secondaryOnClick,
  image,
  ctaLabel = 'Select',
  secondaryCtaLabel = 'Details',
  inCartLabel = 'In cart',
  linePrice,
  price,
  priceFormat = '%p',
  isClosed,
  smallPrint,
  disclaimer,
  inCart,
  control,
  isDisabled,
  pricePosition = 'bottom',
  themeStyle,
  screenTheme,
  linkProps,
  focusCardLabel,
}: CardButtonFieldProps) => {
  const { t } = useTranslation('booking') //TODO: switch out to use new translation system
  const theme = useTheme()
  const titleValue = getFlowValue(title, control)
  const descriptionValue = getFlowValue(description, control)
  const imageValue = getFlowValue(image, control)
  const ctaLabelValue = getFlowValue(ctaLabel, control)
  const secondaryCtaLabelValue = getFlowValue(secondaryCtaLabel, control)
  const isClosedValue = getFlowValue(isClosed, control)
  const inCartValue = getFlowValue(inCart, control)
  const inCartLabelValue = getFlowValue(inCartLabel, control)
  const isDisabledValue = getFlowValue(isDisabled, control)
  const linePriceValue = getFlowValue(linePrice, control)
  const priceValue = getFlowValue(price, control)
  const priceFormatValue = getFlowValue(priceFormat, control)
  const pricePositionValue = getFlowValue(pricePosition, control)
  const focusCardLabelValue = getFlowValue(focusCardLabel, control)

  const arrowIcon = useMemo(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="1ch"
        viewBox="0 0 12 10"
        style={{ marginLeft: 6 }}
        aria-hidden
      >
        <path
          fill={
            themeStyle?.svgColor
              ? themeStyle?.svgColor
              : theme.bookingEngine?.[screenTheme]?.cardButtonField?.svgColor
                ? theme.bookingEngine?.[screenTheme]?.cardButtonField?.svgColor
                : isClosedValue
                  ? colors.grey
                  : colors.deepBlue
          }
          d="M11.7816 4.10794l-.5325-.52738L7.90438.22037c-.29248-.29383-.76494-.29383-1.05742 0-.29248.29383-.29248.76847 0 1.0623l2.5948 2.60678H.74994C.33747 3.88945 0 4.22848 0 4.64286c0 .41437.33747.7534.74994.7534h8.69182l-2.5948 2.60678c-.29248.29383-.29248.76848 0 1.0623.29248.29383.76494.29383 1.05742 0l3.34472-3.36018.525-.52739c.2999-.30136.2999-.776.0075-1.06983z"
        />
      </svg>
    ),
    [isClosedValue]
  )

  const inCartIcon = useMemo(() => {
    const svgColor =
      themeStyle?.svgColor ||
      theme?.bookingEngine?.[screenTheme]?.cardButtonField?.svgColor ||
      colors.deepBlue

    return (
      <svg
        height="2ch"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: 6 }}
        aria-hidden
      >
        <path
          d="M5.77612 8.49275L9.92538 13.5077L17.209 4.01514"
          stroke={svgColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M17.709 8.23461L17.6708 8.28153L17.6839 8.34055C17.8013 8.86846 17.8601 9.42651 17.8601 9.98507C17.8601 14.334 14.334 17.8601 9.98507 17.8601C5.6362 17.8601 2.11007 14.334 2.11007 9.98507C2.11007 5.6362 5.6362 2.11007 9.98507 2.11007C11.2188 2.11007 12.3947 2.40379 13.4541 2.90408L13.5468 2.94788L13.6079 2.86545L14.2049 2.05948L14.2927 1.94102L14.1615 1.87382C12.9202 1.23807 11.4978 0.875 9.98507 0.875C4.96082 0.875 0.875 4.96082 0.875 9.98507C0.875 15.0093 4.96082 19.0951 9.98507 19.0951C15.0093 19.0951 19.0951 15.0093 19.0951 9.98507C19.0951 9.04593 18.9436 8.16857 18.7023 7.32387L18.6364 7.09318L18.4851 7.27939L17.709 8.23461Z"
          fill={svgColor}
          stroke={svgColor}
          strokeWidth="0.25"
        />
      </svg>
    )
  }, [])

  const renderButtonContent = useMemo(() => {
    const buttonWeight = themeStyle?.button?.fontWeight ?? 'bold'
    const buttonStyle = {
      ...theme?.bookingEngine?.[screenTheme]?.cardButtonField?.button,
      paddingBottom: 2,
    }

    const renderContent = (label, weight = buttonWeight) => (
      <Type preset="text" weight={weight} style={buttonStyle}>
        {label}
      </Type>
    )

    if (inCartValue) {
      return (
        <styles.ButtonContent>
          {inCartIcon} {renderContent(inCartLabelValue)}
        </styles.ButtonContent>
      )
    }

    if (isClosedValue) {
      return (
        <styles.ButtonContent>
          {renderContent(
            disclaimer || t('booking.extras.unavailable'),
            themeStyle?.button?.fontWeight ?? 'medium'
          )}
        </styles.ButtonContent>
      )
    }

    return (
      <styles.ButtonContent>
        {renderContent(ctaLabelValue)} <span>{arrowIcon}</span>
      </styles.ButtonContent>
    )
  }, [isClosedValue, inCartValue, inCartLabelValue, disclaimer])

  const priceContent = useMemo(() => {
    return (
      <Type
        preset="labelSmall"
        aria-hidden={!!priceValue}
        weight="bold"
        style={{
          flexShrink: priceFormatValue.length <= 2 && !linePrice && 0,
          ...themeStyle?.price,
        }}
      >
        {priceValue && !isClosedValue ? (
          <styles.PricesWrapper>
            {typeof priceValue === 'number' ? (
              <Amount
                value={priceValue}
                format={priceFormatValue}
                lineThrough={!!linePrice}
              />
            ) : (
              priceValue
            )}
            {linePrice && (
              <styles.discountPrice>
                <Amount value={linePriceValue} format={priceFormatValue} />
              </styles.discountPrice>
            )}

            {!!smallPrint && <span>{` ${smallPrint}`}</span>}
          </styles.PricesWrapper>
        ) : (
          ''
        )}
      </Type>
    )
  }, [priceValue, isClosedValue, priceFormatValue, smallPrint])

  const ButtonContentWrapper = ({ children }) =>
    linkProps ? (
      <styles.LinkWrapper
        preset="text"
        as="a"
        href={linkProps.href}
        isDisabled={isDisabledValue}
        onClick={() => onClick(control)}
        {...(linkProps.isExternalLink && {
          target: '_blank',
          rel: 'noopener noreferrer',
        })}
      >
        {children}
      </styles.LinkWrapper>
    ) : (
      <styles.Button
        preset="text"
        as="button"
        type="button"
        onClick={() => onClick(control)}
        isDisabled={isDisabledValue}
        disabled={isDisabledValue}
        inCart={inCartValue}
        themeStyle={themeStyle?.button}
      >
        {children}
      </styles.Button>
    )

  return (
    <styles.Card
      themeStyle={
        themeStyle?.card ||
        theme?.bookingEngine?.[screenTheme]?.cardButtonField?.card
      }
      focusCard={!!focusCardLabelValue}
    >
      {imageValue && (
        <styles.ImageWrap>
          <ContentfulImage
            fill
            alt="Blue Lagoon Product"
            sizes="(min-width: 75em) 25vw, (min-width: 48em) 25vw, 50vw"
            image={imageValue}
          />
        </styles.ImageWrap>
      )}
      <styles.Content>
        {!!focusCardLabelValue && (
          <Type
            preset="labelSmall"
            weight="bold"
            style={{
              padding: '3px 12px',
              border:
                themeStyle?.card?.focusLabelBorder ??
                `1px solid ${themeStyle?.button?.color ?? colors.deepBlue}`,
              width: 'min-content',
              background:
                themeStyle?.card?.focusLabelBackground ?? colors.white,
              marginBottom: theme.spacing[0.5],
              marginTop: `calc(-1 * ${theme.spacing[0.5]})`,
              whiteSpace: 'nowrap',
              borderRadius: themeStyle?.card?.focusLabelBorderRadius ?? 0,
            }}
          >
            {focusCardLabelValue}
          </Type>
        )}

        <styles.TopWrapper>
          <Type preset="label">{titleValue}</Type>
          {pricePositionValue === 'top' && priceContent}
        </styles.TopWrapper>
        {description &&
          (isValidElement(descriptionValue) ? (
            descriptionValue
          ) : (
            <Type preset="text" top={{ xs: 1 }} bottom={{ xs: 1 }}>
              {descriptionValue}
            </Type>
          ))}
        <styles.Footer>
          {pricePositionValue === 'bottom' && priceContent}

          {secondaryCtaLabelValue && secondaryOnClick && (
            <styles.SecondaryButton
              preset="text"
              as="button"
              type="button"
              weight="bold"
              onClick={e => {
                e.stopPropagation()
                secondaryOnClick(control)
              }}
              isDisabled={isDisabledValue}
              disabled={isDisabledValue}
              themeStyle={themeStyle?.button}
            >
              {secondaryCtaLabel}
            </styles.SecondaryButton>
          )}
          <ButtonContentWrapper>{renderButtonContent}</ButtonContentWrapper>
        </styles.Footer>
        <styles.Line selected={inCartValue} themeStyle={themeStyle?.line} />
      </styles.Content>
    </styles.Card>
  )
}

import { useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'
import styled, { useTheme } from 'styled-components'

import { SimpleAccordion } from 'bl-common/src/booking/SimpleAccordion/SimpleAccordion'
import { colors } from 'bl-common/src/constants/colors'
import { globalBookingMessages } from 'bl-flows/src/messages'
import { isValidGiftCard } from 'bl-utils/src/giftcards/isValidGiftCard'

import { FlowComponent, FlowDiscountAccordionField } from '../../types'
import { getFlowValue } from '../../utils'
import ActiveDiscountCode from './ActiveDiscountCode'
import DiscountField from './DiscountField'
import { DiscountCode, DiscountCodeState, DiscountType } from './types'

// TODO check for empty fields in cart after code is applied
// const hasDiscount = (booking: any) => {
//   if (booking.discountedAdultPrice !== null) {
//     return true
//   }
//   if (booking.discountedChildrenPrice !== null) {
//     return true
//   }
//   if (booking.busTransfer?.discountedAdultPrice !== null) {
//     return true
//   }
//   if (booking.massage?.some(massage => massage.discountedPrice !== null)) {
//     return true
//   }
// }

// const hasGiftCard = (booking: any) => {
//   return (
//     booking.giftCardPaymentAmount !== null &&
//     booking.giftCardAmount !== null &&
//     booking.giftCardPaymentAmount !== 0
//   )
// }

const FieldWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`

const ContentContainer = styled.div`
  display: grid;
  gap: 10px;
`

const AddRowButton = styled.button`
  cursor: pointer;
  text-align: left;
  justify-self: flex-start;
  &[hidden='true'] {
    display: none;
  }
`

type DiscountAccordionProps = FlowComponent &
  FlowDiscountAccordionField['props'] & {
    id: string
  }

export const DiscountAccordion = ({
  control,
  screenTheme,
  ...props
}: DiscountAccordionProps) => {
  const [isLoading] = useState(false)
  const label = getFlowValue(props.label, control)
  const activePromoCode = getFlowValue(props.activePromoCode, control)
  const activeGiftCards = getFlowValue(props.activeGiftCards, control)
  const [fieldErrors, setFieldErrors] = useState({})

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.discountAccordionField
  const inputThemeStyle = theme?.bookingEngine?.[screenTheme]?.inputTextField

  const activeCodes = [...(activeGiftCards ?? []), activePromoCode].filter(
    c => c != null
  )

  const [codeFields, setCodeFields] = useState<DiscountCode[] | null>(() => {
    if (activeCodes.length > 0) {
      return activeCodes.reduce((arr, cur, i) => {
        if (typeof cur === 'string') {
          return [
            ...arr,
            {
              key: i,
              code: cur,
              state: DiscountCodeState.applied,
              type: DiscountType.promoCode,
            },
          ]
        } else {
          return [
            ...arr,
            {
              key: i,
              code: cur.no,
              balance: cur.balance,
              chargeAmount: cur.chargeAmount,
              remaining: cur.balance - cur.chargeAmount,
              state: DiscountCodeState.applied,
              type: DiscountType.giftCard,
            },
          ]
        }
      }, [])
    }
    return [
      {
        code: '',
        state: DiscountCodeState.initial,
        key: 0,
      },
    ]
  })

  const addCodeField = () => {
    const prevKey = codeFields.reduce((a, { key }) => Math.max(a, key), 0)

    setCodeFields([
      ...codeFields,
      {
        code: '',
        state: DiscountCodeState.initial,
        key: prevKey + 1,
      },
    ])
  }

  const removeCode = (code: string) => {
    if (isValidGiftCard(code)) {
      props.removeGiftCard(code, control)
    } else {
      props.addPromoCode('', control)
    }

    const remainingCodes = [...codeFields.filter(c => c.code !== code)]

    const discountCodes = isEmpty(remainingCodes)
      ? [
          {
            code: '',
            state: DiscountCodeState.initial,
            key: 0,
          },
        ]
      : remainingCodes

    setCodeFields(discountCodes)
  }

  const applyCode = async (codeInput, index: number) => {
    const updatedCodeFields = [...codeFields]

    updatedCodeFields[index] = {
      ...updatedCodeFields[index],
      ...codeInput,
      state: DiscountCodeState.applied,
    }

    try {
      if (isValidGiftCard(codeInput.code)) {
        const res = await props.addGiftCard(codeInput.code, control)
        const addedGiftCard = res?.data?.addGiftCard?.giftCards?.find(
          gc => gc.no === codeInput.code
        )

        if (addedGiftCard) {
          updatedCodeFields[index] = {
            ...updatedCodeFields[index],
            balance: addedGiftCard.balance,
            chargeAmount: addedGiftCard.chargeAmount,
            remaining: addedGiftCard.balance - addedGiftCard.chargeAmount,
          }
        }
      } else {
        await props.addPromoCode(codeInput.code, control)
      }

      setCodeFields(updatedCodeFields)

      control.screen.setState({
        [`${props.id}[${index}]`]: codeInput.code,
      })
    } catch (e) {
      console.warn('Error applying discount code', e)
      setFieldErrors({
        [`${props.id}[${index}]`]: control.context.t(
          globalBookingMessages.errors.notValid
        ),
      })

      updatedCodeFields[index] = {
        ...updatedCodeFields[index],
        ...codeInput,
        state: DiscountCodeState.invalid,
      }
      setCodeFields(updatedCodeFields)
    }
  }

  const onSubmit = async (code, index) => {
    if (!code || code.length === 0) {
      return
    }
    const isGiftCardNo = isValidGiftCard(code)
    const isPromoCode = !isGiftCardNo

    if (isPromoCode && hasAppliedPromoCodes) {
      invalidateCode(
        code,
        index,
        `*${control.context.t(globalBookingMessages.errors.onePromoPerBooking)}`
      )

      return
    }

    await applyCode?.(
      {
        code,
        type: isGiftCardNo ? DiscountType.giftCard : DiscountType.promoCode,
        state: DiscountCodeState.applied,
      },
      index
    )
  }

  const invalidateCode = (code, index: number, message) => {
    const newCodes = [
      ...codeFields.slice(0, index),
      {
        ...codeFields[index],
        code,
        state: DiscountCodeState.invalid,
      },
      ...codeFields.slice(index + 1),
    ]
    setCodeFields(newCodes)
    setFieldErrors({
      [`${props.id}[${index}]`]: message,
    })
  }

  // TODO read remaining amount for giftcard fields

  // const remaining = useAmount(
  //   state.booking.prices?.giftCardAmount -
  //     state.booking.prices?.giftCardPaymentAmount,
  //   '',
  //   true
  // )

  const hasAppliedPromoCodes = useMemo(
    () =>
      codeFields?.some(
        ({ type, state }) =>
          type === DiscountType.promoCode && state === DiscountCodeState.applied
      ) ?? false,
    [codeFields]
  )

  return (
    <FieldWrapper>
      <SimpleAccordion
        label={label}
        withBorder={false}
        labelColor={themeStyle?.accordion?.labelColor ?? colors.dark}
        startExpanded
      >
        <ContentContainer>
          <AnimatePresence>
            {codeFields.map(
              ({ code, state: codeState, key, chargeAmount, remaining }, i) => {
                const isGiftCard = code && isValidGiftCard(code)
                return (
                  <motion.div
                    key={`discount_${key}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {codeState === DiscountCodeState.applied ? (
                      <ActiveDiscountCode
                        themeStyle={themeStyle?.activeDiscountCode}
                        removeLabel={control.context.t(
                          globalBookingMessages.buttons.remove
                        )}
                        code={code}
                        type={
                          isGiftCard
                            ? DiscountType.giftCard
                            : DiscountType.promoCode
                        }
                        text={
                          isGiftCard
                            ? control.context.t(
                                globalBookingMessages.labels.giftCardActive,
                                {
                                  giftCard: code,
                                  remaining,
                                  chargeAmount,
                                }
                              )
                            : control.context.t(
                                globalBookingMessages.labels.promoCodeActive,
                                {
                                  promoCode: code,
                                }
                              )
                        }
                        onClose={() => removeCode(code)}
                        isLoading={isLoading}
                      />
                    ) : (
                      <DiscountField
                        {...props}
                        control={control}
                        id={`${props.id}[${key}]`}
                        onSubmit={code => onSubmit(code, i)}
                        initialValue={codeFields[i].code}
                        error={
                          codeState === DiscountCodeState.invalid &&
                          fieldErrors[`${props.id}[${key}]`]
                        }
                        inputThemeStyle={inputThemeStyle}
                      />
                    )}
                  </motion.div>
                )
              }
            )}
          </AnimatePresence>
          <AddRowButton
            type="button"
            onClick={addCodeField}
            style={{ color: themeStyle?.addRowColor ?? colors.black }}
          >
            {control.context.t(globalBookingMessages.buttons.addRow)}
          </AddRowButton>
        </ContentContainer>
      </SimpleAccordion>
    </FieldWrapper>
  )
}

export default DiscountAccordion

import { FlowComponent, FlowCustomField } from '../../types'

type CustomFieldProps = FlowComponent & FlowCustomField['props']

export const CustomField = (props: CustomFieldProps) => {
  if (props.render) {
    return props.render(props.control)
  }
  if (props.component) {
    const Component = props.component

    return <Component {...props} />
  }

  console.warn('Custom field is missing component prop')
  return null
}

import { Stepper } from 'bl-common/src/elements/Stepper'
import { CheckList } from 'bl-common/src/units/CheckList'
import { WelcomeGuideMediaPlayer } from 'bl-common/src/units/WelcomeGuide/WelcomeGuideMediaPlayer'
import {
  buildButton,
  buildCheckboxInputField,
  buildCustomField,
  buildFlow,
  buildProgressButton,
  buildScreenErrorField,
  buildScreenWithSmallImageLayout,
  buildText,
  FlowControl,
  FlowScreen,
} from 'bl-flows-core'
import { AtLeastOne } from 'bl-flows-core/src/types/typeUtils'

import { defaultRequiredValidator } from '../../utils/validation'
import { preCheckinMessages } from './preCheckinMessages'

type TCheckInStates =
  | 'CHECK_IN_SUCCESS'
  | 'ALREADY_CHECKED_IN'
  | 'CHECK_IN_FAILED'

interface PreCheckinInitialData {
  welcomeGuideContent: any // Welcome guide content types currently do not support code gen
  alreadyCheckedIn: boolean
  isCheckInOpen: boolean
  onStart: () => void
}

const getDynamicScreenContent = (control: FlowControl) => {
  const checkInState = control.screen.setupHook.checkInState as TCheckInStates
  switch (checkInState) {
    case 'CHECK_IN_SUCCESS':
      return {
        title: control.context.t(preCheckinMessages.checkInCompleteTitle),
        subtitle: control.context.t(preCheckinMessages.checkInCompleteInfo),
        buttonLabel: control.screen.uiState?.linkCopied
          ? control.context.t(preCheckinMessages.linkCopiedLabel)
          : control.context.t(preCheckinMessages.copyLinkButtonLabel),
      }
    case 'ALREADY_CHECKED_IN':
      return {
        title: control.context.t(preCheckinMessages.alreadyCheckedInTitle),
        subtitle: control.context.t(
          preCheckinMessages.alreadyCheckedInSubtitle
        ),
        buttonLabel: control.context.t(
          preCheckinMessages.alreadyCheckedButtonLabel
        ),
        onClickButton: (control: FlowControl) => {
          control.flow.setupHook.onClose()
        },
      }

    case 'CHECK_IN_FAILED':
      return {
        title: control.context.t(preCheckinMessages.preCheckinFailedTitle),
        subtitle: control.context.t(
          preCheckinMessages.preCheckinFailedSubtitle
        ),
        buttonLabel: control.context.t(preCheckinMessages.startOverLabel),
        onClickButton: () => {
          window.location.reload()
        },
      }
  }
}

const renderWelcomeGuideStep = (
  step,
  media,
  content,
  onStart,
  { stepIndex, stepsLength }
) => {
  const isFirstStep = stepIndex === 0
  return buildScreenWithSmallImageLayout({
    id: `welcomeGuide-${stepIndex}`,
    subType: 'form',
    breadcrumb: false,
    layoutProps: {
      layoutId: step.fields.title,
      ImageComponent: (
        <WelcomeGuideMediaPlayer media={media} step={stepIndex} />
      ),
    },
    columnStyle: true,
    fields: {
      main: [
        buildCustomField({
          id: 'stepper',
          defaultValue: null,
          condition: () => stepIndex !== 0,
          props: {
            component: props => (
              <Stepper
                stepsLength={stepsLength - 1}
                currentStepIndex={stepIndex}
                width={'100%'}
                useLinkNavigation={false}
                canSkipForward={false}
                onClick={stepIndex => props.control.goToScreen(stepIndex + 1)}
              />
            ),
          },
          layout: {
            spacing: {
              mt: { xs: 6, bmd: 0 },
              mb: { xs: 1 },
            },
          },
        }),
        buildText({
          props: {
            type: 'headingMedium',
            value: step.fields.title,
            textAlign: 'center',
          },
          layout: {
            spacing: {
              mt: { xs: isFirstStep ? 11 : 4, bmd: 2 },
              mb: { xs: 1 },
            },
          },
        }),
        buildText({
          condition: () => !!step.fields.subtitle,
          props: {
            type: 'paragraph',
            value: step.fields.subtitle,
            textAlign: 'center',
          },
          layout: {
            spacing: {
              mb: { xs: 1 },
            },
          },
        }),
        buildButton({
          condition: control =>
            !!step.fields.description &&
            !control.screen.uiState.descriptionExpanded,
          props: {
            preset: 'transparent',
            label: content.readMore,
            onClick: control => {
              control.screen.setUiState({ descriptionExpanded: true })
            },
          },
        }),
        buildText({
          condition: control => !!control.screen.uiState.descriptionExpanded,
          props: {
            type: 'paragraph',
            value: step.fields.description,
            textAlign: 'center',
          },
          layout: {
            spacing: {
              mb: { xs: 1 },
            },
          },
        }),
        buildButton({
          props: {
            label: isFirstStep ? content.continue : content.next,
            onClick(control) {
              if (isFirstStep) {
                onStart()
              }
              control.nextScreen()
            },
          },
          layout: {
            marginTopAuto: { xs: true },
            spacing: {
              mb: { xs: 1 },
            },
            width: { xs: '90vw', md: '30vw' },
            maxWidth: { xs: '350px' },
          },
        }),
      ],
    },
  })
}

export const buildPreCheckInFlow = (
  onComplete: () => Promise<boolean>,
  onClose: (control: FlowControl) => void,
  initialData: PreCheckinInitialData
) => {
  const steps = initialData.welcomeGuideContent.steps
  const media = steps.map(step => step.fields.video)

  return buildFlow({
    id: 'completeCheckinFlow',
    routerSettings: {
      updateHistory: false,
    },
    settings: {
      resetWhenOpened: true,
    },
    setupHook: (_, control) => {
      return {
        onClose,
        checkListItems: [
          control.context.t(preCheckinMessages.checkListItem1),
          control.context.t(preCheckinMessages.checkListItem2),
          control.context.t(preCheckinMessages.checkListItem3),
          control.context.t(preCheckinMessages.checkListItem4),
          control.context.t(preCheckinMessages.checkListItem5),
        ],
      }
    },
    children: [
      ...(steps.map((step, i) =>
        renderWelcomeGuideStep(
          step,
          media,
          initialData.welcomeGuideContent,
          initialData.onStart,
          {
            stepsLength: steps.length,
            stepIndex: i,
          }
        )
      ) as AtLeastOne<FlowScreen>),
      buildScreenWithSmallImageLayout({
        id: 'checkList',
        subType: 'form',
        breadcrumb: false,
        condition: () => !initialData.alreadyCheckedIn,
        layoutProps: {
          layoutId: 'checkList',
          layoutImageSrc:
            'https://images.ctfassets.net/w65k7w0nsb8q/7IyFkHz6p3dKhXTTTqFoWi/daeb2d788557c2e2569f3d600b1ed626/Blue_Lagoon_2437__3_.jpg?w=1920&q=75',
          hideImageOnMobile: true,
        },
        columnStyle: true,
        fields: {
          main: [
            buildText({
              props: {
                type: 'headingMedium',
                value: control =>
                  control.context.t(preCheckinMessages.completeCheckin),
                textAlign: 'center',
              },
              layout: {
                spacing: {
                  mt: { xs: 4, bmd: 2 },
                },
              },
            }),

            buildCustomField({
              defaultValue: null,
              layout: {
                spacing: {
                  mt: { xs: 1 },
                  mb: { xs: 1 },
                },
              },
              props: {
                component: props => (
                  <CheckList
                    items={props.control.flow?.setupHook?.checkListItems}
                  />
                ),
              },
            }),

            buildCheckboxInputField({
              id: 'agreeToRead',
              props: {
                label: control =>
                  control.context.t(preCheckinMessages.checkBoxLabel),
              },
              layout: {
                marginTopAuto: { xs: true },
                spacing: {
                  mt: { xs: 2 },
                  mb: { xs: 1 },
                },
              },
              validation: {
                validator: defaultRequiredValidator,
              },
            }),
            buildScreenErrorField({
              props: { fieldIdsToRender: ['checkInClosed'] },
              layout: {
                spacing: { mb: { xs: 1 } },
              },
            }),
            buildProgressButton({
              layout: {
                bottom: { xs: 0 },
                spacing: {
                  mb: { xs: 1 },
                },
                width: { xs: '90vw', md: '30vw' },
                maxWidth: { xs: '350px' },
              },
              props: {
                label: control =>
                  control.context.t(preCheckinMessages.confirmButtonLabel),
                onClick: async control => {
                  const { hasErrors } = control.validateAndSetScreenErrors()
                  if (hasErrors) {
                    return false
                  }
                  // We do not allow the user to check in if check in is closed (x hours before arrival).
                  // We are doing this in the client because the API currently doesn't provide any info about this
                  if (!initialData.isCheckInOpen) {
                    control.screenErrors.setState({
                      checkInClosed: control.context.t(
                        preCheckinMessages.preCheckInClosedError
                      ),
                    })
                    return false
                  }
                  const success = await onComplete()
                  if (!success) {
                    control.flow.setState({
                      preCheckInSuccess: false,
                    })
                    control.nextScreen()
                    return false
                  }

                  control.flow.setState({ preCheckInSuccess: true })
                  return true
                },
                onComplete(control) {
                  control.nextScreen()
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithSmallImageLayout({
        id: 'confirmation',
        subType: 'section',
        breadcrumb: false,
        setupHook: control => {
          let checkInState: TCheckInStates

          if (control.flow.state.preCheckInSuccess) {
            checkInState = 'CHECK_IN_SUCCESS'
          } else if (initialData.alreadyCheckedIn) {
            checkInState = 'ALREADY_CHECKED_IN'
          } else {
            checkInState = 'CHECK_IN_FAILED'
          }

          return {
            checkInState,
          }
        },
        layoutProps: {
          layoutId: 'confirmation',
          layoutImageSrc:
            'https://images.ctfassets.net/w65k7w0nsb8q/7IyFkHz6p3dKhXTTTqFoWi/daeb2d788557c2e2569f3d600b1ed626/Blue_Lagoon_2437__3_.jpg?w=1920&q=75',
        },
        columnStyle: true,
        fields: {
          main: [
            buildText({
              props: {
                type: 'headingMedium',
                value: control => getDynamicScreenContent(control).title,
                textAlign: 'center',
              },
              layout: {
                spacing: {
                  mt: { xs: 4, md: 2 },
                },
              },
            }),
            buildText({
              props: {
                type: 'paragraph',
                value: control => getDynamicScreenContent(control).subtitle,
                textAlign: 'center',
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                  mb: { xs: 1 },
                },
              },
            }),
            buildText({
              condition: control =>
                control.screen.setupHook.checkInState === 'CHECK_IN_SUCCESS',
              props: {
                type: 'label',
                preset: 'labelLarge',
                value: control =>
                  control.context.t(preCheckinMessages.copyLinkButtonInfo),
                textAlign: 'center',
              },
              layout: {
                marginTopAuto: { xs: true },
                spacing: {
                  mt: { xs: 3 },
                  mb: { xs: 1 },
                },
              },
            }),

            buildProgressButton({
              condition: control =>
                control.screen.setupHook.checkInState === 'CHECK_IN_SUCCESS',
              layout: {
                spacing: {
                  mb: { xs: 1 },
                },
                width: { xs: '90vw', md: '30vw' },
                maxWidth: { xs: '350px' },
              },
              props: {
                label: control => getDynamicScreenContent(control).buttonLabel,
                onClick: async control => {
                  await navigator?.clipboard?.writeText?.(
                    'https://bluelagoon.com/welcome'
                  )
                  control.screen.setUiState({ linkCopied: true })
                  return true
                },
                onComplete(control) {
                  control.nextScreen()
                },
              },
            }),
            buildButton({
              condition: control =>
                control.screen.setupHook.checkInState !== 'CHECK_IN_SUCCESS',
              layout: {
                marginTopAuto: { xs: true },
                spacing: { mb: { xs: 1 } },
                width: { xs: '90vw', md: '30vw' },
                maxWidth: { xs: '350px' },
              },
              props: {
                label: control => getDynamicScreenContent(control).buttonLabel,
                onClick: control =>
                  getDynamicScreenContent(control).onClickButton(control),
              },
            }),
          ],
        },
      }),
    ],
  })
}

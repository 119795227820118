import { Timeline } from 'flow-builder/src/components/Timeline/Timeline'

import { FlowComponent, FlowItineraryField } from '../../types'
import { getFlowValue } from '../../utils'

type ItineraryFieldProps = FlowComponent & FlowItineraryField['props']

export const ItineraryField = ({
  screenTheme,
  moreLabel,
  ...props
}: ItineraryFieldProps) => {
  const items = getFlowValue(props.items, props.control)

  return (
    <Timeline
      items={items}
      moreLabel={count => props.control.context.t(moreLabel, { count })}
    />
  )
}

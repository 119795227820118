import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { mb, ml, mr, mt, pb, pl, pr, pt } from 'styled-components-spacing'

import { ScreenTheme } from 'bl-common/src/styles/types'

import {
  AddProductCardField,
  BreadcrumbField,
  ButtonField,
  CalendarField,
  CardButtonField,
  CheckBoxField,
  ConfirmationSummaryField,
  DiscountAccordion,
  DrawerField,
  ImageField,
  InputTextField,
  ModalField,
  PickerField,
  ProductCard,
  ProgressButtonField,
  RichTextField,
  ScreenErrorField,
  SpaPackageTableField,
  TextAreaField,
  TextField,
} from '../fields'
import { AccordionField } from '../fields/AccordionField'
import { AvailableDateTimesField } from '../fields/AvailableDateTimesField'
import { ChangeDateField } from '../fields/ChangeDateField'
import { CustomField } from '../fields/CustomField'
import { DatePickerField } from '../fields/DateRangePickerField'
import { DateTimeSelectField } from '../fields/DateTimeSelectField'
import { DisclaimerField } from '../fields/DisclaimerField/DisclaimerField'
import { ItineraryField } from '../fields/ItineraryField'
import { MultipleFields } from '../fields/MultipleFields'
import { OverviewField } from '../fields/OverviewField'
import { PhoneInputField } from '../fields/PhoneInputField'
import { SelectDayVisitTimeField } from '../fields/SelectDayVisitTimeField'
import { SelectField } from '../fields/SelectField/SelectField'
import {
  BreakpointMap,
  FlowComponent,
  FlowControl,
  FlowField,
  FlowInputComponentValidationHelpers,
} from '../types'
import { UI_STATE_DIRTY_FIELDS } from './constants'
import { FlowRenderer } from './FlowRenderer'

interface FieldRendererProps extends FlowComponent {
  item: FlowField
  theme?: ScreenTheme
}

const validationUtilFunctions: FlowInputComponentValidationHelpers = {
  onInputBlur: (fieldId: string, control: FlowControl) => {
    control.screen.setUiState({
      [UI_STATE_DIRTY_FIELDS]: {
        ...control.screen.uiStateRef.current[UI_STATE_DIRTY_FIELDS],
        [fieldId]: true,
      },
    })
    control.validateAndSetScreenErrors(true)
  },
}

interface FieldLayoutProps {
  position: BreakpointMap<
    'absolute' | 'sticky' | 'static' | 'fixed' | 'relative'
  >
  bottom: BreakpointMap<number>
  left: BreakpointMap<number>
  right: BreakpointMap<number>
  top: BreakpointMap<number>
  zIndex?: BreakpointMap<number>
  flexBasis: BreakpointMap<string>
  marginTopAuto?: BreakpointMap<boolean>
  maxWidth: BreakpointMap<string>
  marginTop?: BreakpointMap<number>
  marginRight?: BreakpointMap<number>
  marginBottom?: BreakpointMap<number>
  marginLeft?: BreakpointMap<number>
  paddingTop?: BreakpointMap<number>
  paddingRight?: BreakpointMap<number>
  paddingBottom?: BreakpointMap<number>
  paddingLeft?: BreakpointMap<number>
  textAlign?: BreakpointMap<string>
  $width?: BreakpointMap<string>
  columnStyle?: boolean
  display?: BreakpointMap<
    | 'block'
    | 'inline'
    | 'inline-block'
    | 'flex'
    | 'inline-flex'
    | 'grid'
    | 'inline-grid'
    | 'flow-root'
    | 'none'
    | 'contents'
  >
  opacity?: number
}

const FieldLayout = styled.div<FieldLayoutProps>`
  position: relative;
  ${({ $width }) =>
    map(
      $width,
      value => css`
        width: ${value || '100%'};
      `
    )}

  ${({ flexBasis, columnStyle }) =>
    flexBasis &&
    !columnStyle &&
    map(
      flexBasis,
      value => css`
        flex-basis: ${value};
      `
    )}

  ${({ maxWidth }) =>
    maxWidth &&
    map(
      maxWidth,
      value => css`
        max-width: ${value};
      `
    )}

${({ position }) =>
    position &&
    map(
      position,
      value => css`
        position: ${value};
      `
    )}


${({ opacity }) => css`
    opacity: ${opacity};
  `}

${({ textAlign }) =>
    textAlign &&
    map(
      textAlign,
      value => css`
        text-align: ${value};
      `
    )}

${({ bottom }) =>
    bottom &&
    map(
      bottom,
      value => css`
        bottom: ${value}px;
      `
    )}

${({ left }) =>
    left &&
    map(
      left,
      value => css`
        left: ${value}px;
      `
    )}

${({ right }) =>
    right &&
    map(
      right,
      value => css`
        right: ${value}px;
      `
    )}

${({ top }) =>
    top &&
    map(
      top,
      value => css`
        top: ${value}px;
      `
    )}

${({ zIndex }) =>
    zIndex &&
    map(
      zIndex,
      value => css`
        z-index: ${value};
      `
    )}

${({ marginTopAuto }) =>
    marginTopAuto &&
    map(
      marginTopAuto,
      value => css`
        margin-top: ${value === true && 'auto'};
      `
    )}

${({ display }) =>
    display &&
    map(
      display,
      value => css`
        display: ${value};
      `
    )}

  ${({ marginTop, marginTopAuto }) =>
    marginTop && !marginTopAuto && map(marginTop, val => mt(val))}
  ${({ marginRight }) => marginRight && map(marginRight, val => mr(val))} 
  ${({ marginLeft }) => marginLeft && map(marginLeft, val => ml(val))} 
  ${({ marginBottom }) => marginBottom && map(marginBottom, val => mb(val))} 

  ${({ paddingTop }) => paddingTop && map(paddingTop, val => pt(val))} 
  ${({ paddingRight }) => paddingRight && map(paddingRight, val => pr(val))} 
  ${({ paddingLeft }) => paddingLeft && map(paddingLeft, val => pl(val))} 
  ${({ paddingBottom }) => paddingBottom && map(paddingBottom, val => pb(val))}
`

const renderField = ({ item, control, screenTheme }: FieldRendererProps) => {
  const fieldId = item.id

  const fieldError = get(control.screenErrors.state, fieldId)

  switch (item.subType) {
    case 'modal':
    case 'drawer': {
      const ModalComponent =
        item.subType === 'drawer' ? DrawerField : ModalField
      const children =
        typeof item.children === 'function'
          ? item.children(control)
          : item.children
      return (
        <ModalComponent
          control={control}
          {...item.props}
          screenTheme={screenTheme}
        >
          {Array.isArray(children) ? (
            children.map((child, index) => (
              <FieldRenderer
                key={index}
                control={control}
                item={child}
                screenTheme={screenTheme}
              />
            ))
          ) : (
            <FlowRenderer
              flow={children}
              context={control.context}
              parentFlowControl={control}
            />
          )}
        </ModalComponent>
      )
    }
    case 'discountAccordion': {
      return (
        <DiscountAccordion
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'inputText': {
      return (
        <InputTextField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
          error={fieldError}
        />
      )
    }
    case 'accordion': {
      return (
        <AccordionField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'textarea': {
      return (
        <TextAreaField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
          error={fieldError}
        />
      )
    }

    case 'richtext': {
      return (
        <RichTextField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'text': {
      return (
        <TextField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'button': {
      return (
        <ButtonField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'progressButton': {
      return (
        <ProgressButtonField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'breadcrumb': {
      return (
        <BreadcrumbField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'screenError': {
      return (
        <ScreenErrorField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'picker': {
      return (
        <PickerField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
          error={fieldError}
        />
      )
    }
    case 'inputPhone': {
      return (
        <PhoneInputField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
          error={fieldError}
        />
      )
    }
    case 'calendar': {
      return (
        <CalendarField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'spaPackage': {
      return (
        <SpaPackageTableField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'cardButton': {
      return (
        <CardButtonField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'productCard': {
      return (
        <ProductCard
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }

    case 'datepicker': {
      return (
        <DatePickerField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'select': {
      return (
        <SelectField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
          error={fieldError}
        />
      )
    }
    case 'image': {
      return (
        <ImageField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'checkbox': {
      return (
        <CheckBoxField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
          error={fieldError}
        />
      )
    }
    case 'itinerary': {
      return (
        <ItineraryField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'dateTimeSelect': {
      return (
        <DateTimeSelectField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'availableDateTimes': {
      return (
        <AvailableDateTimesField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'selectDayVisitTime': {
      return (
        <SelectDayVisitTimeField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'changeDate': {
      return (
        <ChangeDateField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'addProductCard': {
      return (
        <AddProductCardField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
          id={fieldId}
        />
      )
    }
    case 'disclaimer': {
      return (
        <DisclaimerField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'overview': {
      return (
        <OverviewField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'confirmationSummary': {
      return (
        <ConfirmationSummaryField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'custom': {
      return (
        <CustomField
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    case 'multiple': {
      return (
        <MultipleFields
          {...item.props}
          {...validationUtilFunctions}
          control={control}
          screenTheme={screenTheme}
        />
      )
    }
    default: {
      return null
    }
  }
}

const FieldSpacingRenderer: React.FC<
  FieldRendererProps & { children: React.ReactNode }
> = ({ children, columnStyle, item }) => {
  if (item.subType === 'modal' || item.subType === 'drawer') {
    // The modal field has no layout
    return <>{children}</>
  }

  const { layout } = item

  // TODO: implement more dynamic and responsive spacing
  return (
    <FieldLayout
      position={layout?.position}
      top={layout?.top}
      right={layout?.right}
      bottom={layout?.bottom}
      left={layout?.left}
      zIndex={layout?.zIndex}
      flexBasis={layout?.width ?? { xs: '100%' }}
      maxWidth={layout?.maxWidth}
      $width={layout?.width}
      textAlign={layout?.textAlign}
      marginTopAuto={layout?.marginTopAuto}
      marginTop={layout?.spacing?.mt}
      marginRight={layout?.spacing?.mr}
      marginBottom={layout?.spacing?.mb}
      marginLeft={layout?.spacing?.ml}
      paddingTop={layout?.spacing?.pt}
      paddingRight={layout?.spacing?.pr}
      paddingBottom={layout?.spacing?.pb}
      paddingLeft={layout?.spacing?.pl}
      columnStyle={columnStyle}
      display={layout?.display}
      opacity={layout?.opacity}
    >
      <div>{children}</div>
    </FieldLayout>
  )
}

export const FieldRenderer: React.FC<FieldRendererProps> = props => {
  const { item, control } = props

  const isHidden =
    typeof item.condition === 'function' && item.condition(control) === false

  if (isHidden) {
    return null
  }

  return (
    <FieldSpacingRenderer {...props}>{renderField(props)}</FieldSpacingRenderer>
  )
}

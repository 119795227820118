import { ChangeEvent } from 'react'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { TextArea } from 'bl-common/src/form/TextArea/TextArea'
import { ErrorMessage } from 'bl-common/src/units/Form/styles'

import { FlowInputComponent, FlowTextAreaField, FlowValue } from '../../types'
import { getFlowValue } from '../../utils'

type TextAreaProps = FlowInputComponent &
  FlowTextAreaField['props'] & {
    id: string
    error?: FlowValue
  }

const TextAreaWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TopWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
})

const Label = styled(Type)({
  fontWeight: 'bold',
  marginBottom: '8px',
})

export const TextAreaField = ({ screenTheme, ...props }: TextAreaProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.textAreaField
  const errorColor = theme?.bookingEngine?.[screenTheme]?.errorMessage?.color

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    if (props.control.screen.stateRef.current[props.id] === newValue) {
      return
    }

    props.control.screen.setState({
      [props.id]: event.target.value,
    })
  }

  const onBlur = () => {
    props.onInputBlur(props.id, props.control)
  }

  const defaultValue = getFlowValue(props.defaultValue, props.control) || ''

  return (
    <TextAreaWrapper>
      <TopWrapper>
        {!!props.label && (
          <Label size={{ xs: 14 }} as="label">
            {getFlowValue(props.label, props.control)}
          </Label>
        )}
        {props?.error && (
          <ErrorMessage color={errorColor}>{props?.error}</ErrorMessage>
        )}
      </TopWrapper>
      <TextArea
        id={props.id}
        name={props.id}
        placeholder={getFlowValue(props.placeholder, props.control)}
        label={getFlowValue(props.label, props.control)}
        value={get(props.control.screen.state, props.id, defaultValue)}
        onChange={onChange}
        onBlur={onBlur}
        rows={props.rows ?? 8}
        maxLength={props.maxLength ?? 100}
        themeStyle={themeStyle?.textArea}
        style={{
          width: '100%',
        }}
        hasError={!!props?.error}
      />
    </TextAreaWrapper>
  )
}

import { ChangeEvent, useEffect, useRef, useState } from 'react'

import { ButtonInput } from 'bl-common/src/form/ButtonInput'
import { SubtleErrorMessage } from 'bl-common/src/form/SubtleErrorMessage'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { globalBookingMessages } from 'bl-flows/src/messages'

import { FlowComponent, FlowDiscountAccordionField } from '../../types'
import { getFlowValue } from '../../utils'

type DiscountFieldProps = FlowComponent &
  FlowDiscountAccordionField['props'] & {
    id: string
    initialValue: string
    onSubmit: (code: string) => Promise<void>
    error: string | boolean
    inputThemeStyle?: PartialBookingEngine['inputTextField']
  }

export const DiscountField = ({
  error,
  control,
  inputThemeStyle,
  ...props
}: DiscountFieldProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [value, setValue] = useState(props.initialValue)

  const refIsRemoved = useRef(false)

  useEffect(() => {
    return () => {
      refIsRemoved.current = true
    }
  }, [])

  const submitCode = async (code: string) => {
    if (isSubmitting) {
      return
    }
    setIsSubmitting(true)

    await props.onSubmit?.(code)

    if (!refIsRemoved.current) {
      setIsSubmitting(false)
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value?.toUpperCase()

    setValue(newValue)
  }

  return (
    <>
      <ButtonInput
        id={props.id}
        name={props.id}
        buttonLabel={control.context.t(globalBookingMessages.buttons.add)}
        placeholder={getFlowValue(props.placeholder, control)}
        value={value}
        hasError={!!error}
        loading={isSubmitting}
        onButtonClick={() => submitCode(value)}
        buttonType="button"
        onChange={onChange}
        inputThemeStyle={inputThemeStyle}
      />
      {!!error && <SubtleErrorMessage>{error}</SubtleErrorMessage>}
    </>
  )
}

export default DiscountField

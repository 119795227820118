import styled, { useTheme } from 'styled-components'

import { mediaObj } from 'bl-common/src/utils/media'
import { FlowComponent } from 'bl-flows-core'
import { TransportationLocation } from 'bl-graphql'

import { globalBookingMessages } from '../../messages/global'
import { Direction } from '../../types/Transportation'
import { MapContainer } from './MapContainer'

const MapWrapper = styled('div')({
  height: '100vh',
  position: 'relative',

  [mediaObj.mlg]: {
    display: 'block',
    height: '90vh',
  },
})

export const Map: React.FC<FlowComponent> = ({ control, screenTheme }) => {
  const direction: Direction = control.screen.state.direction

  const theme = useTheme()
  const themeStyle = theme.bookingEngine?.[screenTheme]?.transportationFlow?.map

  const onSelectLocation = (location: TransportationLocation) => {
    control.screen.setState({
      location,
    })
    control.screen.setUiState({ showMap: true })
  }

  return (
    <MapWrapper>
      <MapContainer
        control={control}
        onSelectLocation={onSelectLocation}
        isPickup={direction === Direction.pickup}
        confirmLabel={
          direction === Direction.pickup
            ? control.context.t(globalBookingMessages.text.pickupConfirm)
            : control.context.t(globalBookingMessages.text.dropoffConfirm)
        }
        disclaimer={control.context.t(
          globalBookingMessages.text.pickupDisclaimer
        )}
        themeStyle={themeStyle}
      />
    </MapWrapper>
  )
}

import { useContext, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { rgba } from 'polished'
import styled, { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Button } from 'bl-common/src/elements/Button/Button'
import { CurrencySelector } from 'bl-common/src/elements/CurrencySelector'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'
import { PackageAvailabilityQuery } from 'bl-graphql/src/generated/hooks-with-types'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import {
  getTimeFromDateTimeString,
  setDateTimeISOString,
} from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import {
  DayVisitProductId,
  MembershipProductIds,
  membershipProductIds,
  PRODUCT_IDS,
  spaProductIdPackageMap,
} from 'bl-utils/src/ProductIds'
import { DatePicker } from 'flow-builder/src/components/DatePicker'

import { buildDisclaimerField } from '../../builders'
import { SelectedIcon } from '../../components/SelectedIcon'
import { FieldRenderer } from '../../renderers'
import { FlowComponent, FlowSelectDayVisitTimeField } from '../../types'
import { getFlowValue } from '../../utils'

const MEMBERSHIP_UPGRADE_TO_RETREAT_PRICE = 69000

const DropdownSvg = ({
  color,
}: {
  color?: PartialBookingEngine['massageCard']['cardHeadingColor']
}) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1L5 5L1 1"
      stroke={color || colors.deepBlue}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

type SelectDayVisitTimeFieldProps = FlowComponent &
  FlowSelectDayVisitTimeField['props'] & { id: string }

const ColumnPriceLabel = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing[0.5],
  whiteSpace: 'nowrap',
})

const ColumnLabels = styled.div<{
  themeStyle?: PartialBookingEngine['selectDayVisitTimeField']
}>(({ themeStyle }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  borderTop: `1px solid ${themeStyle?.columnLabelsBorderColor ?? '#D2D2D2'}`,
  borderBottom: `1px solid ${themeStyle?.columnLabelsBorderColor ?? '#D2D2D2'}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing[1]} ${theme.spacing[1.5]} ${theme.spacing[1]}`,
  paddingRight: theme.spacing[2],
}))

const TableRow = styled.button<{
  selected?: boolean
  hidePrice?: boolean
  themeStyle?: PartialBookingEngine['selectDayVisitTimeField']
}>(({ selected, hidePrice, themeStyle }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: hidePrice ? '1fr 1fr ' : '1fr 1fr 1fr',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing[1.5],
  paddingRight: theme.spacing[2],
  color: themeStyle?.tableRowColor ?? 'inherit',
  cursor: 'pointer',
  backgroundColor:
    selected &&
    (themeStyle?.tableRowHoverBackground ?? rgba(colors.blueOnDark, 0.2)),

  [RightColumnItem]: {
    p: {
      color: themeStyle?.rightColumnColor ?? colors.deepBlue,
    },
  },

  '&:hover': {
    backgroundColor:
      themeStyle?.tableRowHoverBackground ?? rgba(colors.blueOnDark, 0.2),
  },
}))

const RowWrapper = styled.div<{
  isEvenRow?: boolean
  themeStyle?: PartialBookingEngine['selectDayVisitTimeField']
}>(({ isEvenRow, themeStyle }) => ({
  backgroundColor: isEvenRow
    ? (themeStyle?.evenRowColor ?? rgba(colors.lightGrey, 0.5))
    : 'transparent',
}))

const RightColumnItem = styled.div({
  minWidth: theme.spacing[10],
  textAlign: 'right',

  [mediaObj.md]: {
    minWidth: theme.spacing[6],
  },
  [mediaObj.mlg]: {
    minWidth: theme.spacing[5.5],
  },
})

const SelectedWrapper = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  span: {
    paddingBottom: 2,
  },
})

const OtherOptions = styled(Type)<{ isOpen?: boolean }>(({ isOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[0.5],
  justifyContent: 'flex-end',
  marginRight: `calc(-1 * ${theme.spacing[0.5]} - 10px)`,

  svg: {
    cursor: 'pointer',
    transition: 'transform 150ms ease-in-out',
    transform: isOpen && 'rotate(-180deg)',
    marginTop: 2,
  },
}))

const isSlotFullyBooked = ({
  available,
  guestCount,
  numChangingRooms,
  isRetreatSpa = false,
}: {
  available: number
  guestCount: number
  numChangingRooms: number
  isRetreatSpa?: boolean
}) => {
  return (
    available === 0 ||
    (available < guestCount && !isRetreatSpa) ||
    (available < numChangingRooms && isRetreatSpa)
  )
}

const filterSlots = (
  packageAvailability: PackageAvailabilityQuery['packageAvailability'],
  packageType: string
) => {
  try {
    if (!packageType) {
      return []
    }

    return packageAvailability.slots.reduce((result, currentSlot) => {
      const selectedPackage = currentSlot.packages.find(
        currentPackage => currentPackage.type === packageType
      )

      if (!selectedPackage) {
        return result
      }
      // retreat package can have slots on 30 min intervals but other ones 1 hour so retreat has inner slots
      const hasInnerSlots =
        selectedPackage.slots && selectedPackage.slots.length > 0

      if (hasInnerSlots) {
        selectedPackage.slots.forEach(innerSlot => {
          result.push({
            ...innerSlot,
          })
        })
      } else {
        result.push({
          time: currentSlot.time,
          ...selectedPackage,
        })
      }

      return result
    }, [])
  } catch {
    return []
  }
}

export const SelectDayVisitTimeField = ({
  screenTheme,
  control,
  ...props
}: SelectDayVisitTimeFieldProps) => {
  const theme = useTheme()
  const themeStyle = theme.bookingEngine?.[screenTheme]

  const [fullyBookedError, setFullyBookedError] = useState<string>()
  const dateProp = getFlowValue(props.date, control)
  const date = dateProp
    ? formatDateInUTC(dateProp)
    : formatDateInUTC(props.currentAdmission?.date)
  const time = getTimeFromDateTimeString(
    control.screen.stateRef?.current?.selectedTime
  )
  const [isOtherOptionsOpen, setIsOtherOptionsOpen] = useState<{
    [key: string]: boolean
  }>({})

  const packageProductNo = getFlowValue(props.packageProductNo, control)
  const isMembershipInCart = getFlowValue(props.isMembershipInCart, control)
  const membershipProductId = getFlowValue(props.membershipProductId, control)
  const guestCount = getFlowValue(props.guestCount, control)
  const isMYB = getFlowValue(props.isMYB, control)
  const isRetreatSpa = packageProductNo === PRODUCT_IDS.SpaRetreat
  const numChangingRooms = isRetreatSpa
    ? control.flow.state.guests?.changingRooms
    : 0

  const loading = getFlowValue(props.isLoadingAvailability, control)
  const data = getFlowValue(props.availabilityData, control)

  const premiumProductId =
    getFlowValue(props.premiumProductId, control) || PRODUCT_IDS.SpaPremium
  const hasAvailabilityError = getFlowValue(props.hasAvailabilityError, control)
  const messages = getFlowValue(props?.messages, control)

  const refetch = getFlowValue(props.refetchAvailability, control)
  const getAdmissionTitle = getFlowValue(props.getAdmissionTitle, control)

  const adultPrice = control.flow.setupHook?.adultPrice
  const alreadyPaidPrice = adultPrice * guestCount

  const hidePriceColumn = props.hidePrice || false

  const isCurrentAdmissionMembershipCard = membershipProductIds.includes(
    props.currentAdmission?.productNo as MembershipProductIds
  )
  const isSignaturePackageEnabled = control.context.isSignaturePackageEnabled

  const spaProductId = spaProductIdPackageMap[packageProductNo] ?? ''

  const { currency, exchangeRates } = useContext(CurrencyContext)

  const getOtherOptions = ({
    selectedPackage,
    time,
  }: {
    selectedPackage: DayVisitProductId
    time: string
  }) => {
    const otherOptions = data?.productAvailabilities
      ?.filter(slot => {
        const includedProductIds = membershipProductIds.some(
          id => id === selectedPackage
        )
          ? [
              // ProductIds that can be changed to when membership card is selected
              PRODUCT_IDS.SpaComfort,
              PRODUCT_IDS.SpaPremium,
              ...(isSignaturePackageEnabled ? [PRODUCT_IDS.SpaSignature] : []),
            ]
          : selectedPackage === PRODUCT_IDS.SpaComfort
            ? [
                // ProductIds that can be changed to when comfort package is selected
                // Spread membership productId if membership is in cart
                ...(isMembershipInCart ? [membershipProductId] : []),
                PRODUCT_IDS.SpaPremium,
                ...(isSignaturePackageEnabled
                  ? [PRODUCT_IDS.SpaSignature]
                  : []),
              ]
            : selectedPackage === PRODUCT_IDS.SpaPremium
              ? [
                  ...(isMembershipInCart ? [membershipProductId] : []),
                  ...(isSignaturePackageEnabled
                    ? [PRODUCT_IDS.SpaSignature]
                    : []),
                  PRODUCT_IDS.SpaComfort,
                ]
              : [
                  ...(isMembershipInCart ? [membershipProductId] : []),
                  PRODUCT_IDS.SpaComfort,
                  PRODUCT_IDS.SpaPremium,
                ]

        return (
          slot.available > 0 &&
          slot.available >= guestCount &&
          slot.time === time &&
          includedProductIds.includes(slot.productId)
        )
      })
      .map(slot => ({
        ...slot,
        time: formatDateInUTC(slot.time, 'HH:mm'),
        serverTime: slot.time,
      }))
      .sort((a, b) => a.price - b.price)

    return otherOptions
  }

  const filteredSlots = isMYB
    ? filterSlots(data?.packageAvailability, spaProductId)
    : data?.productAvailabilities
        .filter(product => product.productId === packageProductNo)
        .map(slot => {
          return {
            ...slot,
            time: formatDateInUTC(slot.time, 'HH:mm'),
            serverTime: slot.time,
            ...(slot.available === 0 && {
              otherOptions: getOtherOptions({
                selectedPackage: packageProductNo as DayVisitProductId,
                time: slot.time,
              }),
            }),
          }
        })
        .sort((a, b) => {
          // Sort by time in case API sends it in random order
          const aTime = new Date(`1970-01-01T${a.time}`)
          const bTime = new Date(`1970-01-01T${b.time}`)
          return aTime.getTime() - bTime.getTime()
        })

  const noAvailability =
    !!data?.productAvailabilities &&
    !filteredSlots?.some(slot => slot?.available > 0)

  const onSelect = (slot, isChangingPackage) => {
    // Find the child admission offerId.
    // Currently only applicable in new flex spa flow.
    const childAdmissions =
      !isMYB &&
      data?.productAvailabilities?.filter(
        product =>
          product.productId === PRODUCT_IDS.SpaChild &&
          product.time === slot.time
      )

    if (control.flow.setupHook?.setConfirmationDrawerProductId) {
      control.flow.setupHook?.setConfirmationDrawerProductId(slot.productId)
    }

    if (
      isSlotFullyBooked({
        available: slot.available,
        guestCount,
        numChangingRooms,
        isRetreatSpa,
      })
    ) {
      // Toggle other options only when clicking on other options, not the other package slots.
      if (slot.otherOptions?.length && !isChangingPackage) {
        setIsOtherOptionsOpen({
          ...isOtherOptionsOpen,
          [slot.time]: !isOtherOptionsOpen[slot.time],
        })
      } else {
        setFullyBookedError(control.context.t(messages.timeFullyBookedError))
      }
      return
    } else {
      setFullyBookedError('')
    }

    const premiumAvailability = data?.productAvailabilities?.find(
      item =>
        getTimeFromDateTimeString(item?.time) === slot?.time &&
        item?.productId === premiumProductId
    )

    if (props.onClick) {
      if (isMYB) {
        props.onClick(
          {
            selectedTime: slot.serverTime,
            selectedPrice:
              isCurrentAdmissionMembershipCard &&
              packageProductNo === PRODUCT_IDS.SpaRetreat
                ? MEMBERSHIP_UPGRADE_TO_RETREAT_PRICE
                : slot.price - alreadyPaidPrice,
          },
          control
        )
      } else {
        props.onClick(
          {
            selectedTime: slot.serverTime,
            selectedPrice: slot.price,
            ...(!isMYB &&
              premiumAvailability?.available >= guestCount && {
                premiumAvailability,
              }),
            productId: slot.productId,
            offerId: slot.offerId,
            childOfferId: childAdmissions?.[0]?.offerId,
          },
          control
        )
      }
    } else {
      // TODO: This entire else block will be deprecated when MYB has migrated.
      // Start of deprecation
      control.screen.setState({
        selectedTime: setDateTimeISOString(date, slot.time),
        selectedPrice: isMYB
          ? isCurrentAdmissionMembershipCard &&
            packageProductNo === PRODUCT_IDS.SpaRetreat
            ? MEMBERSHIP_UPGRADE_TO_RETREAT_PRICE
            : slot.price - alreadyPaidPrice
          : slot.price,
        ...(!isMYB &&
          premiumAvailability?.available >= guestCount && {
            premiumAvailability,
          }),
        ...(!isMYB && {
          productId: slot.productId,
          offerId: slot.offerId,
          childOfferId: childAdmissions?.[0]?.offerId,
        }),
      })

      if (isMYB) {
        control.nextScreen()
      } else {
        control.screen.setUiState({
          isConfirmModalOpen: true,
        })
      }
      // End of deprecation
    }
  }

  const arrivalDate = control.flow.stateRef?.current?.calendar?.arrivalDate

  if (loading) {
    return (
      <div>
        {props.showDatePicker && (
          <DatePicker
            onNextDayClick={() => props.onNextDayClick(control)}
            onPrevDayClick={() => props.onPrevDayClick(control)}
            locale={control.context.locale}
            date={arrivalDate}
            color={
              themeStyle?.selectDayVisitTimeField?.dateSelectorColor ??
              colors.deepBlue
            }
          />
        )}
        <SpinnerWrapper>
          <Spinner shouldAnimate />
        </SpinnerWrapper>
      </div>
    )
  }

  if (hasAvailabilityError) {
    return (
      <>
        {props.showDatePicker && (
          <DatePicker
            onNextDayClick={() => props.onNextDayClick(control)}
            onPrevDayClick={() => props.onPrevDayClick(control)}
            locale={control.context.locale}
            date={arrivalDate}
            color={
              themeStyle?.selectDayVisitTimeField?.dateSelectorColor ??
              colors.deepBlue
            }
          />
        )}
        <Type bottom={1}>
          {control.context.t(messages?.availabilityFetchError)}
        </Type>
        <Button disabled={loading} type="button" onClick={() => refetch()}>
          {control.context.t(messages?.timeRefetch)}
        </Button>
      </>
    )
  }

  return (
    <div>
      {props.showDatePicker && (
        <DatePicker
          onNextDayClick={() => props.onNextDayClick(control)}
          onPrevDayClick={() => props.onPrevDayClick(control)}
          locale={control.context.locale}
          date={arrivalDate}
          color={
            themeStyle?.selectDayVisitTimeField?.dateSelectorColor ??
            colors.deepBlue
          }
        />
      )}
      <FieldRenderer
        control={control}
        item={buildDisclaimerField({
          props: {
            value: fullyBookedError,
            color: colors.errorRed,
            preset: 'text',
            showDisclaimer: !!fullyBookedError,
          },
          layout: {
            spacing: { mb: { xs: 1 } },
          },
        })}
      />
      <ColumnLabels themeStyle={themeStyle?.selectDayVisitTimeField}>
        <Type preset="textSmall" weight="bold" textAlign="left">
          {control.context.t(messages?.timeSlotsTimeLabel)}
        </Type>
        {
          <ColumnPriceLabel>
            {!hidePriceColumn ? (
              <>
                <Type preset="textSmall" weight="bold" textAlign="center">
                  {control.context.t(
                    control.flow.setupHook?.isRetreatSpaFlow
                      ? messages?.timeSlotsPriceRetreatLabel
                      : messages?.timeSlotsPriceLabel
                  )}
                </Type>
                {props?.showCurrencySelector && (
                  <CurrencySelector
                    minimal
                    positionRelativeTo={{ vertical: 'outside' }}
                    verticalPosition="bottom"
                    horizontalPosition="center"
                    offset={{ y: 10 }}
                    themeStyle={themeStyle}
                  />
                )}
              </>
            ) : (
              ''
            )}
          </ColumnPriceLabel>
        }
        <Type preset="textSmall" weight="bold" textAlign="right">
          {control.context.t(messages?.chooseTime)}
        </Type>
      </ColumnLabels>
      {filteredSlots?.map((slot, index) => {
        const isFullyBooked = isSlotFullyBooked({
          available: slot.available,
          guestCount,
          numChangingRooms,
          isRetreatSpa,
        })

        return (
          <RowWrapper
            key={slot.time + index}
            isEvenRow={index % 2 === 0}
            themeStyle={themeStyle?.selectDayVisitTimeField}
          >
            <TableRow
              type="button"
              onClick={() => onSelect(slot, false)}
              selected={slot.time === time && !isFullyBooked}
              hidePrice={hidePriceColumn}
              themeStyle={themeStyle?.selectDayVisitTimeField}
            >
              <Type preset="textLarge" textAlign="left">
                {slot.time}
              </Type>
              {!hidePriceColumn && (
                <Type preset="textLarge" textAlign="center">
                  {isMYB
                    ? isCurrentAdmissionMembershipCard &&
                      packageProductNo === PRODUCT_IDS.SpaRetreat
                      ? // If we are updating to retreat and the customer has annual card we display ISK 69000 for all slots
                        !!slot.price &&
                        formatPrice(
                          MEMBERSHIP_UPGRADE_TO_RETREAT_PRICE,
                          'ISK',
                          false
                        )
                      : !!slot.price &&
                        !!alreadyPaidPrice &&
                        formatPrice(slot.price - alreadyPaidPrice, 'ISK', false)
                    : isFullyBooked
                      ? slot.otherOptions?.length
                        ? control.context.t(messages?.timeSlotsFullyBooked)
                        : '-'
                      : formatPrice(
                          calcPrice(slot?.price, exchangeRates[currency]),
                          currency,
                          false
                        )}
                </Type>
              )}
              <RightColumnItem>
                {slot.time === time && !isFullyBooked ? (
                  <Type preset="textLarge" weight="medium">
                    <SelectedWrapper>
                      <SelectedIcon themeStyle={themeStyle} />{' '}
                      <span>{control.context.t(messages?.selected)}</span>
                    </SelectedWrapper>
                  </Type>
                ) : !isFullyBooked ? (
                  slot.available < 10 ? (
                    <>
                      <Type preset="textSmall" weight="medium" mBottom={0.5}>
                        {control.context.t(
                          messages?.timeSlotsAvailableSlotsLeft,
                          {
                            available: slot.available,
                          }
                        )}
                      </Type>
                      <Type preset="text" weight="medium">
                        {control.context.t(messages?.select)}
                      </Type>
                    </>
                  ) : (
                    <Type preset="textLarge" weight="medium">
                      {control.context.t(messages?.select)}
                    </Type>
                  )
                ) : slot.otherOptions?.length ? (
                  <OtherOptions
                    preset="text"
                    weight="medium"
                    isOpen={isOtherOptionsOpen[slot.time]}
                  >
                    <>
                      {control.context.t(messages?.timeSlotsOtherOptions)}
                      <DropdownSvg color={colors.deepBlue} />
                    </>
                  </OtherOptions>
                ) : (
                  <Type preset="text" weight="medium">
                    {/* Fallback to fully booked if no other options are available */}
                    {control.context.t(messages?.timeSlotsFullyBooked)}
                  </Type>
                )}
              </RightColumnItem>
            </TableRow>
            <AnimatePresence exitBeforeEnter>
              {isFullyBooked &&
                isOtherOptionsOpen[slot.time] &&
                slot.otherOptions?.map(product => {
                  return (
                    <motion.div
                      key={product.time + product.productId}
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ ease: 'linear', duration: 0.2 }}
                    >
                      <TableRow
                        type="button"
                        onClick={() => onSelect(product, true)}
                      >
                        <div></div>
                        <Type>
                          {getAdmissionTitle(
                            control,
                            spaProductIdPackageMap[product.productId],
                            true
                          ) + ' '}
                          {formatPrice(
                            calcPrice(product?.price, exchangeRates[currency]),
                            currency,
                            false
                          )}
                        </Type>
                        <RightColumnItem>
                          <Type preset="textLarge" weight="medium">
                            {control.context.t(messages?.select)}
                          </Type>
                        </RightColumnItem>
                      </TableRow>
                    </motion.div>
                  )
                })}
            </AnimatePresence>
          </RowWrapper>
        )
      })}

      <FieldRenderer
        control={control}
        item={buildDisclaimerField({
          props: {
            value: control.context.t(messages?.noAvailability),
            color: colors.errorRed,
            preset: 'text',
            weight: 'bold',
            showDisclaimer: noAvailability,
          },
          layout: {
            spacing: { mt: { xs: 1.5 } },
          },
        })}
      />
    </div>
  )
}

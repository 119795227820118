import styled from 'styled-components'

import { theme } from '../styles/theme'

const SpinnerWrapper = styled.div<{ pt?: number; verticalCenter?: boolean }>(
  ({ pt, verticalCenter }) => ({
    height: verticalCenter && '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: pt && theme.spacing[pt],
  })
)

export { SpinnerWrapper }

import { parseISO } from 'date-fns/parseISO'
import { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { DatePicker } from 'flow-builder/src/components/DatePicker'

import { FlowChangeDateField, FlowComponent } from '../../types'

type ChangeDateFieldProps = FlowComponent & FlowChangeDateField['props']

export const ChangeDateField = ({
  screenTheme,
  control,
  ...props
}: ChangeDateFieldProps) => {
  const theme = useTheme()
  const themeStyle = theme.bookingEngine?.[screenTheme]

  const arrivalDate = control.flow.stateRef?.current?.calendar?.arrivalDate
  const parsedArrivalDate =
    typeof arrivalDate === 'string' ? parseISO(arrivalDate) : arrivalDate

  return (
    <div>
      <DatePicker
        date={parsedArrivalDate}
        locale={control.context.locale}
        onNextDayClick={() => props.onNextDayClick?.(control)}
        onPrevDayClick={() => props.onPrevDayClick?.(control)}
        style={{
          borderBottom: `1px solid ${
            themeStyle?.selectDayVisitTimeField?.columnLabelsBorderColor ??
            '#D2D2D2'
          }`,
        }}
        color={
          themeStyle?.selectDayVisitTimeField?.dateSelectorColor ??
          colors.deepBlue
        }
      />
    </div>
  )
}

import { differenceInDays } from 'date-fns/differenceInDays'

import { FlowControl } from 'bl-flows-core'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { globalBookingMessages } from '../messages'

interface FormatDateRangeProps {
  fromDate: Date
  toDate: Date
  isSameMonth: boolean
  locale: string
  monthFormat: string
}

const formatDateRange = ({
  fromDate,
  toDate,
  isSameMonth,
  locale,
  monthFormat,
}: FormatDateRangeProps) => {
  if (locale === 'en') {
    const fromFormatted = formatDateInUTC(fromDate, `${monthFormat} d`, locale)
    const toFormatted = isSameMonth
      ? formatDateInUTC(toDate, 'd', locale)
      : formatDateInUTC(toDate, `${monthFormat} d`, locale)
    return `${fromFormatted}-${toFormatted}`
  } else {
    const fromFormatted = isSameMonth
      ? formatDateInUTC(fromDate, 'd.', locale)
      : formatDateInUTC(fromDate, `d. ${monthFormat}`, locale)
    const toFormatted = formatDateInUTC(toDate, `d. ${monthFormat}`, locale)
    return `${fromFormatted}-${toFormatted}`
  }
}

export const formatHotelBookingDuration = ({
  context,
  from,
  to,
  monthFormat = 'MMMM',
  displayNumberOfNights = true,
}: {
  context: FlowControl['context']
  from?: string | number | Date
  to?: string | number | Date
  monthFormat?: string
  displayNumberOfNights?: boolean
}): string => {
  if (!from || !to) {
    return ''
  }
  const fromDate = new Date(from)
  const toDate = new Date(to)
  const isSameMonth = fromDate.getMonth() === toDate.getMonth()

  const tDuration = formatDateRange({
    fromDate,
    toDate,
    isSameMonth,
    locale: context.locale,
    monthFormat,
  })
  const nights = differenceInDays(toDate, fromDate)
  const tNights = context.t(globalBookingMessages.text.nights, { nights })

  return displayNumberOfNights ? `${tDuration} (${tNights})` : tDuration
}

import {
  ActivityItem,
  AdmissionItem,
  CartItem,
  CartItemType,
  CartType,
  HotelRoomItem,
  ProductItem,
  RoomOffer,
  TransportationItem,
} from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { HB_PRODUCT_IDS, PRODUCT_IDS } from 'bl-utils/src/ProductIds'

import { timeMessages } from '../flows/spa/messages/time'
import {
  cartMessages,
  globalBookingMessages,
  productIdsMessages,
} from '../messages'

export interface ModifiedActivityItem extends ActivityItem {
  messages: {
    title: string
    description?: string
    guests?: string
  }
  date?: string
  time?: string
}

interface ModifiedTransportationItem extends TransportationItem {
  messages: {
    title: string
    description?: string
    guests?: string
  }
  pickupLocationName?: string
  dropoffLocationName?: string
  disclaimer
}

interface HighlandBaseTransportationItem extends ActivityItem {
  messages: {
    title: string
    description?: string
    guests?: string
  }
}

export const formatHotelRoomItem = ({
  offer,
  rateCode,
  adults,
  infants,
  children,
  arrivalDate,
  departureDate,
  groupCode,
}: {
  offer: RoomOffer
  arrivalDate: Date
  departureDate: Date
  adults: number
  infants: number
  children: number
  rateCode?: string
  groupCode?: string
}) => {
  const r = rateCode || offer.fields.rateCode
  const metaData = {
    arrivalDate,
    departureDate,
    numberOfAdults: adults,
    numberOfChildren: children,
    numberOfInfants: infants,
    rateCode: r,
    offers: offer.rateCodes[r].map(({ offerId, date }) => ({
      offerId,
      date,
    })),
    ...(groupCode && { groupCode }),
  }

  return {
    productId: offer.productId,
    qty: 1,
    hotelBookingRef: '',
    meta: metaData,
  }
}

export const formatHotelRoomCartItem = (item: HotelRoomItem) => ({
  id: item.id,
  title: item.packageTitle,
  type: 'type',
  description: item.fields?.title,
  price: item.price,
})

export const formatProductCartItem = (
  item: ProductItem,
  t: (message: any, values?: Record<string, any>) => string
) => {
  const titleFallback = productIdsMessages[item.productId]
    ? t(productIdsMessages[item.productId])
    : item.productId
  return {
    id: item.id,
    title: item.fields?.name || titleFallback,
    type: '',
    description:
      item?.qty > 1
        ? `${t(globalBookingMessages.labels.items, { quantity: item.qty })}`
        : '',
    price: item.totalLinePrice,
    discountedPrice: item.totalLinePrice !== item.linePrice && item.linePrice,
  }
}

export const formatAdmissionCartItem = (
  item: AdmissionItem & { childQty: number; adultQty: number },
  t: (message: any, values?: Record<string, any>) => string
) => {
  const isRetreat = item.productId === PRODUCT_IDS.SpaRetreat
  const multiplier = isRetreat ? item.qty : item.adultQty
  return {
    id: item.id,
    title: t(productIdsMessages[item.productId]),
    type: '',
    description: isRetreat
      ? `${item.qty} x ${t(timeMessages.info.drawerSummaryChangingRoom)}, ${t(
          globalBookingMessages.text.adults,
          {
            adults: item.adultQty,
          }
        )}`
      : t(globalBookingMessages.text.adults, {
          adults: item.adultQty,
        }) +
        (item.childQty > 0
          ? `, ${t(globalBookingMessages.text.children, {
              children: item.childQty,
            })}`
          : ''),

    price: item.price * multiplier,
    discountedPrice:
      item.price * multiplier !== item.linePrice && item.linePrice,
  }
}

export const formatRestaurantCartItem = (
  item: ModifiedActivityItem,
  cartType: CartType,
  t?: (message: any, values?: Record<string, any>) => string
) => ({
  id: item.id,
  title: item.messages?.title,
  type: '',
  description:
    cartType === CartType.Hotel
      ? `${item.date} at ${item.time}`
      : t(globalBookingMessages.text.guests, {
          guests: item.meta?.noOfPersons || item.qty,
        }),
  price: cartType === CartType.Hotel ? item.price : undefined,
})

export const formatMassageCartItem = (
  item: ModifiedActivityItem,
  cartType: CartType,
  t?: (message: any, values?: Record<string, any>) => string
) => ({
  id: item.id,
  title: item.messages?.title || item.displayName,
  type: '',
  description: item.messages?.description,
  description2:
    cartType === CartType.Hotel
      ? item.qty > 1
        ? `${item.qty} ${item.messages?.guests} - ${item.date} at ${item.time}`
        : `${item.date} at ${item.time}`
      : t(globalBookingMessages.text.guests, {
          guests:
            item.productId === PRODUCT_IDS.MassageFloatingTwo
              ? 2
              : item.meta?.noOfPersons || item.qty,
        }),
  price: item.price * item.qty,
})

export const formatPrivateTransferCartItem = (
  item: ModifiedActivityItem,
  cartType: CartType
) => ({
  id: item.id,
  title: item.messages?.title || item.displayName,
  type: '',
  description: item.fullName,
  description2:
    cartType === CartType.Hotel ? `${item.date} at ${item.time}` : undefined,
  price: item.price * item.qty,
})

export const formatActivityCartItem = (
  item: ActivityItem,
  t: (message: any, values?: Record<string, any>) => string
) => {
  const titleFallback = productIdsMessages[item.productId]
    ? t(productIdsMessages[item.productId])
    : item.displayName
  return {
    id: item.id,
    title: item.fields?.name || titleFallback,
    type: '',
    description:
      item?.qty > 1
        ? `${t(globalBookingMessages.labels.items, { quantity: item.qty })}`
        : '',
    price: item.totalLinePrice,
    discountedPrice: item.totalLinePrice !== item.linePrice && item.linePrice,
  }
}

export const formatActivityWithTimeCartItem = (
  item: ActivityItem,
  t: (message: any, values?: Record<string, any>) => string
) => {
  const titleFallback = productIdsMessages[item.productId]
    ? t(productIdsMessages[item.productId])
    : item.displayName
  return {
    id: item.id,
    title: item.fields?.name || titleFallback,
    type: '',
    description:
      item.qty > 1
        ? `${t(globalBookingMessages.labels.items, { quantity: item.qty })}`
        : '',
    description2: item.meta?.arrivalTime
      ? `${formatDateInUTC(
          item.meta.arrivalTime,
          'MMM dd'
        )} at ${formatDateInUTC(item.meta.arrivalTime, 'HH:mm')}`
      : '',
    price: item.totalLinePrice,
    discountedPrice: item.totalLinePrice !== item.linePrice && item.linePrice,
  }
}

export const formatTransportationCartItem = (
  item: ModifiedTransportationItem,
  t?: (message: any, values?: Record<string, any>) => string
) => ({
  id: item.id,
  title: item.messages?.title || item.displayName,
  type: '',
  description:
    (item?.pickupLocationName &&
      t(cartMessages.info.transportationFromLocation, {
        location: item?.pickupLocationName,
      })) ||
    (item?.dropoffLocationName &&
      t(cartMessages.info.transportationToLocation, {
        location: item?.dropoffLocationName,
      })),
  price: (item.price * item.qty) / 2, // dividing by two as we're showing the transportation as 2 items
  disclaimer: item?.disclaimer,
})

export const formatHighlandbaseTransportCartItem = (
  item: HighlandBaseTransportationItem,
  t?: (message: any, values?: Record<string, any>) => string
) => ({
  id: item.id,
  title: item.messages?.title || item.displayName,
  type: '',
  description: `${t(globalBookingMessages.text.dateAtTime, {
    date: formatDateInUTC(item.meta.arrivalTime, 'd MMM'),
    time: formatDateInUTC(item.meta.arrivalTime, 'HH:mm'),
  })} ${t(globalBookingMessages.text.forGuests, { count: item.qty })}`,
  price: item.price * item.qty,
})

export const formatCartItem = (
  item: CartItem,
  cartType: CartType,
  t?: (message: any, values?: Record<string, any>) => string
) => {
  if (item.type === CartItemType.Hotel) {
    return formatHotelRoomCartItem(item as HotelRoomItem)
  } else if (item.type === CartItemType.Item) {
    return formatProductCartItem(item as ProductItem, t)
  } else if (item.type === CartItemType.Restaurant) {
    return formatRestaurantCartItem(item as ModifiedActivityItem, cartType, t)
  } else if (item.type === CartItemType.Massage) {
    return formatMassageCartItem(item as ModifiedActivityItem, cartType, t)
  } else if (item.type === CartItemType.PrivateTransfer) {
    return formatPrivateTransferCartItem(item as ModifiedActivityItem, cartType)
  } else if (item.type === CartItemType.Transfer) {
    return formatTransportationCartItem(item as ModifiedTransportationItem, t)
  } else if (item.type === CartItemType.Activity) {
    switch (item.productId) {
      case HB_PRODUCT_IDS.TransferSkjolHb:
      case HB_PRODUCT_IDS.TransferHbSkjol:
      case HB_PRODUCT_IDS.TransferHbRvk:
      case HB_PRODUCT_IDS.TransferRvkHb:
        return formatHighlandbaseTransportCartItem(
          item as HighlandBaseTransportationItem,
          t
        )
      case HB_PRODUCT_IDS.BuggyDoubleRide:
      case HB_PRODUCT_IDS.BuggySingleRide:
      case HB_PRODUCT_IDS.SnowmobileDoubleRide:
      case HB_PRODUCT_IDS.SnowmobileSingleRide:
        return formatActivityWithTimeCartItem(item as ActivityItem, t)
      default:
        return formatActivityCartItem(item as ActivityItem, t)
    }
  } else if (item.type === CartItemType.Admission) {
    return formatAdmissionCartItem(
      item as AdmissionItem & { childQty: number; adultQty: number },
      t
    )
  }
  return
}

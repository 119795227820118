import { colors } from 'bl-common/src/constants/colors'
import { PartialBookingEngine } from 'bl-common/src/styles/types'

export const SelectedIcon = ({
  themeStyle,
  style,
}: {
  themeStyle?: PartialBookingEngine
  style?: React.CSSProperties
}) => {
  return (
    <svg
      height="2ch"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 6, ...style }}
      aria-hidden
    >
      <path
        d="M5.77612 8.49275L9.92538 13.5077L17.209 4.01514"
        stroke={
          themeStyle?.selectDayVisitTimeField?.selectedIconColor ||
          colors.deepBlue
        }
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.709 8.23461L17.6708 8.28153L17.6839 8.34055C17.8013 8.86846 17.8601 9.42651 17.8601 9.98507C17.8601 14.334 14.334 17.8601 9.98507 17.8601C5.6362 17.8601 2.11007 14.334 2.11007 9.98507C2.11007 5.6362 5.6362 2.11007 9.98507 2.11007C11.2188 2.11007 12.3947 2.40379 13.4541 2.90408L13.5468 2.94788L13.6079 2.86545L14.2049 2.05948L14.2927 1.94102L14.1615 1.87382C12.9202 1.23807 11.4978 0.875 9.98507 0.875C4.96082 0.875 0.875 4.96082 0.875 9.98507C0.875 15.0093 4.96082 19.0951 9.98507 19.0951C15.0093 19.0951 19.0951 15.0093 19.0951 9.98507C19.0951 9.04593 18.9436 8.16857 18.7023 7.32387L18.6364 7.09318L18.4851 7.27939L17.709 8.23461Z"
        fill={
          themeStyle?.selectDayVisitTimeField?.selectedIconColor ||
          colors.deepBlue
        }
        stroke={
          themeStyle?.selectDayVisitTimeField?.selectedIconColor ||
          colors.deepBlue
        }
        strokeWidth="0.25"
      />
    </svg>
  )
}

import { defineMessages } from 'react-intl'

const info = defineMessages({
  aboutBreadcrumb: {
    id: 'sharedFlows:float.aboutBreadcrumb',
    defaultMessage: 'About',
    description: 'Float: About breadcrumb label',
  },
  aboutTitle: {
    id: 'sharedFlows:float.aboutTitle',
    defaultMessage:
      'Would you like to experience float therapy in the Blue Lagoon?',
    description: 'Float: Title on about screen',
  },
  aboutSubtitle: {
    id: 'sharedFlows:float.aboutSubtitle',
    defaultMessage:
      'A hypnotic session of floatation and gentle bodywork that relaxes the mind, soothes the body, and elevates the spirit—relieving stress, alleviating pain, and catalyzing inner peace.',
    description: 'Float: Subtitle on about screen',
  },
  updateButton: {
    id: 'sharedFlows:float.updateButton',
    defaultMessage: 'Update',
    description: 'Float: Update button',
  },
  editTimeBreadcrumb: {
    id: 'sharedFlows:float.editTimeBreadcrumb',
    defaultMessage: 'Time',
    description: 'Float: Edit time breadcrumb label',
  },
  timeBreadcrumb: {
    id: 'sharedFlows:float.timeBreadcrumb',
    defaultMessage: 'Type & time',
    description: 'Float: Type & time breadcrumb label',
  },
  timeTitle: {
    id: 'sharedFlows:float.timeTitle',
    defaultMessage: 'Select Float Therapy',
    description: 'Float: Title on type & time screen',
  },
  editTimeTitle: {
    id: 'sharedFlows:float.editTimeTitle',
    defaultMessage: 'Select time',
    description: 'Float: Title on edit time screen',
  },
  editTimeSubtitle: {
    id: 'sharedFlows:float.editTimeSubtitle',
    defaultMessage: 'Your previous booking was at {time}.',
    description: 'Float: Subtitle in change time modal',
  },
  timeSubtitle: {
    id: 'sharedFlows:float.timeSubtitle',
    defaultMessage:
      'We recommend having your Float therapy 30-60 minutes after arriving at The Blue Lagoon.',
    description: 'Float: Subtitle on time screen',
  },
  timeConfirmButton: {
    id: 'sharedFlows:float.timeConfirmButton',
    defaultMessage: 'Confirm',
    description: 'Float: Confirm button label',
  },
  timeCardHeading: {
    id: 'sharedFlows:float.timeCardHeading',
    defaultMessage: 'Float Therapy {number}',
    description: 'Float: Type & time card heading',
  },
  overviewSubTitle: {
    id: 'sharedFlows:float.overviewSubTitle',
    defaultMessage: 'Kindly ensure your timely arrival at the float area.',
    description: 'Float: Subtitle on overview screen',
  },
  confirmationTitle: {
    id: 'sharedFlows:float.confirmationTitle',
    defaultMessage: 'Float therapy confirmation',
    description: 'Float: Title on confirmation screen',
  },
  confirmationTitleCart: {
    id: 'sharedFlows:float.confirmationTitleCart',
    defaultMessage: 'Float therapy has been added to cart',
    description: 'Float: Title on confirmation screen when adding to cart',
  },
  confirmationSubtitle: {
    id: 'sharedFlows:float.confirmationSubtitle',
    defaultMessage: 'You will be charged upon arrival.',
    description: 'Float: Subtitle on confirmation screen',
  },
  confirmationEditSubtitle: {
    id: 'sharedFlows:float.confirmationEditSubtitle',
    defaultMessage:
      'Your booking has successfully been updated, below you can see the details of your new booking',
    description: 'Float: Subtitle on confirmation screen when editing',
  },
  addAnotherCardLabel: {
    id: 'sharedFlows:float.addAnotherCardLabel',
    defaultMessage: '+ Add another Float Therapy',
    description: 'Float: Button label to add another float card',
  },
  overviewSummaryQuantity: {
    // Used in getItemsForFloatOverview()
    id: 'sharedFlows:float.overviewSummaryQuantity',
    defaultMessage: 'for  {count, plural, one {# guest} other {# guests}} ',
    description: 'Float: Number of guests on confirmation screen',
  },
})

const warnings = defineMessages({
  noMoreFloats: {
    id: 'sharedFlows:massage.noMoreFloats',
    defaultMessage:
      'Your booking only has {guests} {guests, plural, one {guest} other {guests}}, therefore you can’t add more than {floats} {floats, plural, one {float} other {floats}} to your booking. Contact support to book extra floats.',
    description:
      'Float: Disclaimer when there is already a float in cart for all guests',
  },
  noTimeSelectedDisclaimer: {
    id: 'sharedFlows:float.noTimeSelectedDisclaimer',
    defaultMessage: 'Please remember to select a time for your Float Therapy',
    description:
      "Float: Disclaimer when user hasn't filled out time and type for all floats",
  },
  noAvailabilityDisclaimer: {
    id: 'sharedFlows:float.noAvailabilityDisclaimer',
    defaultMessage: 'No Float Therapies are available on your selected date',
    description: 'Float: Disclaimer for when there are no floats available',
  },
})

const errors = defineMessages({
  bookingFailedError: {
    id: 'sharedFlows:float.bookingFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to create a booking, please contact our support center for help',
    description: 'Float: Error message for failed booking',
  },
  editBookingFailedError: {
    id: 'sharedFlows:float.editBookingFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to edit your booking, please contact our support center for help',
    description: 'Float: Error message for failed edit booking',
  },
  floatsExceedNumberOfGuests: {
    id: 'sharedFlows:float.floatsExceedNumberOfGuests',
    defaultMessage:
      'Your selection exceeds the number of guests in your booking',
    description:
      'Float: error shown when number of float "seats" exceed number of booking guests',
  },
})

export const floatMessages = {
  info,
  warnings,
  errors,
}

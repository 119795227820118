import { HB_PRODUCT_IDS, PRODUCT_IDS } from './ProductIds'

export const getProductDurationInMinutes = (productId: string) => {
  switch (productId) {
    case PRODUCT_IDS.Massage30:
      return 30
    case PRODUCT_IDS.Massage60:
      return 60
    case PRODUCT_IDS.Massage120:
      return 120
    case PRODUCT_IDS.MassageFloatingGroup:
    case PRODUCT_IDS.MassageFloatingOne:
    case PRODUCT_IDS.MassageFloatingTwo:
      return 45
    case PRODUCT_IDS.RestaurantLava:
      return 2
    case PRODUCT_IDS.SpaRestaurant:
      return 2
    case HB_PRODUCT_IDS.HighlandBaseRestaurant:
      return 2
  }

  return 60
}

export const KEFLAVIK_AIRPORT_ID = '42229'
export const REYKJAVIK_TERMINAL_ID = '45620'

import { useCallback, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { Asset } from 'contentful'
import styled, { css } from 'styled-components'

import { PlayIcon } from '../../elements/Icons/PlayIcon'
import { media, mediaMax } from '../../utils/media'

const Video = styled.video`
  animation: fadeIn 500ms;
  animation-fill-mode: forwards;
  opacity: 0;

  height: calc(90vw * calc(1080 / 1920));
  ${media.bmd(css`
    height: calc(calc(100vw - 160px) * calc(2 / 3) * 0.9 * calc(1080 / 1920));
  `)}

  ${mediaMax.md(css`
    @media screen and (orientation: landscape) {
      height: calc(100vw * calc(1080 / 1920));
      max-height: 100vh;
      max-width: 100vw;
    }
  `)}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlayButton = styled.button`
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  ${media.bmd(css`
    height: 64px;
    width: 64px;
  `)}

  > svg {
    margin: 0;
    width: 14px;

    ${media.bmd(css`
      width: 18px;
    `)}
  }

  animation: fadeIn 500ms;
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const preloadVideo = async (url: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const src = URL.createObjectURL(blob)
  return src
}

export const WelcomeGuideMediaPlayer = ({
  step,
  media,
}: {
  step: number
  media: Asset[]
}) => {
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const [videoSrcs, setVideoSrcs] = useState<Array<string>>([])

  useEffect(() => {
    const preloadVideos = async () => {
      const preloadedVideoSources = await Promise.all(
        media.map(media => preloadVideo(media.fields.file.url))
      )
      setVideoSrcs(preloadedVideoSources)
    }
    preloadVideos()
  }, [])

  useEffect(() => {
    const showPlayButton = () => setIsPlaying(false)
    const hidePlayButton = () => setIsPlaying(true)

    videoElementRef.current?.addEventListener('loadeddata', hidePlayButton)

    videoElementRef.current?.addEventListener('pause', showPlayButton)

    videoElementRef.current?.addEventListener('ended', showPlayButton)

    videoElementRef.current?.addEventListener('playing', hidePlayButton)

    videoElementRef.current?.addEventListener('play', hidePlayButton)

    return () => {
      videoElementRef.current?.removeEventListener('loadeddata', hidePlayButton)

      videoElementRef.current?.removeEventListener('pause', showPlayButton)

      videoElementRef.current?.removeEventListener('ended', showPlayButton)

      videoElementRef.current?.removeEventListener('playing', hidePlayButton)

      videoElementRef.current?.removeEventListener('play', hidePlayButton)
    }
  }, [step])

  const playVideo = useCallback(() => {
    videoElementRef?.current?.play()
  }, [])

  // Show unpreloaded first step video while preloading all videos
  const isFirstStep = step === 0
  const currentMedia = media[step]

  return currentMedia.fields.file.contentType === 'video/mp4' ? (
    <>
      <Video
        ref={videoElementRef}
        key={'video' + step}
        src={isFirstStep ? currentMedia.fields.file.url : videoSrcs[step]}
        autoPlay
        muted
        playsInline
      ></Video>
      {!isPlaying ? (
        <Overlay key={'overlay' + step}>
          <PlayButton type="button" onClick={playVideo}>
            <PlayIcon />
          </PlayButton>
        </Overlay>
      ) : null}
    </>
  ) : (
    <ImageWrapper>
      <Image src={'https:' + currentMedia.fields.file.url} fill alt="" />
    </ImageWrapper>
  )
}

const MASK_CONTROLLING_CHARACTER = '#'

const getMaskDecorationInfo = (mask: string) => {
  let maxLength = 0
  const decorationCharacterLookup = mask
    .split('')
    .reduce((lookup, character) => {
      const isControllingCharacter = character === MASK_CONTROLLING_CHARACTER

      if (isControllingCharacter) {
        maxLength += 1
      }

      if (!isControllingCharacter && !lookup[character]) {
        lookup[character] = true
      }

      return lookup
    }, {})

  return {
    decorationCharacterLookup,
    maxLength,
  }
}

export const normalise = (value: string, mask: string) => {
  const { decorationCharacterLookup, maxLength } = getMaskDecorationInfo(mask)

  return value
    .replace(/\s/g, '')
    .split('')
    .filter(c => !decorationCharacterLookup[c])
    .slice(0, maxLength)
    .join('')
}

export const serialise = (value: string, mask: string) => {
  const result = []

  let currentSourceValueIndex = 0

  for (let i = 0; i < mask.length; i += 1) {
    if (currentSourceValueIndex >= value?.length ?? 0) {
      break
    }

    const valueCharacter = value[currentSourceValueIndex]
    const maskCharacter = mask[i]

    if (maskCharacter === MASK_CONTROLLING_CHARACTER) {
      result.push(valueCharacter)
      currentSourceValueIndex += 1
    } else {
      result.push(maskCharacter)
    }
  }

  return result.join('')
}

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'
import { replaceLineBreaks } from 'bl-utils/src/replaceLineBreaks'

import { FlowComponent, FlowTextField } from '../../types'
import { DisclaimerTextField } from './DisclaimerField'

type TextFieldProps = FlowComponent & FlowTextField['props']

const typeConfig = {
  heading: { as: 'h1', preset: 'headlineLarge', weight: 'bold' },
  headingMedium: {
    as: 'h1',
    preset: 'headline',
    weight: 'bold',
  },
  subheading: {
    as: 'h2',
    preset: 'subtitle',
    weight: 'light',
    multiline: true,
  },
  headingSmall: { as: 'h3', preset: 'headlineSmall', weight: 'bold' },
  textLarge: { as: 'h3', preset: 'textLarge', weight: 'bold' },
  text: { as: 'p', preset: 'text' },
  label: { preset: 'label' },
  disclaimer: null, // Special case, handled separately
  html: null, // Special case, handled separately
} as const

export const TextField = (props: TextFieldProps) => {
  const value =
    typeof props.value === 'function' ? props.value(props.control) : props.value

  if (value === undefined) {
    return null
  }

  const title = typeof value === 'string' ? replaceLineBreaks(value) : value

  const commonProps = {
    maxWidth: props.maxWidth ?? 660,
    style: { wordBreak: 'break-word', whiteSpace: 'pre-line' },
    color: props.color,
    textAlign: props.textAlign,
    ...(props.mask && { className: 'ph-no-capture' }),
  }

  if (props.type === 'disclaimer') {
    return <DisclaimerTextField>{title}</DisclaimerTextField>
  } else if (props.type === 'html') {
    return (
      <Type
        {...commonProps}
        as={props.as}
        preset={props.preset}
        maxWidth={props.maxWidth}
        dangerouslySetInnerHTML={{
          __html: formatHtmlText(title, undefined, '_self'),
        }}
      />
    )
  } else if (typeConfig[props.type]) {
    const { as, preset, weight, multiline } = typeConfig[props.type]
    return (
      <Type
        {...commonProps}
        as={as}
        preset={props.preset || preset}
        weight={weight}
        multiline={multiline}
      >
        {title}
      </Type>
    )
  }

  // Fallback for types not explicitly handled
  return (
    <p
      style={{
        textAlign: props.textAlign || 'inherit',
      }}
    >
      {title}
    </p>
  )
}

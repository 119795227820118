import { defineMessages } from 'react-intl'

const info = defineMessages({
  aboutBreadcrumb: {
    id: 'sharedFlows:spaRestaurant.aboutBreadcrumb',
    defaultMessage: 'About',
    description: 'SpaRestaurant: About breadcrumb label',
  },
  aboutTitle: {
    id: 'sharedFlows:spaRestaurant.aboutTitle',
    defaultMessage: 'Spa Restaurant',
    description: 'SpaRestaurant: Title for about screen',
  },
  aboutSubtitle: {
    id: 'sharedFlows:spaRestaurant.aboutSubtitle',
    defaultMessage:
      'Before, during, or after your spa journey, savor the restaurant’s delicious cuisine, enchanting ambience, and stunning views of the Retreat Lagoon. Dine in your robe or fully clothed.',
    description: 'SpaRestaurant: Subtitle for about screen',
  },
  aboutReserveButton: {
    id: 'sharedFlows:spaRestaurant.aboutReserveButton',
    defaultMessage: 'Reserve a table',
    description: 'SpaRestaurant: Button to continue to reserve',
  },
  timeBreadcrumb: {
    id: 'sharedFlows:spaRestaurant.timeBreadcrumb',
    defaultMessage: 'Time',
    description: 'SpaRestaurant: Time breadcrumb label',
  },
  timeTitle: {
    id: 'sharedFlows:spaRestaurant.timeTitle',
    defaultMessage: 'Select Time',
    description: 'SpaRestaurant: Title for time screen',
  },
  timeSubtitle: {
    id: 'sharedFlows:spaRestaurant.timeSubtitle',
    defaultMessage: 'Please select the time you would prefer',
    description: 'SpaRestaurant: Subtitle for time screen',
  },
  editTimeSubtitle: {
    id: 'sharedFlows:spaRestaurant.editIimeSubtitle',
    defaultMessage: 'Your previous booking was at {time}',
    description: 'SpaRestaurant: Subtitle for edit time screen',
  },
  guestsBreadcrumb: {
    id: 'sharedFlows:spaRestaurant.guestsBreadcrumb',
    defaultMessage: 'Guests',
    description: 'SpaRestaurant: Guests breadcrumb label',
  },
  guestsTitle: {
    id: 'sharedFlows:spaRestaurant.guestsTitle',
    defaultMessage: 'Choose Number of Guests',
    description: 'SpaRestaurant: Title on guests screen',
  },
  guestsPickerLabel: {
    id: 'sharedFlows:spaRestaurant.guestsPickerLabel',
    defaultMessage: 'Guests',
    description: 'SpaRestaurant: Label for guest quantity picker ',
  },
  addReservation: {
    id: 'sharedFlows:spaRestaurant.addReservation',
    defaultMessage: 'Add reservation',
    description: 'SpaRestaurant: Button label to add reservation',
  },
  commentLabel: {
    id: 'sharedFlows:spaRestaurant.commentLabel',
    defaultMessage: 'Any special requests?',
    description: 'SpaRestaurant: Comment label',
  },
  commentPlaceholder: {
    id: 'sharedFlows:spaRestaurant.commentPlaceholder',
    defaultMessage:
      'Any special dietary requests? Are you celebrating? Let us know',
    description: 'SpaRestaurant: Comment placeholder',
  },
  spaRestaurantReservation: {
    // Used in getItemsForSpaRestaurantOverview()
    id: 'sharedFlows:spaRestaurant.spaRestaurantReservation',
    defaultMessage: 'Spa Restaurant reservation',
    description: 'SpaRestaurant: Title of the Spa Restaurant reservation',
  },
  titleGuestsDateTime: {
    // Used in getItemsForSpaRestaurantOverview()
    id: 'sharedFlows:spaRestaurant.titleGuestsDateTime',
    defaultMessage: '{title}, {guests}, {date} at {time}',
    description: 'SpaRestaurant: Title of the Spa Restaurant reservation',
  },
  confirmationTitle: {
    id: 'sharedFlows:spaRestaurant.confirmationTitle',
    defaultMessage: 'Spa Restaurant reservation confirmed',
    description: 'SpaRestaurant: Title on confirmation screen',
  },
  editConfirmationTitle: {
    id: 'sharedFlows:spaRestaurant.editConfirmationTitle',
    defaultMessage: 'Your Spa Restaurant reservation has been updated!',
    description: 'SpaRestaurant: Title on confirmation screen when editing',
  },
})

const warnings = defineMessages({
  numberHigherThanGuests: {
    id: 'sharedFlows:spaRestaurant.numberHigherThanGuests',
    defaultMessage:
      'Number of  guests for a reservation is higher than number of guests on the booking',
    description:
      'Warning message when number of reservation guests is higher than guests on booking',
  },
  tooManyGuestsForFlowBooking: {
    id: 'sharedFlows:spaRestaurant.tooManyGuestsForFlowBooking',
    defaultMessage:
      'Send request to contact@bluelagoon.is for 10+ table reservation.',
    description:
      'SpaRestaurant: Warning message when users reach limit of guests that is possible to reserve for with the flow',
  },
})

const errors = defineMessages({
  reservationFailedError: {
    id: 'sharedFlows:spaRestaurant.reservationFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to create a reservation, please contact our support center for help',
    description: 'SpaRestaurant: Error message for failed reservation',
  },
  editReservationFailedError: {
    id: 'sharedFlows:spaRestaurant.editReservationFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to edit your reservation, please contact our support center for help',
    description: 'SpaRestaurant: Error message for failed reservation',
  },
})

export const spaRestaurantMessages = {
  info,
  warnings,
  errors,
}

import { defineMessages } from 'react-intl'

const info = defineMessages({
  aboutBreadcrumb: {
    id: 'sharedFlows:lava.aboutBreadcrumb',
    defaultMessage: 'About',
    description: 'Lava: About breadcrumb label',
  },
  aboutTitle: {
    id: 'sharedFlows:lava.aboutTitle',
    defaultMessage: 'Lava Restaurant',
    description: 'Lava: Title for about screen',
  },
  aboutSubtitle: {
    id: 'sharedFlows:lava.aboutSubtitle',
    defaultMessage:
      'Gourmet dining on the shores of a wonder of the world. Showcasing the savory highlights of Icelandic cuisine, our menus are based on the finest, freshest local ingredients.',
    description: 'Lava: Subtitle for about screen',
  },
  aboutReserveButton: {
    id: 'sharedFlows:lava.aboutReserveButton',
    defaultMessage: 'Reserve a table',
    description: 'Lava: Button to continue to reserve',
  },
  timeBreadcrumb: {
    id: 'sharedFlows:lava.timeBreadcrumb',
    defaultMessage: 'Time',
    description: 'Lava: Time breadcrumb label',
  },
  timeTitle: {
    id: 'sharedFlows:lava.timeTitle',
    defaultMessage: 'Select Time',
    description: 'Lava: Title for time screen',
  },
  timeSubtitle: {
    id: 'sharedFlows:lava.timeSubtitle',
    defaultMessage: 'Please select the time you would prefer',
    description: 'Lava: Subtitle for time screen',
  },
  editTimeSubtitle: {
    id: 'sharedFlows:lava.editIimeSubtitle',
    defaultMessage: 'Your previous booking was at {time}',
    description: 'Lava: Subtitle for edit time screen',
  },
  packageTimeTitle: {
    id: 'sharedFlows:lava.packageTimeTitle',
    defaultMessage: 'Reserve a table at Lava Restaurant',
    description: 'Lava: Title for time screen when adding lava on package step',
  },
  packageTimeSubtitle: {
    id: 'sharedFlows:lava.packageTimeSubtitle',
    defaultMessage:
      'This package includes dinner at Lava Restaurant. Please select when you would like to book your table',
    description:
      'Lava: Subtitle for time screen when adding lava on package step',
  },
  guestsBreadcrumb: {
    id: 'sharedFlows:lava.guestsBreadcrumb',
    defaultMessage: 'Guests',
    description: 'Lava: Guests breadcrumb label',
  },
  guestsTitle: {
    id: 'sharedFlows:lava.guestsTitle',
    defaultMessage: 'Choose Number of Guests',
    description: 'Lava: Title on guests screen',
  },
  guestsPickerLabel: {
    id: 'sharedFlows:lava.guestsPickerLabel',
    defaultMessage: 'Guests',
    description: 'Lava: Label for guest quantity picker ',
  },
  addReservation: {
    id: 'sharedFlows:lava.addReservation',
    defaultMessage: 'Add reservation',
    description: 'Lava: Button label to add reservation',
  },
  commentLabel: {
    id: 'sharedFlows:lava.commentLabel',
    defaultMessage: 'Any special requests?',
    description: 'Lava: Comment label',
  },
  commentPlaceholder: {
    id: 'sharedFlows:lava.commentPlaceholder',
    defaultMessage:
      'Any special dietary requests? Are you celebrating? Let us know',
    description: 'Lava: Comment placeholder',
  },
  lavaReservation: {
    // Used in getItemsForLavaOverview()
    id: 'sharedFlows:lava.lavaReservation',
    defaultMessage: 'Lava reservation',
    description: 'Lava: Title of the lava reservation',
  },
  titleGuestsDateTime: {
    // Used in getItemsForLavaOverview()
    id: 'sharedFlows:lava.titleGuestsDateTime',
    defaultMessage: '{title}, {guests}, {date} at {time}',
    description: 'Lava: Title of the lava reservation',
  },
  confirmationTitle: {
    id: 'sharedFlows:lava.confirmationTitle',
    defaultMessage: 'Lava Restaurant reservation confirmed',
    description: 'Lava: Title on confirmation screen',
  },
  confirmationTitleCart: {
    id: 'sharedFlows:lava.confirmationTitleCart',
    defaultMessage: 'Your Lava reservation has been added to cart',
    description: 'Lava: Title on confirmation screen',
  },
  editConfirmationTitle: {
    id: 'sharedFlows:lava.editConfirmationTitle',
    defaultMessage: 'Your Lava reservation has been updated!',
    description: 'Lava: Title on confirmation screen when editing',
  },
  confirmationSubtitle: {
    id: 'sharedFlows:lava.confirmationSubtitle',
    defaultMessage:
      'We are expecting {guests, plural, one {# guest} other {# guests}} at {time} {date}',
    description: 'Lava: Subtitle on confirmation screen',
  },
  confirmationSummary: {
    id: 'sharedFlows:lava.confirmationSummary',
    defaultMessage: 'Summary',
    description: 'Lava: Summary label',
  },
  confirmationGuestsLabel: {
    id: 'sharedFlows:lava.confirmationGuestsLabel',
    defaultMessage: 'Lava table reservation for: ',
    description: 'Lava: Confimration guests label',
  },
  confirmationGuests: {
    id: 'sharedFlows:lava.confirmationGuests',
    defaultMessage: '{guests, plural, one {# guest} other {# guests}}',
    description: 'Lava: Confirmation number of guests',
  },
  confirmationTimeLabel: {
    id: 'sharedFlows:lava.confirmationTimeLabel',
    defaultMessage: 'Time: ',
    description: 'Lava: Confirmation time label',
  },
  confirmationTime: {
    id: 'sharedFlows:lava.confirmationTime',
    defaultMessage: '{date} at {time}',
    description: 'Lava: Confirmation time and date',
  },
})

const warnings = defineMessages({
  numberHigherThanGuests: {
    id: 'sharedFlows:lava.numberHigherThanGuests',
    defaultMessage:
      'Number of  guests for a reservation is higher than number of guests on the booking',
    description:
      'Warning message when number of reservation guests is higher than guests on booking',
  },
  tooManyGuestsForFlowBooking: {
    id: 'sharedFlows:lava.tooManyGuestsForFlowBooking',
    defaultMessage:
      'Send request to [contact@bluelagoon.is](/booking-details?modal=upgradeLava&form=true) for 10+ table reservation.',
    description:
      'Lava: Warning message when users reach limit of guests that is possible to reserve for with the flow',
  },
})

const errors = defineMessages({
  reservationFailedError: {
    id: 'sharedFlows:lava.reservationFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to create a reservation, please contact our support center for help',
    description: 'Lava: Error message for failed reservation',
  },
  editReservationFailedError: {
    id: 'sharedFlows:lava.editReservationFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to edit your reservation, please contact our support center for help',
    description: 'Lava: Error message for failed reservation',
  },
})

export const lavaMessages = {
  info,
  warnings,
  errors,
}

/**
 * Typesafer way to checks if an element is included in a given array.
 * https://fettblog.eu/typescript-array-includes/#option-2%3A-a-helper-with-type-assertions
 * @template T, U
 * @param {ReadonlyArray<T>} coll - The array to check for the element.
 * @param {U} el - The element to check if it is included in the array.
 * @returns {boolean} - Returns true if the element is included in the array, false otherwise.
 */
export function isElementIncludedInArray<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T)
}

import { Component } from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
  notifyError: (error: Error) => void
}

interface ErrorBoundaryState {
  error: boolean
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  componentDidCatch(error: Error): void {
    this.setState({ error: true })
    this.props.notifyError(error)
    console.error(error)
  }

  render() {
    const { error } = this.state

    if (error) {
      return null
    }

    return this.props.children
  }
}

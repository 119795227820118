export const busStops = {
  0: {
    id: '45620',
    name: 'Reykjavík Terminal',
    address: 'Skógarhlíð 10, 101 Reykjavík',
    latitude: 64.13339560000001,
    longitude: -21.9181259,
  },
  1: {
    id: '45667',
    number: '1',
    bus: '1',
    type: 'bus',
    name: 'Bus stop 1 - Ráðhús - City Hall',
    address: 'Vonarstræti',
    latitude: 64.146316,
    longitude: -21.941491,
  },
  2: {
    id: '45671',
    number: '2',
    bus: '2',
    type: 'bus',
    name: 'Bus stop 2 - Tjörnin - The Pond',
    address: 'Lækjargata by Mæðragarður',
    latitude: 64.145763,
    longitude: -21.938547,
  },
  3: {
    id: '45672',
    number: '3',
    bus: '3',
    type: 'bus',
    name: 'Bus stop 3 - Lækjargata',
    address: 'Lækjargata 4',
    latitude: 64.14678,
    longitude: -21.937296,
  },
  4: {
    id: '283209',
    number: '4',
    bus: '4',
    type: 'bus',
    name: 'Bus stop 4 - Miðbakki',
    address: 'Parking lot by Geirsgata',
    latitude: 64.150278,
    longitude: -21.9405,
  },
  5: {
    id: '45674',
    number: '5',
    bus: '5',
    type: 'bus',
    name: 'Bus stop 5 - Harpa',
    address: 'Austurbakki 2',
    latitude: 64.149766,
    longitude: -21.929865,
  },
  6: {
    id: '45675',
    number: '6',
    bus: '6',
    type: 'bus',
    name: 'Bus stop 6 - Safnahúsið - The Culture House',
    address: 'Hverfisgata 15 by Arnarhóll',
    latitude: 64.147454,
    longitude: -21.932894,
  },
  8: {
    id: '45677',
    number: '8',
    bus: '8',
    type: 'bus',
    name: 'Bus stop 8 - Hallgrímskirkja',
    address: 'By Eiríksgata',
    latitude: 64.141548,
    longitude: -21.927973,
  },
  9: {
    id: '45678',
    number: '9',
    bus: '9',
    type: 'bus',
    name: 'Bus stop 9 - Snorrabraut',
    address: 'Snorrabraut 24',
    latitude: 64.143497,
    longitude: -21.916289,
  },
  /* Removed temporarily
  10: {
    id: '45668',
    number: '10',
    bus: '10',
    type: 'bus',
    name: 'Bus stop 10 - Hlemmur',
    address: 'Snorrabraut 105',
    latitude: 64.143155,
    longitude: -21.915414,
  },*/
  11: {
    id: '45669',
    number: '11',
    bus: '11',
    type: 'bus',
    name: 'Bus stop 11 - Austurbær',
    address: 'Snorrabraut 3',
    latitude: 64.142364,
    longitude: -21.917228,
  },
  12: {
    id: '45670',
    number: '12',
    bus: '12',
    type: 'bus',
    name: 'Bus stop 12 - Höfðatorg',
    address: 'Þórunnartún 1',
    latitude: 64.144389,
    longitude: -21.910327,
  },
  13: {
    id: '109654',
    number: '13',
    bus: '13',
    type: 'bus',
    name: 'Bus stop 13 - Rauðarárstígur',
    address: 'Laugavegur 120',
    latitude: 64.142658,
    longitude: -21.913866,
  },
  14: {
    id: '103137',
    number: '14',
    bus: '14',
    type: 'bus',
    name: 'Bus stop 14 - Skúlagata',
    address: 'Skúlagata',
    latitude: 64.148165,
    longitude: -21.927858,
  },
  bsi: {
    id: '45665',
    name: 'BSI bus terminal',
    address: 'Vatnsmýrarvegi 10',
    latitude: 64.1376866,
    longitude: -21.9366231,
  },
}

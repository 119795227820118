export const modularScale = 1.618
export const base = 16
export const topicLineSize = 20

// Horizontal padding
export const gridOffsetFrom = 24
export const gridOffsetTo = 78

// Design Grid
export const gutter = 40

export interface TransportationType {
  price: number
  departureTime?: DateTime
  pickupTime?: DateTime
  dropoffLocation?: Location
  pickupLocation?: Location
  pickup?: TransportationArea
  dropoff?: TransportationArea & { samesAsPickup?: boolean }
}

export interface Location {
  direction: string
  location: {
    id: string
  }
}

export interface DateTime {
  time: string
  date?: Date
}

export interface TransportationArea {
  type: LocationType
}

export enum Direction {
  pickup = 'pickup',
  dropoff = 'dropoff',
}

export enum LocationType {
  airport = 'airport',
  city = 'city',
  gullfoss = 'gullfoss',
}

import { add } from 'date-fns/add'
import { isAfter } from 'date-fns/isAfter'

import { FlowControl, SubFlowBookingInfo } from 'bl-flows-core'
import { MassageAndFloatAvailability } from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'

import { MassageState } from '../subflows'

type FilterSlots = {
  control: FlowControl
  massageType: string
  availability: MassageAndFloatAvailability['floats']
  bookingInfo: SubFlowBookingInfo
  date: string
  number: number
  isRetreatSpaBooking?: boolean
  checkSelectedMassages?: boolean
}

export const filterInWaterSlots = ({
  control,
  massageType,
  availability,
  bookingInfo,
  date,
  number,
  isRetreatSpaBooking = false,
  checkSelectedMassages = true,
}: FilterSlots) => {
  const currentMassageType = availability?.find(
    massage => massage.id === massageType
  )
  const timeSlots = currentMassageType?.slots ?? []

  const cutOffTime =
    massageType &&
    isRetreatSpaBooking &&
    bookingInfo?.entryDate &&
    cutOffTimeHoursMinutes[massageType] &&
    add(bookingInfo?.entryDate, {
      hours: cutOffTimeHoursMinutes[massageType].hours,
      minutes: cutOffTimeHoursMinutes[massageType].minutes,
    })

  const cutOffTimeInHours = cutOffTime && formatDateInUTC(cutOffTime, 'HH:mm')

  const isCheckinDay =
    !!bookingInfo?.hotelDates &&
    date === formatDateInUTC(bookingInfo?.hotelDates.arrivalDate)
  const isCheckoutDay =
    !!bookingInfo?.hotelDates &&
    date === formatDateInUTC(bookingInfo?.hotelDates.departureDate)

  return timeSlots?.filter(slot => {
    // checkin and checkout dates hardcoded because for a period of time
    // the check-in and check-out hours were modified when editing hotel dates
    // so we can't trust the api response.
    const checkInOutFilter = isCheckinDay
      ? slot.time >= '15:30' // Half an hour after check-in so that guests can't book at checkin (15:00)
      : isCheckoutDay
        ? slot.time <= '11:00'
        : true

    // Retreat Spa access is only 5 hours, customer should not be able to book a massage outside that time
    const retreatSpaCutOffFilter =
      isRetreatSpaBooking && cutOffTimeInHours
        ? slot.time <= cutOffTimeInHours
        : true

    // We need to count how many times the current timeslot
    // has been selected in other massage cards.
    // That way, the user won't be able to select a timeslot that has
    // already reached its availability limit
    // The filtering criteria are as follows:
    // 1. The slot's time matches another massage's time(from state)
    // 2. The selected date matches another's massage date (from state)
    // 3. The index of the massage is not the same as the current MassageCard index,
    //    because we want to allow the first person to select the slot to be able to do so.
    // We can then compare the selectedMassagesCountForSlot with slot.available to
    // determine if we show the slot or not.

    const selectedMassagesCountForSlot =
      control?.screen?.state?.massages?.filter(
        (massage: MassageState, iMassage: number) =>
          massage.time === slot.time &&
          massage.date &&
          formatDateInUTC(massage.date) === date &&
          iMassage !== number
      ).length

    const availableSlotsMoreThanSelected = checkSelectedMassages
      ? selectedMassagesCountForSlot < slot.available
      : true

    return (
      isAfter(new Date(`${date} ${slot?.time}`), new Date()) &&
      slot.available > 0 &&
      availableSlotsMoreThanSelected &&
      checkInOutFilter &&
      retreatSpaCutOffFilter
    )
  })
}

// Cutoff time for Retreat Spa bookings, the visits are 5 hours so customers
// should only be able to book massages/floats that can fit inside that window
// 3 hours means that we add 3 hours from the arrival time as a cutoff time to book
export const cutOffTimeHoursMinutes = {
  [PRODUCT_IDS.Massage30]: { hours: 3, minutes: 30 },
  [PRODUCT_IDS.Massage60]: { hours: 3, minutes: 0 },
  [PRODUCT_IDS.Massage120]: { hours: 2, minutes: 0 },
  [PRODUCT_IDS.MassageFloatingOne]: { hours: 3, minutes: 0 },
  [PRODUCT_IDS.MassageFloatingTwo]: { hours: 3, minutes: 0 },
  [PRODUCT_IDS.MassageFloatingMidnight]: { hours: 3, minutes: 0 },
  [PRODUCT_IDS.MassageFloatingGroup]: { hours: 3, minutes: 0 },
}

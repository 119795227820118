import { defineMessages } from 'react-intl'

const info = defineMessages({
  pickupLocationBreadcrumb: {
    id: 'sharedFlows:transportation.pickupLocationBreadcrumb',
    defaultMessage: 'Pickup location',
    description: 'Transportation: Pickup location breadcrumb label',
  },
  pickupTitle: {
    id: 'sharedFlows:transportation.pickupTitle',
    defaultMessage: 'Select pickup location',
    description: 'Transportation: Title on pickup location screen',
  },
  locationCity: {
    id: 'sharedFlows:transportation.locationCity',
    defaultMessage: 'Reykjavík downtown area',
    description:
      'Transportation: Label for location city - used in multiple places in the flow',
  },
  locationAirport: {
    id: 'sharedFlows:transportation.locationAirport',
    defaultMessage: 'Keflavík Airport',
    description:
      'Transportation: Label for location airport - used in multiple places in the flow',
  },
  locationGullfoss: {
    id: 'sharedFlows:transportation.locationGullfoss',
    defaultMessage: 'Gullfoss',
    description:
      'Transportation: Label for location Gullfoss - used in multiple places in the flow',
  },
  fromReykjavik: {
    id: 'sharedFlows:transportation.fromReykjavik',
    defaultMessage: 'From Reykjavík',
    description:
      'Transportation: Label for location Reykjavik - used in overview screen for bus transport',
  },
  fromKeflavikAirport: {
    id: 'sharedFlows:transportation.fromKeflavikAirport',
    defaultMessage: 'From Keflavík Airport',
    description:
      'Transportation: Label for location Keflavik Airport - used in overview screen for bus transport',
  },
  fromTheBlueLagoon: {
    id: 'sharedFlows:transportation.fromTheBlueLagoon',
    defaultMessage: 'From The Blue Lagoon',
    description:
      'Transportation: Label for location Blue Lagoon - used in overview screen for bus transport',
  },
  locationPriceRoundtrip: {
    id: 'sharedFlows:transportation.locationPriceRoundtrip',
    defaultMessage: '%p for roundtrip',
    description:
      '{Price} for roundtrip (%p is for the formatter to work in this specific case)',
  },
  pickupTimeBreadcrumb: {
    id: 'sharedFlows:transportation.pickupTimeBreadcrumb',
    defaultMessage: 'Pickup time',
    description: 'Transportation: Pickup time breadcrumb label',
  },
  pickupTimeTitle: {
    id: 'sharedFlows:transportation.pickupTimeTitle',
    defaultMessage: 'Choose pickup time',
    description: 'Transportation: Title on pickup time screen',
  },
  pickupTimeSubtitle: {
    id: 'sharedFlows:transportation.pickupTimeSubtitle',
    defaultMessage:
      'Based on your Blue Lagoon booking we recommend the following pickup times',
    description: 'Transportation: Subtitle on pickup time screen',
  },
  editPickupTimeSubtitle: {
    id: 'sharedFlows:transportation.editPickupTime',
    defaultMessage: 'You previous pickup time was at {time}',
    description: 'Transportation: Subtitle on pickup time screen when editing',
  },
  dropoffLocationBreadcrumb: {
    id: 'sharedFlows:transportation.dropoffLocationBreadcrumb',
    defaultMessage: 'Dropoff location',
    description: 'Transportation: Dropoff location breadcrumb label',
  },
  dropoffTitle: {
    id: 'sharedFlows:transportation.dropoffTitle',
    defaultMessage: 'Select dropoff location',
    description: 'Transportation: Title on dropoff location screen',
  },
  sameAsPickup: {
    id: 'sharedFlows:transportation.sameAsPickup',
    defaultMessage: 'Same as pickup',
    description:
      'Transportation: Same as pickup option in dropoff location selection',
  },
  departureTimeBreadcrumb: {
    id: 'sharedFlows:transportation.departureTimeBreadcrumb',
    defaultMessage: 'Departure time',
    description: 'Transportation: Departure time breadcrumb label',
  },
  departureTimeTitle: {
    id: 'sharedFlows:transportation.departureTimeTitle',
    defaultMessage: 'Choose departure time',
    description: 'Transportation: Title on departure time screen',
  },
  departureTimeSubtitle: {
    id: 'sharedFlows:transportation.departureTimeSubtitle',
    defaultMessage:
      'Based on your Blue Lagoon booking we recommend the following departure times.',
    description: 'Transportation: Subtitle on departure time screen',
  },
  editDepartureTimeSubtitle: {
    id: 'sharedFlows:transportation.editDepartureTimeSubtitle',
    defaultMessage: 'Your previous departure time was at {time}',
    description:
      'Transportation: Subtitle on departure time screen when editing',
  },
  overviewSubtitle: {
    id: 'sharedFlows:transportation.overviewSubtitle',
    defaultMessage:
      'Here are the bus transfer options you have selected. Please make sure everything below is correct.',
    description: 'Transportation: Subtitle on overview screen',
  },
  submitLabel: {
    id: 'sharedFlows:transportation.submitLabel',
    defaultMessage: 'Add transportation',
    description: 'Transportation: Label on add transportation button',
  },
  confirmationTitle: {
    id: 'sharedFlows:transportation.confirmationTitle',
    defaultMessage: 'Booking confirmed',
    description: 'Transportation: Title on confirmation screen',
  },
  confirmationEditSubtitle: {
    id: 'sharedFlows:transportation.confirmationEditSubtitle',
    defaultMessage:
      'Your booking has successfully been updated, below you can see the details of your new booking',
    description: 'Transportation: Subtitle on confirmation screen when editing',
  },
  confirmationSummary: {
    id: 'sharedFlows:transportation.confirmationSummary',
    defaultMessage: 'Summary:',
    description: 'Transportation: Summary label on confirmation screen',
  },
  dropoff: {
    id: 'sharedFlows:transportation.dropoff',
    defaultMessage: 'Dropoff:',
    description:
      'Dropoff in newly edited/added transportation booking, used on confirmation screen',
  },
  pickup: {
    id: 'sharedFlows:transportation.pickup',
    defaultMessage: 'Pickup:',
    description:
      'Pickup in newly edited/added transportation booking, used on confirmation screen',
  },
  confirmationPickupAndDropoff: {
    id: 'sharedFlows:transportation.confirmationPickupAndDropoff',
    defaultMessage: '{location}, {date} at {time}',
    description:
      'Transportation: Dropoff in newly edited/added transportation booking',
  },
  pickupLocationShuttleDisclaimer: {
    id: 'sharedFlows:transportation.pickupLocationShuttleDisclaimer',
    defaultMessage:
      'The shuttle will take you to the Reykjavík Terminal bus station, with pickups scheduled up to 30 minutes before the bus departs from the terminal.',
    description: 'Transportation: Disclaimer text on pickup location screen',
  },
  pickupTimeShuttleDisclaimer: {
    id: 'sharedFlows:transportation.pickupTimeShuttleDisclaimer',
    defaultMessage:
      'The shuttle will take you to the Reykjavík Terminal bus station, with pickups scheduled up to 30 minutes before the bus departs from the terminal.',
    description: 'Transportation: Disclaimer text on pickup location screen',
  },
  pickupTimeNonTerminalSubtitle: {
    id: 'sharedFlows:transportation.pickupTimeNonTerminalSubtitle',
    defaultMessage:
      'Based on your Blue Lagoon booking, we recommend the following bus transfer times. Please be ready 30 minutes in advance since the shuttle will take you to the Reykjavík Terminal',
    description: 'Transportation: Subtitle on pickup time screen',
  },
  shuttlePickupAt: {
    id: 'sharedFlows:transportation.shuttlePickupAt',
    defaultMessage: 'Shuttle pickup at',
    description: 'Transportation: Shuttle pickup at location',
  },
  shuttleDropoffAt: {
    id: 'sharedFlows:transportation.shuttleDropoffAt',
    defaultMessage: 'Shuttle dropoff at',
    description: 'Transportation: Shuttle dropoff at location',
  },
  beReadyAt: {
    id: 'sharedFlows:transportation.beReadyAt',
    defaultMessage: 'Be ready at',
    description: 'Transportation: Be ready at time',
  },
  atTime: {
    id: 'sharedFlows:transportation.atTime',
    defaultMessage: 'at',
    description: 'Transportation: At time',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  submitError: {
    id: 'sharedFlows:transportation.submitError',
    defaultMessage:
      'Unfortunately an error occurred when trying to add the transportation to you booking booking, please contact our support center for help',
    description: 'Transportation: Error message for a failed submit',
  },
  editError: {
    id: 'sharedFlows:transportation.editError',
    defaultMessage:
      'Unfortunately an error occurred when trying to edit your transportation, please contact our support center for help',
    description: 'Transportation: Error message for a failed edit',
  },
})

export const transportationMessages = {
  info,
  warnings,
  errors,
}

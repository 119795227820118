import { addDays } from 'date-fns/addDays'
import { addMinutes } from 'date-fns/addMinutes'
import { isBefore } from 'date-fns/isBefore'
import { parseISO } from 'date-fns/parseISO'

import { SubFlowActivity, TimeSlot } from 'bl-flows-core'
import { AdmissionItem, CartItem } from 'bl-graphql'
import {
  getTimeFromDateTimeString,
  setDateTimeISOString,
} from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

type SharedActivity = SubFlowActivity & CartItem

// get latest time in the booking + duration
const getLatestTime = (entryDate: Date, lastActivity: SharedActivity) => {
  if (
    lastActivity?.duration > 0 &&
    // lastActivity is "as AdimissionItem" even though it can be other activities, because of issues with union type
    (lastActivity?.date || (lastActivity as AdmissionItem)?.meta?.arrivalTime)
  ) {
    return addMinutes(
      new Date(
        lastActivity?.date || (lastActivity as AdmissionItem)?.meta?.arrivalTime
      ),
      lastActivity?.duration
    )
  } else {
    return addMinutes(entryDate, 180)
  }
}

export const getRecommendedDepartureSlots = (
  timeSlots: TimeSlot[],
  entryDate: Date,
  lastActivity?: SharedActivity
): TimeSlot[] => {
  if (!timeSlots || timeSlots.length === 0) {
    return []
  }

  const entryDateStr = formatDateInUTC(entryDate)
  const nextDay = addDays(entryDate, 1)
  const nextDayStr = formatDateInUTC(nextDay)

  const latestTime = getLatestTime(entryDate, lastActivity)

  // Filter slots for the next day after midnight
  const nextDaySlots = timeSlots.filter(slot => {
    return slot.date === nextDayStr
  })

  // This is a hack to cast the entryDate object to a iso string and then extract the time
  const entryDateTime = getTimeFromDateTimeString(
    setDateTimeISOString(
      formatDateInUTC(entryDate),
      formatDateInUTC(entryDate, 'HH:mm')
    )
  )
  const sixPM = '18:00'
  const isAfterSixPM = entryDateTime >= sixPM

  // Filter slots based on the latest time logic
  const slotsAfterLatestTime = timeSlots.filter(
    slot =>
      !isBefore(
        parseISO(`${entryDateStr} ${slot.time}`),
        parseISO(formatDateInUTC(latestTime, 'yyyy-MM-dd HH:mm'))
      )
  )

  // If there aren't any slots 3 hours after arriving, recommend the last two departures
  const recommendedSlots =
    slotsAfterLatestTime.length > 0
      ? slotsAfterLatestTime.slice(0, 2)
      : timeSlots.slice(-2)

  // Combine the recommended slots with the next day slots if the entry date is after 8 PM
  if (isAfterSixPM) {
    const combinedSlots = [...recommendedSlots, ...nextDaySlots]
    return combinedSlots.filter((element, index) => {
      return combinedSlots.indexOf(element) === index
    })
  }
  return recommendedSlots
}

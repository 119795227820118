import { defineMessages } from 'react-intl'

const info = defineMessages({
  typeBreadcrumb: {
    id: 'sharedFlows:privateTransfer.typeBreadcrumb',
    defaultMessage: 'Transfer type',
    description: 'Private transfer: Transfer type breadcrumb label',
  },
  typeTitle: {
    id: 'sharedFlows:privateTransfer.typeTitle',
    defaultMessage: 'Book Private Airport Transfer',
    description: 'Private transfer: Title on transfer type screen',
  },
  typeSubtitle: {
    id: 'sharedFlows:privateTransfer.typeSubtitle',
    defaultMessage: 'Please select your type of transfer',
    description: 'Private transfer: Subtitle on transfer type screen',
  },
  arrivalBreadcrumb: {
    id: 'sharedFlows:privateTransfer.arrivalBreadcrumb',
    defaultMessage: 'Arrival',
    description: 'Private transfer: Arrival breadcrumb label',
  },
  arrivalLabel: {
    id: 'sharedFlows:privateTransfer.arrivalLabel',
    defaultMessage: 'Arrival',
    description: 'Arrival label',
  },
  arrivalServioSmall: {
    id: 'sharedFlows:privateTransfer.arrivalServioSmall',
    defaultMessage: 'Arrival transfer - Luxury Sedan for 1-2 guests',
    description: 'Private transfer: Arrival card heading for 1-2 guests',
  },
  arrivalServioMedium: {
    id: 'sharedFlows:privateTransfer.arrivalServioMedium',
    defaultMessage: 'Arrival transfer - Business Minivan for 1-4 guests',
    description: 'Private transfer: Arrival card heading for 1-4 guests',
  },
  arrivalServioLarge: {
    id: 'sharedFlows:privateTransfer.arrivalServioLarge',
    defaultMessage: 'Arrival transfer - Luxury Sprinter for 1-9 guests',
    description: 'Private transfer: Arrival card heading for 1-9 guests',
  },
  departureServioSmall: {
    id: 'sharedFlows:privateTransfer.departureServioSmall',
    defaultMessage: 'Departure transfer - Luxury Sedan for 1-2 guests',
    description: 'Private transfer: Departure card heading for 1-2 guests',
  },
  departureServioMedium: {
    id: 'sharedFlows:privateTransfer.departureServioMedium',
    defaultMessage: 'Departure transfer - Business Minivan for 1-4 guests',
    description: 'Private transfer: Departure card heading for 1-4 guests',
  },
  departureServioLarge: {
    id: 'sharedFlows:privateTransfer.departureServioLarge',
    defaultMessage: 'Departure transfer - Luxury Sprinter for 1-9 guests',
    description: 'Private transfer: Departure card heading for 1-9 guests',
  },
  arrivalSmallheading: {
    id: 'sharedFlows:privateTransfer.arrivalSmallheading',
    defaultMessage: 'Arrival transfer - Taxi for 1-3 guests',
    description: 'Private transfer: Arrival card heading for 1-3 people',
  },
  arrivalLargeHeading: {
    id: 'sharedFlows:privateTransfer.arrivalLargeHeading',
    defaultMessage: 'Arrival transfer - Taxi for 4-8 guests',
    description: 'Private transfer: Arrival card heading for 4-8 people',
  },
  arrivalDescription: {
    id: 'sharedFlows:privateTransfer.arrivalDescription',
    defaultMessage:
      'A taxi will transfer up to {guests} guests from the airport and transport you to {property}',
    description: 'Private transfer: Description for arrival cards',
  },
  arrivalServioDescription: {
    id: 'sharedFlows:privateTransfer.arrivalServioDescription',
    defaultMessage:
      'Your driver will meet you in the arrivals hall of Keflavík Airport holding a sign with your name for your transfer to the Retreat.',
    description: 'Private transfer: Description for arrival cards - Servio',
  },
  departureBreadcrumb: {
    id: 'sharedFlows:privateTransfer.departureBreadcrumb',
    defaultMessage: 'Departure',
    description: 'Private transfer: Departure breadcrumb label',
  },
  departureLabel: {
    id: 'sharedFlows:privateTransfer.departureLabel',
    defaultMessage: 'Departure',
    description: 'Private transfer: Departure label',
  },
  departureSmallHeading: {
    id: 'sharedFlows:privateTransfer.departureSmallHeading',
    defaultMessage: 'Departure transfer - Taxi for 1-3 guests',
    description: 'Private transfer: Departure card heading for 1-3 people',
  },
  departureLargeHeading: {
    id: 'sharedFlows:privateTransfer.departureLargeHeading',
    defaultMessage: 'Departure transfer - Taxi for 4-8 guests',
    description: 'Private transfer: Departure card heading for 4-8 people',
  },
  departureDescription: {
    id: 'sharedFlows:privateTransfer.departureDescription',
    defaultMessage:
      'A taxi will transfer up to {guests} guests from {property} to the airport',
    description: 'Private transfer: Description for departure cards',
  },
  departureServioDescription: {
    id: 'sharedFlows:privateTransfer.departureServioDescription',
    defaultMessage:
      'Your driver will meet you in the Retreat Lounge for your transfer to Keflavík Airport.',
    description: 'Private transfer: Description for departure cards - Servio',
  },
  arrivalTitle: {
    id: 'sharedFlows:privateTransfer.arrivalTitle',
    defaultMessage: 'Book Arrival Transfer',
    description: 'Private transfer: Title on transfer arrival screen',
  },
  arrivalSubtitle: {
    id: 'sharedFlows:privateTransfer.arrivalSubtitle',
    defaultMessage:
      'Please select a time for your transfer from Keflavik Airport to {property} on {arrivalDate}',
    description: 'Private transfer: Subtitle on transfer arrival screen',
  },
  arrivalServioSubtitle: {
    id: 'sharedFlows:privateTransfer.arrivalServioSubtitle',
    defaultMessage:
      'Please select a transfer time from Keflavík Airport to the Retreat for {arrivalDate}',
    description:
      'Private transfer: Subtitle on transfer arrival screen - Servio',
  },
  departureTitle: {
    id: 'sharedFlows:privateTransfer.departureTitle',
    defaultMessage: 'Book Departure Transfer',
    description: 'Private transfer: Title on transfer departure screen',
  },
  departureSubtitle: {
    id: 'sharedFlows:privateTransfer.departureSubtitle',
    defaultMessage:
      'Please select a time for your transfer from {property} to Keflavik Airport on {departureDate}',
    description: 'Private transfer: Subtitle on transfer departure screen',
  },
  departureServioSubtitle: {
    id: 'sharedFlows:privateTransfer.departureServioSubtitle',
    defaultMessage:
      'Please select a transfer time from the Retreat to Keflavík Airport for {departureDate}',
    description:
      'Private transfer: Subtitle on transfer departure screen - Servio',
  },
  priceFromFormat: {
    id: 'sharedFlows:privateTransfer.priceFromFormat',
    defaultMessage: 'Price from %p',
    description: 'Private transfer: Subtitle on transfer departure screen',
  },
  pickupLabel: {
    id: 'sharedFlows:privateTransfer.pickupLabel',
    defaultMessage: 'Pick up time',
    description: 'Private transfer: Pick up time label',
  },
  estimatedArrivalFlightTimeLabel: {
    id: 'sharedFlows:privateTransfer.estimatedArrivalFlightTimeLabel',
    defaultMessage: 'Estimated arrival flight time',
    description: 'Private transfer: Estimated arrival flight time label',
  },
  estimatedDepartureFlightTimeLabel: {
    id: 'sharedFlows:privateTransfer.estimatedDepartureFlightTimeLabel',
    defaultMessage: 'Estimated departure flight time',
    description: 'Private transfer: Estimated departure flight time label',
  },
  departureDisclaimer: {
    id: 'sharedFlows:privateTransfer.departureDisclaimer',
    defaultMessage:
      'We recommend that pick up is at least 2,5 hours before time of flight',
    description:
      'Private transfer: disclaimer that pickup needs to be 2,5 hours before flight ',
  },
  flightNrLabel: {
    id: 'sharedFlows:privateTransfer.flightNrLabel',
    defaultMessage: 'Flight number',
    description: 'Private transfer: Flight number label',
  },
  flightNrPlaceholder: {
    id: 'sharedFlows:privateTransfer.flightNrPlaceholder',
    defaultMessage: 'Your flight number',
    description: 'Private transfer: Flight number placeholder',
  },
  commentLabel: {
    id: 'sharedFlows:privateTransfer.commentLabel',
    defaultMessage: 'Comment',
    description: 'Private transfer: Comment label',
  },
  characterCount: {
    id: 'sharedFlows:privateTransfer.characterCount',
    defaultMessage: '{count}/70 characters',
    description: 'Private transfer: Character count for comment',
  },
  commentPlaceholder: {
    id: 'sharedFlows:privateTransfer.commentPlaceholder',
    defaultMessage: 'Would you like to add something?',
    description: 'Private transfer: Comment placeholder',
  },
  arrivalServioSmallOverview: {
    id: 'sharedFlows:privateTransfer.arrivalServioSmallOverview',
    defaultMessage: 'Arrival transfer for 1-2 guests',
    description:
      'Private transfer: Arrival for 1-2 guests description on overview screen for Servio',
  },
  arrivalServioMediumOverview: {
    id: 'sharedFlows:privateTransfer.arrivalServioMediumOverview',
    defaultMessage: 'Arrival transfer for 1-4 guests',
    description:
      'Private transfer: Arrival for 1-4 guests description on overview screen for Servio',
  },
  arrivalServioLargeOverview: {
    id: 'sharedFlows:privateTransfer.arrivalServioLargeOverview',
    defaultMessage: 'Arrival transfer for 1-9 guests',
    description:
      'Private transfer: Arrival for 1-9 guests description on overview screen for Servio',
  },
  departureServioSmallOverview: {
    id: 'sharedFlows:privateTransfer.departureServioSmallOverview',
    defaultMessage: 'Departure transfer for 1-2 guests',
    description:
      'Private transfer: Departure for 1-2 guests description on overview screen for Servio',
  },
  departureServioMediumOverview: {
    id: 'sharedFlows:privateTransfer.departureServioMediumOverview',
    defaultMessage: 'Departure transfer for 1-4 guests',
    description:
      'Private transfer: Departure for 1-4 guests description on overview screen for Servio',
  },
  departureServioLargeOverview: {
    id: 'sharedFlows:privateTransfer.departureServioLargeOverview',
    defaultMessage: 'Departure transfer for 1-9 guests',
    description:
      'Private transfer: Departure for 1-9 guests description on overview screen for Servio',
  },
  arrivalSmallOverview: {
    id: 'sharedFlows:privateTransfer.arrivalSmallOverview',
    defaultMessage: 'Arrival transfer for 1-3 guests',
    description:
      'Private transfer: Arrival for 1-3 guests description on overview screen',
  },
  arrivalLargeOverview: {
    id: 'sharedFlows:privateTransfer.arrivalLargeOverview',
    defaultMessage: 'Arrival transfer for 4-8 guests',
    description:
      'Private transfer: Arrival for 4-8 guests description on overview screen',
  },
  departureSmallOverview: {
    id: 'sharedFlows:privateTransfer.departureSmallOverview',
    defaultMessage: 'Departure transfer for 1-3 guests',
    description:
      'Private transfer: Departure for 1-3 guests description on overview screen',
  },
  departureLargeOverview: {
    id: 'sharedFlows:privateTransfer.departureLargeOverview',
    defaultMessage: 'Departure transfer for 4-8 guests',
    description:
      'Private transfer: Departure for 4-8 guests description on overview screen',
  },
  overviewConfirmButton: {
    id: 'sharedFlows:privateTransfer.overviewConfirmButton',
    defaultMessage: 'Confirm',
    description: 'Private transfer: Confirm button on the overview screen',
  },
  confirmationTitleHBE: {
    id: 'sharedFlows:privateTransfer.confirmationTitleHBE',
    defaultMessage: 'Transfer added to cart',
    description: 'Private transfer: Title on confirmation screen for HBE',
  },
  confirmationTitleMYB: {
    id: 'sharedFlows:privateTransfer.confirmationTitleMYB',
    defaultMessage: 'Booking Confirmed',
    description: 'Private transfer: Title on confirmation screen for MYB',
  },
  dropOffLocation: {
    id: 'sharedFlows:privateTransfer.dropOffLocation',
    defaultMessage: 'Drop-off location',
    description: 'Private transfer: Drop off location prefix',
  },
  pickupLocation: {
    id: 'sharedFlows:privateTransfer.pickupLocation',
    defaultMessage: 'Pick-up location',
    description: 'Private transfer: Pick up location prefix',
  },
  pickupTime: {
    id: 'sharedFlows:privateTransfer.pickupTime',
    defaultMessage: 'Pick-up time',
    description: 'Private transfer: Pick up time prefix',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  bookingFailedError: {
    id: 'sharedFlows:privateTransfer.bookingFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to book a transfer, please contact our support center for help',
    description: 'Private transfer: Error message for failed booking',
  },
})

export const privateTransferMessages = {
  info,
  warnings,
  errors,
}

import { isAfter } from 'date-fns/isAfter'
import { isBefore } from 'date-fns/isBefore'
import { parseISO } from 'date-fns/parseISO'
import { subMinutes } from 'date-fns/subMinutes'

import { SubFlowActivity, TimeSlot } from 'bl-flows-core'
import { AdmissionItem, CartItem } from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

type SharedActivity = SubFlowActivity & CartItem

const getTransportationPickupSelectionEndTime = (
  timeSlots: TimeSlot[],
  duration: number,
  entryDate: Date,
  firstActivity: SharedActivity
) => {
  if (!timeSlots || timeSlots.length === 0) {
    return ''
  }

  const entryDateStr = formatDateInUTC(entryDate, 'yyyy-MM-dd')

  // find earliest booking time (restaurant reservation or ticket time)
  // firstActivity is "as AdimissionItem" even though it can be other activities, because of issues with union type
  let earliestDate
  if (
    firstActivity?.date ||
    (firstActivity as AdmissionItem)?.meta?.arrivalTime
  ) {
    earliestDate = isBefore(
      new Date(
        firstActivity.date ||
          (firstActivity as AdmissionItem)?.meta?.arrivalTime
      ),
      entryDate
    )
      ? firstActivity?.date ||
        (firstActivity as AdmissionItem)?.meta?.arrivalTime
      : entryDate
  } else {
    earliestDate = entryDate
  }

  const slotsAfterPickupTimeAndDuration = timeSlots.filter(
    slot =>
      !isAfter(
        parseISO(`${entryDateStr} ${slot.time}Z`),
        subMinutes(new Date(earliestDate), duration)
      )
  )

  return slotsAfterPickupTimeAndDuration.length > 0
    ? slotsAfterPickupTimeAndDuration[
        slotsAfterPickupTimeAndDuration.length - 1
      ].time
    : ''
}

export const getAvailablePickupSlots = (
  timeSlots: TimeSlot[],
  duration: number,
  entryDate: Date,
  firstActivity?: SharedActivity
) => {
  const endTime = getTransportationPickupSelectionEndTime(
    timeSlots,
    duration,
    entryDate,
    firstActivity
  )

  const endIndex = timeSlots.findIndex(slot => slot.time === endTime)

  return timeSlots.slice(0, endIndex + 1)
}

import styled, { css } from 'styled-components'

import { PartialBookingEngine } from '../styles/types'
import { Type } from './Typography/Typography'

type AlertWrapperProps = {
  withTopPadding?: boolean
  themeStyle?: PartialBookingEngine['discountAccordionField']['activeDiscountCode']
}

const AlertWrapper = styled.div<AlertWrapperProps>`
  padding: ${({ theme }) => `0 ${theme.spacing[1]}`};
  height: 56px;
  margin-top: ${({ theme, withTopPadding }) =>
    withTopPadding ? theme.spacing[2] : 0};
  display: flex;
  align-items: center;

  ${({ color = 'green', themeStyle }) =>
    color === 'green'
      ? css`
          border: 1px solid #169222;
          background: ${themeStyle?.activePromoColor ??
          'rgba(22, 146, 34, 0.1)'};
          color: ${themeStyle?.textColor};
        `
      : css`
          border: 1px solid #52a5b8;
          background: ${themeStyle?.activeGiftCardColor ??
          'rgba(82, 165, 184, 0.1)'};
          color: ${themeStyle?.textColor};
        `}

  button {
    margin-left: auto;
  }
`

const CloseButton = styled.button`
  text-decoration: underline;
  height: 100%;
  width: 44px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Check = () => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height="18px"
    width="18px"
    style={{ marginRight: '14px' }}
  >
    <path
      d="M4.77637 7.49275L8.92562 12.5077L16.2092 3.01514"
      stroke="#454647"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.806 7.31343C16.9254 7.85075 16.9851 8.41791 16.9851 8.98507C16.9851 13.403 13.403 16.9851 8.98507 16.9851C4.56716 16.9851 0.985075 13.403 0.985075 8.98507C0.985075 4.56716 4.56716 0.985075 8.98507 0.985075C10.2388 0.985075 11.4328 1.28358 12.5075 1.79104L13.1045 0.985075C11.8806 0.358209 10.4776 0 8.98507 0C4.02985 0 0 4.02985 0 8.98507C0 13.9403 4.02985 17.9701 8.98507 17.9701C13.9403 17.9701 17.9701 13.9403 17.9701 8.98507C17.9701 8.0597 17.8209 7.19403 17.5821 6.35821L16.806 7.31343Z"
      fill="#454647"
    />
  </svg>
)

export const Alert = ({
  closeLabel = 'Close',
  onClose,
  type = 'success',
  withTopPadding = false,
  children,
  color,
  themeStyle = undefined as PartialBookingEngine['discountAccordionField']['activeDiscountCode'],
}) => {
  const Icon = type === 'success' ? Check : null
  return (
    <AlertWrapper
      withTopPadding={withTopPadding}
      color={color}
      themeStyle={themeStyle}
    >
      <Icon />
      <Type size={{ xs: 12, md: 14 }}>{children}</Type>
      <CloseButton onClick={onClose} type="button">
        <Type size={{ xs: 12, md: 14 }}>{closeLabel}</Type>
      </CloseButton>
    </AlertWrapper>
  )
}

import { between } from './between'
import { base, modularScale } from './sizes'

const createSpacing = () => {
  const obj: Record<number, string> = {}
  const smallest = -32
  const largest = 32
  const step = 0.5

  for (let val = smallest; val <= largest; val += step) {
    obj[val] = between((base / modularScale) * val, base * val)
  }

  return obj
}

export const spacing = createSpacing()

import { useTheme } from 'styled-components'

import { renderFields } from '../../renderers/renderUtils'
import {
  FlowLayoutComponent,
  FlowSidebarLayout,
  FlowSidebarLayoutFields,
} from '../../types'
import {
  ContentContainer,
  layoutAnimation,
  RightContent,
  Sidebar,
  Wrapper,
} from './styles'

type SidebarLayoutProps = FlowLayoutComponent<FlowSidebarLayoutFields> &
  FlowSidebarLayout['props']

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  screenTheme,
  fields,
  control,
  columnStyle = false,
}) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.sidebar
  const screenThemes = theme?.bookingEngineScreenThemes

  return (
    <Wrapper>
      <ContentContainer
        {...layoutAnimation}
        screentheme={screenTheme}
        screenthemes={screenThemes}
      >
        {renderFields(fields.main, control, screenTheme, false, columnStyle)}
      </ContentContainer>
      <Sidebar $backgroundColor={themeStyle?.backgroundColor}>
        <RightContent>
          {fields.right &&
            renderFields(
              fields.right,
              control,
              screenTheme,
              false,
              columnStyle
            )}
        </RightContent>
        {fields.rightBottom && (
          <div
            style={{
              marginTop: 'auto',
            }}
          >
            {renderFields(
              fields.rightBottom,
              control,
              screenTheme,
              false,
              columnStyle
            )}
          </div>
        )}
      </Sidebar>
    </Wrapper>
  )
}

import { defineMessages } from 'react-intl'

const info = defineMessages({
  aboutBreadcrumb: {
    id: 'sharedFlows:massage.aboutBreadcrumb',
    defaultMessage: 'About',
    description: 'Massage: About breadcrumb label',
  },
  aboutTitle: {
    id: 'sharedFlows:massage.aboutTitle',
    defaultMessage: 'Would you like to experience an in-water massage?',
    description: 'Massage: Title on about screen',
  },
  aboutSubtitle: {
    id: 'sharedFlows:massage.aboutSubtitle',
    defaultMessage:
      'Relaxing. Euphoric. Hypnotic. In-water massage opens new vistas of wellbeing for your mind, body, and spirit.',
    description: 'Massage: Subtitle on about screen',
  },
  aboutChooseButton: {
    id: 'sharedFlows:massage.aboutChooseButton',
    defaultMessage: 'Choose your massage',
    description: 'Massage: Choose massage button',
  },
  quantityBreadcrumb: {
    id: 'sharedFlows:massage.quantityBreadcrumb',
    defaultMessage: 'Quantity',
    description: 'Massage: Quantity breadcrumb label',
  },
  quantityTitle: {
    id: 'sharedFlows:massage.quantityTitle',
    defaultMessage: 'How many massages?',
    description: 'Massage: Title on quantity screen',
  },
  quantitySubtitle: {
    id: 'sharedFlows:massage.quantitySubtitle',
    defaultMessage: 'How many in-water massages would you like to book?',
    description: 'Massage: Subtitle on quantity screen',
  },
  quantityPickerLabel: {
    id: 'sharedFlows:massage.quantityPickerLabel',
    defaultMessage: 'Number of massages',
    description: 'Massage: Label for quantity picker',
  },
  quantityContinueButton: {
    id: 'sharedFlows:massage.quantityContinueButton',
    defaultMessage: 'Continue',
    description: 'Massage: Continue button label',
  },
  timeBreadcrumb: {
    id: 'sharedFlows:massage.timeBreadcrumb',
    defaultMessage: 'Type & time',
    description: 'Massage: Type & time breadcrumb label',
  },
  editTimeBreadcrumb: {
    id: 'sharedFlows:massage.editTimeBreadcrumb',
    defaultMessage: 'Time',
    description: 'Massage: Edit time breadcrumb label',
  },
  editTimeSubtitle: {
    id: 'sharedFlows:massage.editTimeSubtitle',
    defaultMessage: 'Your previous booking was at {time}.',
    description: 'Massage: Subtitle for time screen when editing',
  },
  timeTitle: {
    id: 'sharedFlows:massage.timeTitle',
    defaultMessage: 'Select Massage',
    description: 'Massage: Title on time screen',
  },
  timeSubtitle: {
    id: 'sharedFlows:massage.timeSubtitle',
    defaultMessage:
      'We recommend scheduling your massage at least 30 to 60 minutes after your arrival at Blue Lagoon.',
    description: 'Massage: Subtitle on time screen',
  },
  editTimeTitle: {
    id: 'sharedFlows:massage.editTimeTitle',
    defaultMessage: 'Select time',
    description: 'Massage: Title on time screen when editing',
  },
  timeCardHeading: {
    id: 'sharedFlows:massage.timeCardHeading',
    defaultMessage: 'Massage {number}',
    description: 'Massage: Type & time card heading',
  },
  timeRadioLabel: {
    id: 'sharedFlows:massage.timeRadioLabel',
    defaultMessage: '{duration} minute in-water massage {price}',
    description: 'Massage: Label for massage radio buttons',
  },
  timeConfirmButton: {
    id: 'sharedFlows:massage.timeConfirmButton',
    defaultMessage: 'Confirm',
    description: 'Massage: Confirm button label',
  },
  overviewSubTitle: {
    id: 'sharedFlows:massage.overviewSubTitle',
    defaultMessage: 'Kindly ensure your timely arrival at the massage area.',
    description: 'Massage: Subtitle on overview screen',
  },
  confirmationTitle: {
    id: 'sharedFlows:massage.confirmationTitle',
    defaultMessage: 'In-water massage confirmation',
    description: 'Massage: Title on confirmation screen',
  },
  confirmationTitleCart: {
    id: 'sharedFlows:massage.confirmationTitleCart',
    defaultMessage: 'Your massage has been added to cart',
    description: 'Massage: Title on confirmation screen when adding to cart',
  },
  confirmationSubtitle: {
    id: 'sharedFlows:massage.confirmationSubtitle',
    defaultMessage: 'You will be charged upon arrival.',
    description: 'Massage: Subtitle on confirmation screen',
  },
  confirmationEditSubtitle: {
    id: 'sharedFlows:massage.confirmationEditSubtitle',
    defaultMessage:
      'Your booking has successfully been updated, below you can see the details of your new booking',
    description: 'Massage: Subtitle on confirmation screen when editing',
  },
  confirmationSummary: {
    id: 'sharedFlows:massage.confirmationSummary',
    defaultMessage: 'Summary:',
    description: 'Massage: Summary label on confirmation screen',
  },
  confirmationSummaryTime: {
    id: 'sharedFlows:massage.confirmationSummaryTime',
    defaultMessage: '{date} at {time}',
    description: 'Massage: Date & time info on confirmation screen',
  },
  overviewSummaryQuantity: {
    // Used in getItemsForMassageOverview()
    id: 'sharedFlows:massage.overviewSummaryQuantity',
    defaultMessage: 'for  {count, plural, one {# guest} other {# guests}} ',
    description: 'Massage: Number of guests on confirmation screen',
  },
  massage30Description: {
    id: 'sharedFlows:massage.massage30Description',
    defaultMessage:
      'Draped in a warm blanket and supported by a floatation mat, you enter a state of total comfort, regardless of the weather.',
    description: 'Massage: 30 min massage description',
  },
  massage60Description: {
    id: 'sharedFlows:massage.massage60Description',
    defaultMessage:
      'Draped in a warm blanket and supported by a floatation mat, you enter a state of total comfort, regardless of the weather.',
    description: 'Massage: 60 min massage description',
  },
  massage120Description: {
    id: 'sharedFlows:massage.massage120Description',
    defaultMessage:
      'A 30-minute silica salt scrub followed by a 30-minute algae or silica body wrap and a 60-minute relaxing massage.',
    description: 'Massage: 120 min massage description',
  },
  singleFloatDescription: {
    id: 'sharedFlows:massage.singleFloatDescription',
    defaultMessage:
      '{duration}-minutes of floatation and gentle bodywork. One guest and one bodyworker.',
    description: 'Massage: Single float description',
  },
  couplesFloatDescription: {
    id: 'sharedFlows:massage.couplesFloatDescription',
    defaultMessage:
      '{duration}-minutes of floatation and gentle bodywork. Two guests and one bodyworker.',
    description: 'Massage: Couples float description',
  },
  groupFloatDescription: {
    id: 'sharedFlows:massage.groupFloatDescription',
    defaultMessage:
      '{duration}-minutes of guided floatation. Up to five guests and one bodyworker.',
    description: 'Massage: Group float description',
  },
  massageSelectDisabledLabel: {
    id: 'sharedFlows:massage.massageSelectDisabledLabel',
    defaultMessage: 'Select a massage to see availability',
    description:
      "Massage: Placeholder for time selection when the user hasn't picked a type of massage, so there's no availability shown",
  },
  floatSelectDisabledLabel: {
    id: 'sharedFlows:massage.floatSelectDisabledLabel',
    defaultMessage: 'Select a float therapy to see availability',
    description:
      "Massage: Placeholder for time selection when the user hasn't picked a type of float, so there's no availability shown",
  },
  noAvailabilityDisabledLabel: {
    id: 'sharedFlows:massage.noAvailabilityDisabledLabel',
    defaultMessage: 'No availability',
    description:
      "Massage: Placeholder for time selection when the user has picked a type of float, but there's no availability for that massage",
  },
  clear: {
    id: 'sharedFlows:massage.clear',
    defaultMessage: 'Clear',
    description:
      'Massage: Label for button used to clear current selection of a massage',
  },
  limitedMassageDisclaimer: {
    id: 'sharedFlows:massage.limitedMassageDisclaimer',
    defaultMessage:
      "Number of massages can't exceed number of guests in your booking.",
    description: "Massage: Massages can't exceed number of guests Disclaimer",
  },
  nameOfGuest: {
    id: 'sharedFlows:massage.nameOfGuest',
    defaultMessage: 'Name of guest',
    description: 'Massage: Label for name of guest input',
  },
})

const warnings = defineMessages({
  noMoreMassages: {
    id: 'sharedFlows:massage.noMoreMassages',
    defaultMessage:
      'Your booking only has {guests} {guests, plural, one {guest} other {guests}}, therefore you can’t add more than {massages} {massages, plural, one {massage} other {massages}} to your booking. Contact support to book extra massages.',
    description:
      'Massage: Disclaimer when there is already a massage in cart for all guests',
  },
  noAvailabilityDisclaimer: {
    id: 'sharedFlows:massage.noAvailabilityDisclaimer',
    defaultMessage: 'No massages are available on your selected date',
    description: 'Massage: Disclaimer for when there are no floats available',
  },
})

const errors = defineMessages({
  bookingFailedError: {
    id: 'sharedFlows:massage.bookingFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to create a booking, please contact our support center for help',
    description: 'Massage: Error message for failed booking',
  },
  editBookingFailedError: {
    id: 'sharedFlows:massage.editBookingFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to edit your booking, please contact our support center for help',
    description: 'Massage: Error message for failed edit booking',
  },
  noAvailabilityError: {
    id: 'sharedFlows:massage.noAvailabilityError',
    defaultMessage: 'There is no availability for your date',
    description: 'Massage: Error message for no availability',
  },
  noTimeSelectedDisclaimer: {
    id: 'sharedFlows:massage.noTimeSelectedDisclaimer',
    defaultMessage: 'Please remember to select a time for your massage',
    description:
      "Massage: Disclaimer when user hasn't filled out time and type for massage",
  },
  limitedAvailability: {
    id: 'sharedFlows:massage.limitedAvailability',
    defaultMessage: 'Limited availability',
    description: 'Massage: Error title for limited availability',
  },
  limitedAvailabilityDisclaimer: {
    id: 'sharedFlows:massage.limitedAvailabilityDisclaimer',
    defaultMessage:
      'There is only {availability, plural, one {# time slot} other {# time slots}} available at the selected time. Please select a different time slot.',
    description: 'Massage: Error message for limited availability',
  },
})

export const massageMessages = {
  info,
  warnings,
  errors,
}

import { defineMessages } from 'react-intl'

const info = defineMessages({
  aboutBreadcrumb: {
    id: 'sharedFlows:skincare.aboutBreadcrumb',
    defaultMessage: 'About',
    description: 'Skincare: Skincare about breadcrumb label',
  },
  aboutTitle: {
    id: 'sharedFlows:skincare.aboutTitle',
    defaultMessage: 'Silica Mud Mask',
    description: 'Skincare: Title on skincare about screen',
  },
  aboutSubtitle: {
    id: 'sharedFlows:skincare.aboutSubtitle',
    defaultMessage:
      'Rich in the bioactive Blue Lagoon Silica, this iconic reset facial improves complexion and minimizes the visibility of pores, leaving skin fresh and clear.',
    description: 'Skincare: Subtitle on skincare about screen',
  },
  aboutContinueButton: {
    id: 'sharedFlows:skincare.aboutContinueButton',
    defaultMessage: 'Pre-order and Save {discount}%',
    description: 'Skincare: Subtitle on skincare about screen',
  },
  chooseProductBreadcrumb: {
    id: 'sharedFlows:skincare.chooseProductBreadcrumb',
    defaultMessage: 'Choose product',
    description: 'Skincare: Skincare choose product breadcrumb label',
  },
  chooseProductTitle: {
    id: 'sharedFlows:skincare.chooseProductTitle',
    defaultMessage: 'Purchase Silica Mud Mask',
    description: 'Skincare: Title on skincare choose product screen',
  },
  chooseProductSubtitle: {
    id: 'sharedFlows:skincare.chooseProductSubtitle',
    defaultMessage: 'By pre-purchasing we offer a {discount}% discount.',
    description: 'Skincare: Subtitle on skincare choose product screen',
  },
  silicaMudMaskDescription: {
    id: 'sharedFlows:skincare.silicaMudMaskDescription',
    defaultMessage:
      'Rich in the bioactive Blue Lagoon Silica, this iconic reset facial improves complexion and minimizes the visibility of pores, leaving skin fresh and clear.',
    description: 'Skincare: Silica mud mask description',
  },
  addToCartButton: {
    id: 'sharedFlows:skincare.addToCartButton',
    defaultMessage: 'Add to cart',
    description: 'Skincare: Add to cart button',
  },
  confirmationTitle: {
    id: 'sharedFlows:skincare.confirmationTitle',
    defaultMessage: 'When will you receive your Silica Mud Mask?',
    description: 'Skincare: Title on confirmation screen',
  },
  confirmationSubtitle: {
    id: 'sharedFlows:skincare.confirmationSubtitle',
    defaultMessage:
      'Your pre-purchased Blue Lagoon Skincare product will be available for pickup at our onsite store. Please have your confirmation email ready and present it to our staff.',
    description: 'Skincare: Subtitle on confirmation screen',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  addProductFailedError: {
    id: 'sharedFlows:skincare.addProductFailedError',
    defaultMessage:
      'Unfortunately an error occurred when trying to add product to cart, please contact our support center for help',
    description: 'Skincare: Error message for failed add to cart',
  },
})

export const skincareMessages = {
  info,
  warnings,
  errors,
}

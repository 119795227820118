import { addMinutes } from 'date-fns/addMinutes'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'

import { SubFlowActivity, TimeSlot } from 'bl-flows-core/src/types'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

const getTransportationDepartureSelectionStartTime = (
  timeSlots: TimeSlot[],
  entryDate: Date,
  lastActivity?: SubFlowActivity
) => {
  if (!timeSlots || timeSlots.length === 0) {
    return ''
  }

  const entryDateStr = formatDateInUTC(entryDate, 'yyyy-MM-dd')

  // find latest time
  let latestTime
  if (lastActivity?.date) {
    latestTime = addMinutes(new Date(lastActivity.date), lastActivity?.duration)
  } else {
    latestTime = entryDate
  }

  const slotsAfterLatestTime = timeSlots.filter(slot =>
    isAfter(parseISO(`${entryDateStr} ${slot.time}`), latestTime)
  )

  return slotsAfterLatestTime.length > 0 ? slotsAfterLatestTime[0].time : ''
}

export const getAvailableDepartureSlots = (
  timeSlots: TimeSlot[],
  entryDate: Date,
  lastActivity?: SubFlowActivity
) => {
  const startTime = getTransportationDepartureSelectionStartTime(
    timeSlots,
    entryDate,
    lastActivity
  )

  const startIndex = timeSlots.findIndex(slot => slot.time === startTime)

  return timeSlots.slice(startIndex)
}

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="14px"
    transform="translate(2, 0)"
    viewBox="0 0 17 24"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4.26119 22.3503l10.82841-8.7981c.9851-.8004.9851-2.304 0-3.1044L4.26119 1.64971C2.95416.58775 1 1.51787 1 3.20194V20.7981c0 1.684 1.95416 2.6142 3.26119 1.5522z"
    />
  </svg>
)

import { ChangeEvent } from 'react'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Input } from 'bl-common/src/form/Input/Input'
import { StyledInput } from 'bl-common/src/form/Input/Input'
import { ErrorMessage } from 'bl-common/src/units/Form/styles'

import { FlowInputComponent, FlowInputTextField, FlowValue } from '../../types'
import { getFlowValue } from '../../utils'
import { normalise, serialise } from './InputTextField-utils'

type InputTextProps = FlowInputComponent &
  FlowInputTextField['props'] & {
    error?: FlowValue
  }

const TopWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
})

const Label = styled(Type)({
  fontWeight: 'bold',
  marginBottom: '8px',
})

const InputWrapper = styled.div<{ hasError?: boolean }>(({ hasError }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [StyledInput]: {
    borderWidth: hasError && '2px',
  },
}))

export const InputTextField = ({ screenTheme, ...props }: InputTextProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.inputTextField
  const errorColor = theme?.bookingEngine?.[screenTheme]?.errorMessage?.color

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    const newValue = props.format ? normalise(rawValue, props.format) : rawValue

    if (props.control.screen.stateRef.current[props.id] === newValue) {
      return
    }

    props.control.screen.setState({
      [props.id]: newValue,
    })
  }

  const initialValue = getFlowValue(props.initialValue, props.control) ?? ''

  const value = get(props.control.screen.state, props.id, initialValue)
  const serialisedValue = props.format ? serialise(value, props.format) : value

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    props.onInputBlur(props.id, props.control)

    props?.onBlur?.(event.target.value, props.control)
  }

  return (
    <InputWrapper hasError={!!props?.error}>
      <TopWrapper>
        <Label size={{ xs: 14, md: 14 }} as="label" htmlFor={props.id}>
          {props.emptyLabel ? (
            <>&nbsp;</>
          ) : (
            getFlowValue(props.label, props.control)
          )}
          {props.required ? '*' : ''}
        </Label>
        {props?.error && (
          <ErrorMessage color={errorColor}>{props?.error}</ErrorMessage>
        )}
      </TopWrapper>
      <Input
        id={props.id}
        name={props.id}
        placeholder={getFlowValue(props.placeholder, props.control)}
        label={getFlowValue(props.label, props.control)}
        value={serialisedValue}
        onChange={onChange}
        onBlur={onBlur}
        style={{
          width: '100%',
        }}
        type={props.type || 'text'}
        disableEditing={props.disabled ?? false}
        maxLength={props.maxLength}
        themeStyle={themeStyle?.input}
        hasError={!!props?.error}
      />
    </InputWrapper>
  )
}

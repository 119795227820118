import { addMinutes } from 'date-fns/addMinutes'
import { isAfter } from 'date-fns/isAfter'
import { isBefore } from 'date-fns/isBefore'
import { parseISO } from 'date-fns/parseISO'
import { subMinutes } from 'date-fns/subMinutes'

import { SubFlowActivity, TimeSlot } from 'bl-flows-core'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

type GetRecommendedRestaurantSlotInput = {
  timeSlots: TimeSlot[]
  entryDate: Date
  lastActivity?: SubFlowActivity
  spaDurationInMinutes?: number
}

export const getRecommendedRestaurantSlots = ({
  timeSlots,
  entryDate,
  lastActivity,
  spaDurationInMinutes = 180,
}: GetRecommendedRestaurantSlotInput): TimeSlot[] => {
  if (!timeSlots || timeSlots.length === 0) {
    return []
  }

  //Todo: figure out recommended for hotel booking days without activities
  if (!entryDate) {
    return []
  }

  const entryDateStr = formatDateInUTC(entryDate, 'yyyy-MM-dd')

  // split into before and after arrivaltime
  const [before, after]: TimeSlot[][] = timeSlots.reduce(
    ([before, after], current) => {
      const slotTime = parseISO(`${entryDateStr} ${current.time}Z`)
      return isBefore(slotTime, entryDate)
        ? [[...before, current], after]
        : [before, [...after, current]]
    },
    [[], []]
  )

  // Recommend first slot before arrival or none
  const recommendedBeforeSlots = before.filter(
    slot =>
      !isAfter(
        parseISO(`${entryDateStr} ${slot.time}Z`),
        subMinutes(entryDate, 120)
      )
  )

  const recommendedBefore = recommendedBeforeSlots.slice(-1)

  const massageFinishTime =
    lastActivity?.date &&
    addMinutes(new Date(lastActivity.date), lastActivity.duration)

  // duration in Blue Lagoon = 3 hours
  const spaFinishTime = addMinutes(entryDate, spaDurationInMinutes)

  const latestTime = isAfter(massageFinishTime, spaFinishTime)
    ? massageFinishTime
    : spaFinishTime

  // find all slots that are after latest time
  const recommendedAfterSlots = after.filter(
    slot => !isBefore(parseISO(`${entryDateStr} ${slot.time}Z`), latestTime)
  )

  // recommend first slot that is after latest time
  const recommendedAfter =
    recommendedAfterSlots.length > 0
      ? recommendedAfterSlots[0]
      : timeSlots[timeSlots.length - 1]

  // In some edge cases the recommendedAfter could be the same as an item in recommendedBefore and we get two
  // recommendations for the same time.
  // Could be related to the logic always checking before and after, but never equal. That happened in the edge case.
  if (recommendedBefore.find(slot => slot.time === recommendedAfter?.time)) {
    return [...recommendedBefore]
  }

  return [...recommendedBefore, recommendedAfter]
}

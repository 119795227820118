import { useState } from 'react'
import styled from 'styled-components'

import { MapboxMap } from 'bl-common/src/booking/Map/MapboxMap'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { SmallArrow } from 'bl-common/src/elements/SmallArrow'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'
import { TransportationLocation, useDirectionsQuery } from 'bl-graphql'
import { REYKJAVIK_TERMINAL_ID } from 'bl-utils/src/productUtils'

import { busStops } from './busStops'
import InfoBox from './InfoBox'

interface TransportationMapProps {
  locationAddress: string
  location: TransportationLocation
  confirmLabel: string
  onMarkerSelected?: (TransportationLocation) => void
  onConfirm: () => void
  onClose: () => void
  isPickup: boolean
  disclaimer?: string
  themeStyle?: PartialBookingEngine['transportationFlow']['map']
}

const MapContainer = styled('div')({
  inset: 0,
  position: 'absolute',

  zIndex: zIndex.above2,
})

const BackButton = styled('button')({
  position: 'absolute',
  top: 10,
  left: 10,
  color: 'white',
  transform: 'rotate(180deg)',
  padding: 5,
  svg: {
    height: 15,
    width: 20,
  },

  [mediaObj.md]: {
    display: 'none',
  },
})

export const TransportationMap = ({
  locationAddress,
  location,
  confirmLabel,
  onMarkerSelected,
  onConfirm,
  onClose,
  isPickup = true,
  disclaimer,
  themeStyle,
}: TransportationMapProps) => {
  const [directions, setDirections] = useState([])
  const getDirectionVariables = () => {
    if (!location || !(location && location.busStop)) {
      return null
    }

    const bus = location.busStop

    return {
      startLatitude: location.latitude,
      startLongitude: location.longitude,
      endLatitude: bus && bus.latitude,
      endLongitude: bus && bus.longitude,
    }
  }

  useDirectionsQuery({
    variables: getDirectionVariables(),
    onCompleted: data => {
      if (data) {
        setDirections(data.directions.coordinates)
      }
    },
    skip: !location || !(location && location.latitude && location.busStop),
  })

  const markers = location ? [] : Object.values(busStops)

  const onCancel = () => {
    setDirections([])
    onClose()
  }

  const getDisclaimer = () => {
    if (!isPickup || !disclaimer) {
      return ''
    }

    // Add disclaimer to all locations except Skógarhlíð
    return location.id !== REYKJAVIK_TERMINAL_ID ? disclaimer : ''
  }

  return (
    <MapContainer>
      <MapboxMap
        viewPort={
          location && location.latitude && location.longitude
            ? {
                latitude: location.latitude,
                longitude: location.longitude,
                zoom: 15,
              }
            : undefined
        }
        markers={markers}
        selectedMarker={location}
        onMarkerSelected={onMarkerSelected}
        showPopup={!location}
        polylinePoints={directions}
      />
      <BackButton type="button" onClick={onClose}>
        <SmallArrow />
      </BackButton>
      {location?.name && (
        <InfoBox
          locationName={location.name}
          locationAddress={locationAddress}
          disclaimer={getDisclaimer()}
          onClose={onCancel}
          onConfirm={onConfirm}
          confirmLabel={confirmLabel}
          themeStyle={themeStyle?.infoBox}
        />
      )}
    </MapContainer>
  )
}

export default Map

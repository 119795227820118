import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import styled, { css, useTheme } from 'styled-components'

import { PartialBookingEngine } from 'bl-common/src/styles/types'

import { FlowComponent, FlowScreen } from '../types'
import { WithScreenTheme } from '../types/typeUtils'
import { LayoutRenderer } from './LayoutRenderer'
import { renderFields } from './renderUtils'

interface ScreenRendererProps extends FlowComponent {
  item: FlowScreen
}

export const Screen = styled(props => {
  return props.type === 'form' ? <form {...props} /> : <div {...props} />
})<
  {
    type: 'form' | 'section'
    themestyle?: PartialBookingEngine['flowScreen']
  } & WithScreenTheme
>`
  height: 100%;

  ${({ themestyle }) =>
    themestyle &&
    css`
      background: ${themestyle?.backgroundColor ?? 'none'};
    `};
`

export const ScreenRenderer: React.FC<ScreenRendererProps> = ({
  control,
  item,
}) => {
  const router = useRouter()
  const prevPathRef = useRef(router.asPath)
  control.screen.setupHook = item?.setupHook?.(control)

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[item?.theme]?.flowScreen

  if (
    typeof item.condition === 'function' &&
    item.condition(control) === false
  ) {
    return null
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (item.beforeSubmit) {
      const shouldContinue = await item.beforeSubmit(control)

      if (!shouldContinue) {
        return
      }
    }

    if (item.overrideDefaultSubmit) {
      return
    }

    control.nextScreen()
  }

  useEffect(() => {
    if (
      control.currentScreenIndex > 0 &&
      prevPathRef.current !== router.asPath
    ) {
      window.scrollTo(0, 0)
    }
    prevPathRef.current = router.asPath
  }, [control.currentScreenIndex, router.asPath])

  const layout =
    typeof item.layout === 'function' ? item.layout(control) : item.layout

  return (
    <Screen
      type={item.subType}
      onSubmit={onSubmit}
      themestyle={themeStyle}
      data-flow-screen-container
    >
      {item.layout ? (
        <LayoutRenderer
          item={layout}
          control={control}
          screenTheme={item.theme}
          columnStyle={item.columnStyle}
        />
      ) : (
        renderFields(
          typeof item.fields === 'function'
            ? item.fields(control).main
            : item.fields.main,
          control,
          item.theme
        )
      )}
    </Screen>
  )
}

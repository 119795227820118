import { motion } from 'framer-motion'
import styled from 'styled-components'

import { media, mediaObj } from 'ui-primitives/src/utils/media'
import { spacing } from 'ui-primitives/src/utils/spacing'

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${spacing[1.5]};
  padding-left: ${spacing[1]};
  position: relative;

  ::before {
    content: '';
    border: 1px dashed #e0e0e0;
    bottom: -7px;
    left: -1.4px;
    position: absolute;
    top: 10px;
  }

  ::after {
    content: '';
    // Fallbacking to black if no color is provided
    background: var(--color-accent, #000);
    border-radius: 50%;
    position: absolute;
    height: 8px;
    width: 8px;
    top: 7px;
    left: -4px;
    ${media.md`
      top: 5px;
    `}

    ${media.mlg`
      top: 7px;
    `}
  }
`

export const TimelineItem = styled.div`
  display: flex;
  width: 100%;

  :only-child {
    ${Content} {
      ::before {
        display: none;
      }
    }
  }

  :last-child {
    ${Content} {
      ::before {
        display: none;
      }
    }
  }
`

export const Time = styled.div<{ hasMinWidth?: boolean }>(
  ({ hasMinWidth }) => ({
    paddingRight: spacing[1],
    minWidth: hasMinWidth ? 95 : 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    [mediaObj.md]: {
      minWidth: hasMinWidth ? spacing[6] : 'auto',
    },
    p: {
      fontVariantNumeric: 'tabular-nums',
    },
  })
)

export const Hidden = styled(motion.div)`
  overflow: hidden;
  height: auto;
  transition: max-height 500ms ease;
  transform-origin: 0 0;

  ${TimelineItem} {
    ${Content} {
      ::before {
        display: block;
      }
    }
  }
`

export const ExtraItemsButton = styled(motion.button)`
  cursor: pointer;
  color: var(--color-accent);
  text-align: left;
`
export const Close = styled(motion.div)`
  cursor: pointer;
  padding: ${spacing[1]};
  position: absolute;
  right: ${spacing[-1]};
  top: ${spacing[-1]};

  > svg {
    // Using text color here for the icon for contrast
    fill: var(--color-text);
  }
`

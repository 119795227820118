import { FlowControl, FlowValue } from '../types'

export const getFlowValue = <T>(
  flowValue: FlowValue<T>,
  control: FlowControl
): T => {
  if (typeof flowValue === 'function') {
    // @ts-expect-error: Typescript claims 'This expression is not callable.' which I politely disagree with
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return flowValue(control)
  }

  return flowValue
}

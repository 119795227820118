import { defineMessages } from 'react-intl'

const info = defineMessages({
  // All titles and subtitles named with a prefix that matches the query param & flowType
  upgradeDiningReservationsTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeDiningReservationsTitle',
    defaultMessage: 'Dining Reservations',
    description: 'Upgrade Group: Title for Dining Reservations group screen',
  },
  upgradeDiningReservationsSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeDiningReservationsSubtitle',
    defaultMessage:
      'From light delicacies to classic Icelandic cuisine to Michelin-starred gourmet creations, Blue Lagoon offers an enticing spectrum of culinary possibilities.',
    description: 'Upgrade Group: Subtitle for Dining Reservations group screen',
  },
  upgradeInWaterTreatmentsTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeInWaterTreatmentsTitle',
    defaultMessage: 'In-water treatments',
    description: 'Upgrade Group: Title for In water treatments group screen',
  },
  upgradeInWaterTreatmentsSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeInWaterTreatmentsSubtitle',
    defaultMessage:
      'The nourishing warmth of the Blue Lagoon becomes the foundation for our extraordinary water-based experiences.',
    description: 'Upgrade Group: Subtitle for In water treatments group screen',
  },
  upgradeTransferTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeTransferTitle',
    defaultMessage: 'Transfer',
    description: 'Upgrade Group: Title for transfer group screen',
  },
  upgradeTransferSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeTransferSubtitle',
    defaultMessage:
      'Whether you rent a car, book a bus, or reserve a private transfer, you’ll always be surrounded by Iceland’s majestic beauty.',
    description: 'Upgrade Group: Subtitle for transfer group screen',
  },
  upgradeToursAndActivitiesTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeToursAndActivitiesTitle',
    defaultMessage: 'Tours & Activities',
    description: 'Upgrade Group: Title for tours & activities group screen',
  },
  upgradeToursAndActivitiesSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeToursAndActivitiesSubtitle',
    defaultMessage:
      'Set in the heart of a spectacular landscape, Blue Lagoon brings you inspiring adventures for the mind, body, and spirit.',
    description: 'Upgrade Group: Subtitle for tours & activities group screen',
  },
  upgradeIndoorTreatmentsTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeIndoorTreatmentsTitle',
    defaultMessage: 'Indoor Treatments',
    description: 'Upgrade Group: Title for Indoor treatments group screen',
  },
  upgradeIndoorTreatmentsSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeIndoorTreatmentsSubtitle',
    defaultMessage:
      'Our treatments, conducted in an exquisite subterranean suite, bring your mind and body to a place of radiance, relaxation, and rejuvenation.',
    description: 'Upgrade Group: Subtitle for Indoor treatments group screen',
  },
  upgradeWineCellarTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeWineCellarTitle',
    defaultMessage: 'Wine Cellar',
    description: 'Upgrade Group: Title for Wine Cellar group screen',
  },
  upgradeWineCellarSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeWineCellarSubtitle',
    defaultMessage:
      'Descend into the Retreat’s exquisite subterranean wine cellar for an unforgettable tasting experience highlighting renowned vintages from across the globe.',
    description: 'Upgrade Group: Subtitle for Wine Cellar group screen',
  },
  upgradeHighlandBaseToursAndActivitiesTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeHighlandToursAndActivitiesTitle',
    defaultMessage: 'Tours & Activities',
    description:
      'Upgrade Group: Title for highland tours & activities group screen',
  },
  upgradeHighlandBaseToursAndActivitiesSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeHighlandBaseToursAndActivitiesSubtitle',
    defaultMessage:
      'At the extremes of Iceland—year-round adventures for year-round thrillseekers.',
    description:
      'Upgrade Group: Subtitle for highland tours & activities group screen',
  },
  upgradeHighlandBaseDiningTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeHighlandBaseDiningTitle',
    defaultMessage: 'Dining',
    description: 'Upgrade Group: Title for highland base dining group screen',
  },
  upgradeHighlandBaseDiningSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeHighlandBaseDiningSubtitle',
    defaultMessage:
      'Hearty cooking to keep you fueled up for your next adventure.',
    description:
      'Upgrade Group: Subtitle for highland base dining group screen',
  },
  upgradeHighlandBaseTransferTitle: {
    id: 'sharedFlows:upgradeGroup.upgradeHighlandBaseTransferTitle',
    defaultMessage: 'Transfer',
    description: 'Upgrade Group: Title for highland base transfer group screen',
  },
  upgradeHighlandBaseTransferSubtitle: {
    id: 'sharedFlows:upgradeGroup.upgradeHighlandBaseTransferSubtitle',
    defaultMessage: 'Year-round transportation to and from Highland Base.',
    description:
      'Upgrade Group: Subtitle for highland base transfer group screen',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({})

export const upgradeGroupMessages = {
  info,
  warnings,
  errors,
}

import { useRouter } from 'next/router'
import { useTheme } from 'styled-components'

import {
  buildCardButtonField,
  FieldRenderer,
  FlowComponent,
} from 'bl-flows-core'

import { UpgradeCard } from '../../../types/UpgradeCard'

interface UpgradeCardsProps extends FlowComponent {
  cards: UpgradeCard[]
}

export const UpgradeCards = ({
  control,
  cards,
  screenTheme,
}: UpgradeCardsProps) => {
  const { push, query } = useRouter()
  const theme = useTheme()

  return cards?.length > 0 ? (
    <>
      {cards.map((card, j) => {
        // Filter out cards that don't have a title, f.x if an upgrade item is in draft mode in contentful
        if (!card.title) {
          return null
        }
        const hasLinkOutSideMyBooking = !!card.linkOutsideMyBooking

        return (
          <div key={j}>
            <FieldRenderer
              control={control}
              screenTheme={screenTheme}
              item={buildCardButtonField({
                props: {
                  title: card.title,
                  description: card.description,
                  ctaLabel: card.ctaLabel,
                  image: card.image,
                  isClosed: false,
                  themeStyle:
                    theme?.bookingEngine?.[screenTheme]?.cardButtonField,
                  linkProps: hasLinkOutSideMyBooking
                    ? {
                        href: card.linkOutsideMyBooking,
                        isExternalLink: true,
                      }
                    : null,
                  onClick: () => {
                    control.flow.setupHook.onClickCard(card)
                    if (hasLinkOutSideMyBooking) {
                      return null
                    }
                    void push(
                      { query: { ...query, modal: card.flowType } },
                      undefined,
                      {
                        shallow: true,
                      }
                    )
                  },
                },
                layout: {
                  spacing: {
                    mb: { xs: 1 },
                  },
                },
              })}
            />
          </div>
        )
      })}
    </>
  ) : null
}

export const DoneIcon = ({ color }) => (
  <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 6L6 12L15 1"
      stroke={color ? color : 'url(#paint0_linear)'}
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="1"
        y1="1"
        x2="15.272"
        y2="1.36504"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#52A5B8" />
        <stop offset="1" stopColor="#6793AE" />
      </linearGradient>
    </defs>
  </svg>
)

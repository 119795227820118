import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { media } from 'bl-common/src/utils/media'

export const BackButton = styled.button<{ color?: string }>`
  background: none;
  border: 0;
  color: ${props => props?.color ?? colors.deepBlue};
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing[1.5]}`};
  margin: ${({ theme }) => `${theme.spacing[-1.5]}`};
  text-align: left;
  z-index: ${zIndex.above2};
  font-weight: bold;
  font-size: 1.05rem;

  ${media.mlg(css`
    margin: unset;
    left: max(-6vw, -80px);
    padding: ${({ theme }) =>
      `${theme.spacing[1]}  ${theme.spacing[1.5]} ${theme.spacing[1.5]}`};
    position: absolute;
    top: -22px;
  `)}
`

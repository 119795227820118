import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from '../../constants/colors'
import { theme } from '../../styles/theme'
import { ClientOnlyModal as Modal } from '../../units/ClientOnlyModal'
import { ModalSize } from '../../units/Modal'
import { mediaObj } from '../../utils/media'
import { Button } from '../Button/Button'
import { ButtonPreset } from '../Button/Button'
import { ProgressButton } from '../ProgressButton/ProgressButton'
import { Type } from '../Typography/Typography'

export interface ConfirmActionModalProps {
  show: boolean
  onHide: () => void
  title: string
  subtitle?: string
  message: string
  buttonLabel: string
  secondaryButtonLabel?: string
  onConfirm?: (() => Promise<boolean>) | (() => void)
  onCancel?: () => void
  onComplete?: () => void
  isPopUp?: boolean
  fullScreenOnMobile?: boolean
  lineColor?: string
  textColor?: string
  backgroundColor?: string
  buttonPreset?: ButtonPreset
  secondaryButtonPreset?: ButtonPreset
  buttonBorderRadius?: number
}

const BasicModalContainer = styled.div<{
  $isPopUp?: boolean
  $backgroundColor?: string
  $textColor?: string
}>(({ $isPopUp, $backgroundColor, $textColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 300,
  padding: `${theme.spacing[4]} ${theme.spacing[3]}`,
  paddingTop: $isPopUp && theme.spacing[7],
  background: $backgroundColor,
  color: $textColor,

  [mediaObj.sm]: {
    minWidth: '45vw',
    width: 'auto',
  },

  [mediaObj.md]: {
    padding: `${theme.spacing[6]} ${theme.spacing[3]}`,
    width: 'auto',
  },

  [mediaObj.mlg]: {
    marginTop: 0,
  },

  [mediaObj.lg]: {
    minWidth: '35vw',
  },
}))

const Divider = styled.div<{ lineColor?: string }>`
  background: ${props => props.lineColor ?? colors.fountainBlue};
  height: 4px;
  width: ${({ theme }) => theme.spacing[5]};
`
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ModalButton = styled(Button)<{ buttonBorderRadius?: number }>(
  ({ buttonBorderRadius }) => ({
    width: '100%',

    borderRadius: buttonBorderRadius ?? 0,
  })
)
const ModalProgressButton = styled(ProgressButton)<{
  buttonBorderRadius?: number
}>(({ buttonBorderRadius }) => ({
  width: '100%',

  borderRadius: buttonBorderRadius ?? 0,
}))

const ConfirmActionModal = ({
  show,
  onHide,
  title,
  subtitle,
  message,
  buttonLabel,
  secondaryButtonLabel,
  onConfirm,
  onCancel,
  // if onComplete is included the button will be a progress button
  onComplete,
  isPopUp,
  fullScreenOnMobile,
  lineColor,
  textColor,
  backgroundColor,
  buttonPreset,
  secondaryButtonPreset,
  buttonBorderRadius,
}: ConfirmActionModalProps) => {
  const { t } = useTranslation('booking')
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={ModalSize.small}
      isPopUp={isPopUp}
      fullScreenOnMobile={fullScreenOnMobile}
    >
      <BasicModalContainer
        data-test="basic-modal-container"
        role="alertdialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-message"
        $isPopUp={isPopUp}
        $backgroundColor={backgroundColor}
        $textColor={textColor}
      >
        <Type
          as="h1"
          preset="headlineMediumLarge"
          weight="bold"
          data-test="basic-modal-title"
          id="dialog-title"
          bottom={{ xs: 1, md: 1 }}
        >
          {title}
        </Type>
        {subtitle && (
          <Type preset="labelLarge" top={{ xs: 0.5 }} bottom={{ xs: 1 }}>
            {subtitle}
          </Type>
        )}
        <Divider lineColor={lineColor} />
        <Type
          as="h2"
          preset="subtitle"
          top={{ xs: 1, md: 2 }}
          bottom={{ xs: 4, md: 6 }}
          data-test="basic-modal-text"
          id="dialog-message"
        >
          {message}
        </Type>
        <Footer>
          {onComplete ? (
            <ModalProgressButton
              onClick={onConfirm}
              onComplete={onComplete}
              preset={buttonPreset ?? 'blue'}
              paddingSize="small"
              data-test="basic-modal-button"
              buttonBorderRadius={buttonBorderRadius}
            >
              {buttonLabel || t('booking.general.button.okay')}
            </ModalProgressButton>
          ) : (
            <ModalButton
              onClick={() => {
                onConfirm?.()
                onHide()
              }}
              preset={buttonPreset ?? 'blue'}
              paddingSize="small"
              data-test="basic-modal-button"
              buttonBorderRadius={buttonBorderRadius}
            >
              {buttonLabel || t('booking.general.button.okay')}
            </ModalButton>
          )}

          {!!secondaryButtonLabel && (
            <ModalButton
              onClick={() => {
                onCancel?.()
                onHide()
              }}
              preset={secondaryButtonPreset ?? 'transparentWithBlueBorder'}
              paddingSize="small"
              data-test="basic-modal-button"
              top={{ xs: 1, md: 2 }}
              buttonBorderRadius={buttonBorderRadius}
            >
              {secondaryButtonLabel}
            </ModalButton>
          )}
        </Footer>
      </BasicModalContainer>
    </Modal>
  )
}

export default ConfirmActionModal

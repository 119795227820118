import {
  FlowDoubleLayout,
  FlowFullScreenLayout,
  FlowImageLayout,
  FlowSidebarLayout,
  FlowSmallImageLayout,
} from '../types'

export const buildImageLayout = (
  props: Omit<FlowImageLayout, 'type' | 'subType'>
): FlowImageLayout => ({
  ...props,
  type: 'layout',
  subType: 'image',
})

export const buildSmallImageLayout = (
  props: Omit<FlowSmallImageLayout, 'type' | 'subType'>
): FlowSmallImageLayout => ({
  ...props,
  type: 'layout',
  subType: 'media',
})

export const buildSidebarLayout = (
  props: Omit<FlowSidebarLayout, 'type' | 'subType'>
): FlowSidebarLayout => ({
  ...props,
  type: 'layout',
  subType: 'sidebar',
})

export const buildFullScreenLayout = (
  props: Omit<FlowFullScreenLayout, 'type' | 'subType'>
): FlowFullScreenLayout => ({
  ...props,
  type: 'layout',
  subType: 'fullscreen',
})

export const buildDoubleLayout = (
  props: Omit<FlowDoubleLayout, 'type' | 'subType'>
): FlowDoubleLayout => ({
  ...props,
  type: 'layout',
  subType: 'double',
})

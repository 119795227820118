export enum DiscountType {
  promoCode = 'PROMO_CODE',
  giftCard = 'GIFT_CARD',
}

export enum DiscountCodeState {
  initial = 'INITIAL',
  applied = 'APPLIED',
  invalid = 'INVALID',
}

export interface DiscountCode {
  code: string
  balance?: number
  remaining?: number
  chargeAmount?: number
  type?: DiscountType
  state: DiscountCodeState
  key: number
}

import { useTheme } from 'styled-components'

import { AddProductCard } from 'bl-common/src/cards/AddProductCard/AddProductCard'

import { FlowAddProductCardField, FlowComponent } from '../../types'
import { getFlowValue } from '../../utils'

type AddProductCardFieldProps = FlowComponent &
  FlowAddProductCardField['props'] & {
    id: string
  }

export const AddProductCardField: React.FC<AddProductCardFieldProps> = ({
  control,
  image,
  title,
  description,
  price,
  discountedPrice,
  hasImage,
  id,
  screenTheme,
}) => {
  const titleValue = getFlowValue(title, control)
  const descriptionValue = getFlowValue(description, control)
  const priceValue = getFlowValue(price, control)
  const discountedPriceValue = getFlowValue(discountedPrice, control)

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.addProductCardField

  return (
    <AddProductCard
      image={image}
      title={titleValue}
      description={descriptionValue}
      price={priceValue}
      discountedPrice={discountedPriceValue}
      quantity={control.screen.state?.[id]}
      updateQuantity={newValue => {
        control.screen.setState({
          [id]: newValue,
        })
      }}
      hasImage={hasImage}
      themeStyle={themeStyle}
    />
  )
}

export const breakpoints = {
  xs: 0,
  sm: 376,
  smd: 500,
  md: 768,
  bmd: 1000, //booking engine breakpoint
  mlg: 1084,
  lg: 1400,
  xl: 1900,
} as const

export type Breakpoint = keyof typeof breakpoints
export type BreakpointMap<T> = Partial<Record<Breakpoint, T>>

import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { theme } from 'bl-common/src/styles/theme'
import { media, mediaObj } from 'bl-common/src/utils/media'

import { WithScreenTheme } from '../../types/typeUtils'

export const easing = [0.24, 0.28, 0.63, 1]

export const layoutAnimation = {
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  initial: { y: 15, opacity: 0 },
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

export const ContentContainer = styled(motion.div)<WithScreenTheme>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.spacing[0.5]} ${theme.spacing[2]}  ${theme.spacing[3]}`};
  min-height: 100%;

  ${media.bmd(css`
    width: 50%;
    overflow-y: auto;
    height: 100%;
  `)}

  ${media.mlg(css`
    padding: ${({ theme }) =>
      `${theme.spacing[3]} ${theme.spacing[5]} ${theme.spacing[3]}`};
  `)}

  ${({ screentheme, screenthemes }) =>
    screentheme &&
    screenthemes[screentheme] &&
    css`
      background: ${screenthemes[screentheme].background || 'inherit'};
      color: ${screenthemes[screentheme].color || 'inherit'};
    `};
`

export const Sidebar = styled.div<{ $backgroundColor?: string }>(
  ({ $backgroundColor }) => ({
    display: 'none',

    [mediaObj.bmd]: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '40%',
      height: '100%',
      overflowY: 'auto',

      background:
        $backgroundColor ||
        'linear-gradient(90deg, #6793ae 0%, #50a7ba 97.99%)',
    },

    [mediaObj.mlg]: {
      paddingTop: theme.spacing[3],
    },
  })
)

export const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 75%;

  > div {
    width: 100%;
  }
`

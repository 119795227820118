import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { globalBookingMessages } from 'bl-flows/src/messages/global'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { FlowControl, TimeSlot } from '../../types'

type SuggestionCardProps = {
  slot: TimeSlot
  date: Date
  onSelectTime: (slot, date) => void
  control: FlowControl
  themeStyle?: PartialBookingEngine['availableDateTimesField']['suggestionCard']
}

const Card = styled.button<{
  themeStyle?: PartialBookingEngine['availableDateTimesField']['suggestionCard']
}>(({ themeStyle }) => ({
  boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing[1.5],
  marginBottom: theme.spacing[1],
  width: '100%',
  background: themeStyle?.background ?? colors.white,
  cursor: 'pointer',

  display: 'flex',
  justifyContent: 'space-between',
}))

export const SuggestionCard = ({
  slot,
  date,
  onSelectTime,
  control,
  themeStyle,
}: SuggestionCardProps) => {
  const dateText = formatDateInUTC(
    new Date(date),
    'dd MMMM',
    control.context.locale
  )

  return (
    <Card onClick={() => onSelectTime(slot, date)} themeStyle={themeStyle}>
      <Type preset="textSmall" color={themeStyle?.textColor ?? colors.grey}>
        {control.context.t(globalBookingMessages.text.dateAtTime, {
          date: dateText,
          time: slot?.time,
        })}
      </Type>
      <Type
        preset="textSmall"
        color={themeStyle?.selectColor ?? colors.deepBlue}
        weight="bold"
      >
        {control.context.t(globalBookingMessages.text.timeSlotsSelect)}
      </Type>
    </Card>
  )
}

export default SuggestionCard

import styled from 'styled-components'

import { colors } from '../constants/colors'
import { Type } from '../elements/Typography/Typography'

const ErrorMessage = styled(({ ...props }) => (
  <Type size={{ xs: 14, md: 14 }} weight="medium" {...props} />
))`
  color: ${colors.errorRed};
  text-align: right;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing[0.5]};
`

export const SubtleErrorMessage = ({ children }) => (
  <ErrorMessage role="alert">{children}</ErrorMessage>
)

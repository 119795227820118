import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useTranslation as useNextTransation } from 'next-i18next'
import { useApolloClient } from '@apollo/client'
import { useSessionStorageValue } from '@react-hookz/web'

import {
  AddHotelRoomToCartInput,
  AddProductToCartInput,
  Cart,
  CartType,
  CheckoutInput,
  CheckoutMutation,
  CheckoutPaymentInput,
  CheckoutPaymentMutation,
  CreatePaymentTransactionMutation,
  CustomerInformationInput,
  GET_CART,
  GetCheckoutStatusQuery,
  TransactionInput,
  UpdateHotelRoomCartItemInput,
  UpdateProductCartItemInput,
  useAddGiftCardToCartMutation,
  useAddHotelRoomToCartMutation,
  useAddMembershipTokenToCartMutation,
  useAddProductToCartMutation,
  useAddPromoCodeToCartMutation,
  useAddSpaCustomerInfoMutation,
  useCheckoutMutation,
  useCheckoutPaymentMutation,
  useCreateCartMutation,
  useCreatePaymentTransactionMutation,
  useGetCartLazyQuery,
  useGetCheckoutStatusLazyQuery,
  useRemoveGiftCardFromCartMutation,
  useRemoveItemFromCartMutation,
  useRemoveMembershipTokenFromCartMutation,
  useResetCartMutation,
  useUpdateHotelRoomCartItemMutation,
  useUpdateProductCartItemMutation,
} from 'bl-graphql'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { useBookingSessionContext } from './BookingSessionContext'

interface ICartState {
  cartId: string | undefined
  cart: Cart
  loading: boolean
  error: boolean
  cartType: CartType
  checkoutStatusData: GetCheckoutStatusQuery
  hasCheckoutPollingError: boolean
  cartLastUpdated: number | null
  createCart: (input?: CreateCartInput) => Promise<Cart>
  addSpaCustomerInfo: (
    input: Omit<CustomerInformationInput, 'cartId'>
  ) => Promise<any>
  resetSpaCustomerInfo: () => Promise<any>
  watchCart: (cartType?: CartType) => {
    closed: boolean
    unsubscribe(): void
  } | null
  addHotelRoomCartItem: (item: any, noRefetch?: boolean) => Promise<any>
  updateHotelRoomCartItem: (item: any, noRefetch?: boolean) => Promise<any>
  addProductCartItem: (
    item: any,
    noRefetch?: boolean,
    cartType?: CartType
  ) => Promise<any>
  updateProductCartItem: (item: any) => Promise<any>
  removeItemFromCart: (itemId: string, noRefetch?: boolean) => Promise<any>
  checkout: (args: CheckoutInput) => Promise<CheckoutMutation> | null
  resetCart: (noRefetch?: boolean) => Promise<any>
  createPaymentTransaction: (
    input: TransactionInput,
    type?: CartType
  ) => Promise<CreatePaymentTransactionMutation> | null
  getCheckoutStatus: () => void
  checkoutPayment: (
    input: CheckoutPaymentInput,
    type?: CartType
  ) => Promise<CheckoutPaymentMutation> | null
  addPromoCode: (promoCode: string, noRefetch?: boolean) => Promise<any>
  addGiftCard: (giftCard: string, noRefetch?: boolean) => Promise<any>
  removeGiftCard: (giftCard: string, noRefetch?: boolean) => Promise<any>
  addMembershipToken: (
    membershipToken: string,
    noRefetch?: boolean
  ) => Promise<any>
  removeMembershipToken: (noRefetch?: boolean) => Promise<any>
  setCart: (cart: Cart) => void
  getCart: () => Promise<Cart>
}

export const CartContext = createContext<ICartState>({
  cartId: undefined,
  cart: null,
  loading: false,
  error: false,
  cartType: CartType.Hotel,
  checkoutStatusData: null,
  hasCheckoutPollingError: false,
  cartLastUpdated: null,
  createCart: async () => Promise.resolve(null),
  addSpaCustomerInfo: async () => Promise.resolve(null),
  resetSpaCustomerInfo: async () => Promise.resolve(null),
  watchCart: () => null,
  addHotelRoomCartItem: async () => {},
  updateHotelRoomCartItem: async () => {},
  addProductCartItem: async () => {},
  updateProductCartItem: async () => {},
  removeItemFromCart: async () => {},
  checkout: async () => Promise.resolve(null),
  resetCart: async () => Promise.resolve(null),
  createPaymentTransaction: async () => Promise.resolve(null),
  getCheckoutStatus: () => {},
  checkoutPayment: async () => Promise.resolve(null),
  addPromoCode: async () => {},
  addGiftCard: async () => {},
  removeGiftCard: async () => {},
  addMembershipToken: async () => {},
  removeMembershipToken: async () => Promise.resolve(null),
  setCart: () => {},
  getCart: async () => Promise.resolve(null),
})

type CreateCartInput = {
  force?: boolean
  cartType?: CartType
  noRefetch?: boolean
}

interface CartProviderProps {
  children: React.ReactNode
  cartIdKey:
    | 'cart-id-hotel-booking'
    | 'cart-id-package-booking'
    | 'cart-id-spa-booking'
    | 'cart-id-highland-booking'
  cartType?: CartType
}

export const CartProvider = ({
  children,
  cartIdKey,
  cartType = CartType.Hotel,
}: CartProviderProps) => {
  const { i18n } = useTranslation()
  // highlandbase uses different translations
  const { i18n: i18Next } = useNextTransation()
  const locale = i18n.language ?? i18Next.language ?? 'en'
  const { sessionId } = useBookingSessionContext()
  const [cartLastUpdated, setCartLastUpdated] = useState<number | null>(null)
  const [cartId, setCartId] = useSessionStorageValue<string | undefined>(
    `${cartIdKey}-${sessionId}`
  )

  const refCartId = useRef(cartId)

  const [cart, setCart] = useState<Cart | null>(null)
  const [hasCheckoutPollingError, setHasCheckoutPollingError] = useState(false)
  const [cartLoading, setCartLoading] = useState<boolean>(true)
  const [cartLoadingError, setCartLoadingError] = useState<boolean>(false)
  const [createCartMutation] = useCreateCartMutation()
  const [addSpaCustomerInfoMutation] = useAddSpaCustomerInfoMutation()
  const [addHotelItemToCartCartMutation] = useAddHotelRoomToCartMutation()
  const [addProductToCartMutation] = useAddProductToCartMutation()
  const [createPaymentTransactionMutation] =
    useCreatePaymentTransactionMutation()
  const [checkoutPaymentMutation] = useCheckoutPaymentMutation()
  const [updateHotelRoomCartItemMutation] = useUpdateHotelRoomCartItemMutation()
  const [updateProductCartItemMutation] = useUpdateProductCartItemMutation()
  const [removeItemFromCartMutation] = useRemoveItemFromCartMutation()
  const [resetCartMutation] = useResetCartMutation()
  const [checkoutMutation] = useCheckoutMutation()
  const [addPromoCodeMutation] = useAddPromoCodeToCartMutation()
  const [addGiftCardMutation] = useAddGiftCardToCartMutation()
  const [removeGiftCardMutation] = useRemoveGiftCardFromCartMutation()
  const [addMembershipTokenMutation] = useAddMembershipTokenToCartMutation()
  const [removeMembershipTokenMutation] =
    useRemoveMembershipTokenFromCartMutation()
  const [fetchCart] = useGetCartLazyQuery()
  const apolloClient = useApolloClient()

  // This useEffect handles the case when the cart
  // is not loading properly, e.g. because it doesn't exist anymore.
  // In those cases we try to create a new cart.
  useEffect(() => {
    if (cartLoadingError && cartId && !cartLoading) {
      createCart({ force: true })
    }
  }, [cartLoadingError, cartId, cartLoading])

  useEffect(() => {
    refCartId.current = cartId
  }, [cartId])

  const refetchQueries = useCallback(
    refetchCartId => {
      return [
        {
          query: GET_CART,
          variables: {
            input: {
              type: cartType,
              cartId: refetchCartId,
              locale,
            },
          },
        },
        'GetCart',
      ]
    },
    [cartId, locale, cartType]
  )

  const getCart = useCallback(async () => {
    setCartLastUpdated(Date.now())
    try {
      const { data } = await fetchCart({
        variables: {
          input: {
            type: cartType,
            cartId,
            locale,
          },
        },
      })

      const cart = data?.getCart as Cart

      setCart(cart)

      return cart
    } catch (error) {
      return Promise.reject(error)
    }
  }, [cartId, apolloClient, locale, cartType])

  const createCart = useCallback(
    async (input?: CreateCartInput) => {
      if (cartId && !input?.force) {
        return Promise.resolve(cart)
      }

      try {
        const { data } = await createCartMutation({
          variables: {
            input: {
              type: cartType,
            },
          },
        })

        const cart = data?.createCart as Cart

        ReactDOM.unstable_batchedUpdates(() => {
          refCartId.current = cart?.id
          setCartLoadingError(false)
          setCartId(cart?.id)
          setCart(cart)
          setCartLastUpdated(Date.now())
        })

        return cart
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [cartId]
  )

  const watchCart = useCallback(() => {
    const querySubscription = apolloClient
      .watchQuery({
        query: GET_CART,
        variables: {
          input: {
            cartId,
            type: cartType,
            locale,
          },
        },
        returnPartialData: false,
        notifyOnNetworkStatusChange: true,
      })
      .subscribe({
        start: () => {
          setCartLoadingError(false)
        },
        next: ({ data, loading }) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setCartLoadingError(false)
            setCartLoading(loading)
            if (!loading && data?.getCart) {
              setCart(data.getCart)
              setCartLastUpdated(Date.now())
            }
          })
        },
        error: e => {
          ReactDOM.unstable_batchedUpdates(() => {
            setCartLoadingError(true)
            setCartLoading(false)
          })
          console.error(e)
        },
      })

    return querySubscription
  }, [cartId, apolloClient, locale])

  const addHotelRoomCartItem = useCallback(
    (input: Omit<AddHotelRoomToCartInput, 'cartId' | 'locale'>, noRefetch) => {
      return addHotelItemToCartCartMutation({
        variables: {
          input: {
            cartId,
            locale,
            ...input,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, locale, refetchQueries]
  )
  const updateHotelRoomCartItem = useCallback(
    (
      input: Omit<UpdateHotelRoomCartItemInput, 'cartId' | 'locale'>,
      noRefetch
    ) => {
      return updateHotelRoomCartItemMutation({
        variables: {
          input: {
            cartId,
            ...input,
            locale,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, locale, refetchQueries]
  )

  const addProductCartItem = useCallback(
    (
      input: Omit<AddProductToCartInput, 'cartId' | 'locale' | 'type'>,
      noRefetch: boolean,
      type?: CartType
    ) => {
      return addProductToCartMutation({
        variables: {
          input: {
            cartId,
            locale,
            type: type || cartType,
            ...input,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, locale, refetchQueries]
  )

  const addSpaCustomerInfo = useCallback(
    async (input: Omit<CustomerInformationInput, 'cartId'>) => {
      return await addSpaCustomerInfoMutation({
        variables: {
          input: {
            cartId,
            ...input,
          },
        },
        refetchQueries: refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const resetSpaCustomerInfo = useCallback(async () => {
    return await addSpaCustomerInfoMutation({
      variables: {
        input: {
          cartId,
          customer: {
            firstName: '',
            lastName: '',
            email: '',
          },
        },
      },
      refetchQueries: refetchQueries(refCartId.current),
    })
  }, [cartId, refetchQueries])

  const updateProductCartItem = useCallback(
    (input: Omit<UpdateProductCartItemInput, 'cartId' | 'locale'>) => {
      return updateProductCartItemMutation({
        variables: {
          input: {
            cartId,
            ...input,
            locale,
          },
        },
        refetchQueries: refetchQueries(refCartId.current),
      })
    },
    [cartId, locale, refetchQueries]
  )

  const removeItemFromCart = useCallback(
    async (itemId, noRefetch) => {
      return removeItemFromCartMutation({
        variables: {
          input: {
            type: cartType,
            cartId,
            itemId,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const addPromoCode = useCallback(
    async (promoCode, noRefetch) => {
      return await addPromoCodeMutation({
        variables: {
          input: {
            type: cartType,
            promoCode,
            cartId,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const addGiftCard = useCallback(
    async (giftCard, noRefetch) => {
      return await addGiftCardMutation({
        variables: {
          input: {
            type: cartType,
            giftCard,
            cartId,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const removeGiftCard = useCallback(
    async (giftCard, noRefetch) => {
      return await removeGiftCardMutation({
        variables: {
          input: {
            type: cartType,
            giftCard,
            cartId,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const addMembershipToken = useCallback(
    async (membershipToken, noRefetch) => {
      return await addMembershipTokenMutation({
        variables: {
          input: {
            type: cartType,
            membershipToken,
            cartId,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const removeMembershipToken = useCallback(
    async noRefetch => {
      return await removeMembershipTokenMutation({
        variables: {
          input: {
            type: cartType,
            cartId,
          },
        },
        refetchQueries: !noRefetch && refetchQueries(refCartId.current),
      })
    },
    [cartId, refetchQueries]
  )

  const checkout = useCallback(
    async payload => {
      const result = await checkoutMutation({
        variables: {
          input: {
            type: cartType,
            cartId,
            ...payload,
          },
        },
      })

      return result.data || null
    },
    [cartId]
  )

  const resetCart = useCallback(
    async (noRefetch?: boolean) => {
      setCartLoadingError(false)
      try {
        await resetCartMutation({
          variables: {
            input: {
              type: cartType,
              cartId,
            },
          },
          refetchQueries: !noRefetch && refetchQueries(refCartId.current),
        })
      } catch (error) {
        sentryLogging({
          error: new Error('Failed to reset cart.'),
          extras: { error },
        })
        createCart({ force: true })
      }
    },
    [cartId, locale, refetchQueries]
  )

  const createPaymentTransaction = useCallback(
    async (input: TransactionInput, type?: CartType) => {
      const result = await createPaymentTransactionMutation({
        variables: {
          input: {
            cartId,
            type: type || cartType,
            ...input,
          },
        },
        refetchQueries: refetchQueries(refCartId.current),
      })

      return result.data || null
    },
    [cartId, refetchQueries]
  )

  const [getCheckoutStatus, { data: checkoutStatusData, stopPolling }] =
    useGetCheckoutStatusLazyQuery({
      variables: {
        input: {
          type: cartType,
          cartId,
        },
      },
      // On production we are rate limited to 1 request per second
      pollInterval: process.env.NEXT_PUBLIC_CHECKOUT_POLL_INTERVAL
        ? Number(process.env.NEXT_PUBLIC_CHECKOUT_POLL_INTERVAL)
        : 1050,
      // Stop polling when we get an error
      onError: () => {
        setHasCheckoutPollingError(true)
        stopPolling()
      },
    })

  // Stop polling when we get an error in the checkoutStatusData
  useEffect(() => {
    if (checkoutStatusData?.getCheckoutStatus?.error) {
      setHasCheckoutPollingError(true)
      stopPolling()
    }

    if (checkoutStatusData?.getCheckoutStatus?.finished) {
      stopPolling()
    }
  }, [checkoutStatusData?.getCheckoutStatus, stopPolling])

  const checkoutPayment = useCallback(
    async (input: CheckoutPaymentInput) => {
      getCheckoutStatus()
      const result = await checkoutPaymentMutation({
        variables: {
          input: {
            type: cartType,
            cartId,
            ...input,
          },
        },
      })

      return result.data || null
    },
    [cartId]
  )

  const value = useMemo(
    () => ({
      cart,
      cartId,
      loading: cartLoading,
      error: cartLoadingError,
      cartType,
      createCart,
      watchCart,
      addHotelRoomCartItem,
      updateHotelRoomCartItem,
      addProductCartItem,
      updateProductCartItem,
      removeItemFromCart,
      checkout,
      resetCart,
      createPaymentTransaction,
      getCheckoutStatus,
      checkoutPayment,
      addPromoCode,
      addGiftCard,
      removeGiftCard,
      addMembershipToken,
      checkoutStatusData,
      hasCheckoutPollingError,
      removeMembershipToken,
      setCart,
      getCart,
      cartLastUpdated,
      addSpaCustomerInfo,
      resetSpaCustomerInfo,
    }),
    [
      cartId,
      cart,
      cartLoading,
      cartLoadingError,
      cartType,
      createCart,
      watchCart,
      addHotelRoomCartItem,
      updateHotelRoomCartItem,
      addProductCartItem,
      updateProductCartItem,
      removeItemFromCart,
      checkout,
      resetCart,
      createPaymentTransaction,
      getCheckoutStatus,
      checkoutPayment,
      addPromoCode,
      addGiftCard,
      removeGiftCard,
      addMembershipToken,
      checkoutStatusData,
      hasCheckoutPollingError,
      removeMembershipToken,
      setCart,
      getCart,
      cartLastUpdated,
      addSpaCustomerInfo,
      resetSpaCustomerInfo,
    ]
  )

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

import omit from 'lodash/omit'

import {
  FlowControl,
  FlowDoubleLayout,
  FlowFormScreen,
  FlowFormScreenWithDoubleLayout,
  FlowFormScreenWithFullScreenLayout,
  FlowFormScreenWithImageLayout,
  FlowFormScreenWithSidebarLayout,
  FlowFormScreenWithSmallImageLayout,
  FlowFullScreenLayout,
  FlowImageLayout,
  FlowSidebarLayout,
  FlowSmallImageLayout,
  FlowValue,
} from '../types'
import {
  buildDoubleLayout,
  buildFullScreenLayout,
  buildImageLayout,
  buildSidebarLayout,
  buildSmallImageLayout,
} from './layouts'

export const buildScreen = (
  props: Omit<FlowFormScreen, 'type'>
): FlowFormScreen => ({
  theme: 'default',
  ...props,
  type: 'screen',
})

type BuildScreenLayoutProps<T extends { type: string }, U> = Omit<T, 'type'> & {
  layoutProps: FlowValue<U>
}

export const buildScreenWithImageLayout = <U extends FlowImageLayout['props']>(
  props: BuildScreenLayoutProps<FlowFormScreenWithImageLayout, U>
): FlowFormScreenWithImageLayout => {
  const { layoutProps, ...restProps } = props

  const buildLayout = (control?: FlowControl) => {
    const layoutPropsFunc =
      typeof layoutProps === 'function' ? layoutProps(control) : layoutProps

    return buildImageLayout({
      props: layoutPropsFunc,
      fields: props.fields,
    })
  }

  return {
    theme: 'default',
    ...restProps,
    layout: buildLayout,
    type: 'screen-image-layout',
  }
}

export const buildScreenWithSmallImageLayout = <
  U extends FlowSmallImageLayout['props'],
>(
  props: BuildScreenLayoutProps<FlowFormScreenWithSmallImageLayout, U>
): FlowFormScreenWithSmallImageLayout => {
  const { layoutProps, ...restProps } = props

  const buildLayout = (control?: FlowControl) => {
    const layoutPropsFunc =
      typeof layoutProps === 'function' ? layoutProps(control) : layoutProps

    return buildSmallImageLayout({
      props: layoutPropsFunc,
      fields: props.fields,
    })
  }

  return {
    theme: 'default',
    ...restProps,
    layout: buildLayout,
    type: 'screen-small-image-layout',
  }
}

export const buildScreenWithSidebarLayout = (
  props: BuildScreenLayoutProps<
    FlowFormScreenWithSidebarLayout,
    FlowSidebarLayout['props']
  >
): FlowFormScreenWithSidebarLayout => ({
  theme: 'default',
  ...omit(props, ['layoutProps']),
  layout:
    typeof props.layoutProps === 'function'
      ? (control?: FlowControl) =>
          buildSidebarLayout({
            props:
              typeof props.layoutProps === 'function'
                ? props.layoutProps(control)
                : props.layoutProps,
            fields: props.fields,
          })
      : buildSidebarLayout({
          props: props.layoutProps,
          fields: props.fields,
        }),
  type: 'screen-sidebar-layout',
})

export const buildScreenWithFullScreenLayout = (
  props: BuildScreenLayoutProps<
    FlowFormScreenWithFullScreenLayout,
    FlowFullScreenLayout['props']
  >
): FlowFormScreenWithFullScreenLayout => ({
  theme: 'default',
  ...omit(props, ['layoutProps']),
  layout:
    typeof props.layoutProps === 'function'
      ? (control?: FlowControl) =>
          buildFullScreenLayout({
            props:
              typeof props.layoutProps === 'function'
                ? props.layoutProps(control)
                : props.layoutProps,
            fields: props.fields,
          })
      : buildFullScreenLayout({
          props: props.layoutProps,
          fields: props.fields,
        }),
  type: 'screen-fullscreen-layout',
})

export const buildScreenWithDoubleLayout = (
  props: BuildScreenLayoutProps<
    FlowFormScreenWithDoubleLayout,
    FlowDoubleLayout['props']
  >
): FlowFormScreenWithDoubleLayout => ({
  theme: 'default',
  ...omit(props, ['layoutProps']),
  layout:
    typeof props.layoutProps === 'function'
      ? (control?: FlowControl) =>
          buildDoubleLayout({
            props:
              typeof props.layoutProps === 'function'
                ? props.layoutProps(control)
                : props.layoutProps,
            fields: props.fields,
          })
      : buildDoubleLayout({
          props: props.layoutProps,
          fields: props.fields,
        }),
  type: 'screen-double-component-layout',
})

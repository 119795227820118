import { useContext } from 'react'
import { sub } from 'date-fns'

import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { FlowControl, SubFlowActivity } from 'bl-flows-core'
import { ActivityProductAvailabilityQuery, CartItemType } from 'bl-graphql'
import { calculateDiscountedPrice } from 'bl-utils/src/currency/calcDiscountPrice'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'
import {
  displayDate,
  formatDateInUTC,
} from 'bl-utils/src/formatting/formatDate'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'
import { REYKJAVIK_TERMINAL_ID } from 'bl-utils/src/productUtils'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { globalBookingMessages, productIdsMessages } from '../messages'
import { MassageState } from '../subflows'
import { floatMessages } from '../subflows/float/messages/floatMessages'
import { lavaMessages } from '../subflows/lava/messages/lavaMessages'
import { massageMessages } from '../subflows/massage/messages/massageMessages'
import { privateTransferMessages } from '../subflows/privateTransfer/messages/privateTransferMessages'
import { spaRestaurantMessages } from '../subflows/spaRestaurant/messages/spaRestaurantMessages'
import { transportationMessages } from '../subflows/transportation/messages/transportationMessages'
import { LocationType } from '../types/Transportation'
import { filterMassages } from './filterMassages'

/** We have a dynamic overview screen for upgrade and edit flows that gets items as strings
 * Here are the functions to generate the strings, separated from the flows to keep them from being too big
 */

export const getItemsForEditSpaVisitOverview = (control: FlowControl) => {
  const previousBookingDate = control.flow.setupHook?.previousBookingTime
  const newBookingDate = control.flow?.stateRef?.current?.date?.arrivalDate
  const newBookingTime = control.flow?.stateRef?.current?.time?.selectedTime
  const packageTitle = control.flow?.setupHook?.packageTitle
  const currentAdmission = control.flow.setupHook?.currentAdmission
  const guests =
    Number(currentAdmission.numberOfPersons) +
    Number(currentAdmission.numberOfYoungAdults) +
    Number(currentAdmission.numberOfChildren)

  const previousTransportation = control.flow?.setupHook?.previousTransportation
  const newTransportation =
    control.flow.stateRef?.current?.transportation?.newTransportation

  const firstText = `${packageTitle}, ${control.context.t(
    globalBookingMessages.text.dateAtTime,
    {
      date: formatDateInUTC(previousBookingDate, 'd MMM yy'),
      time: getTimeFromDateTimeString(previousBookingDate),
    }
  )} ${control.context.t(globalBookingMessages.text.forGuests, {
    count: guests,
  })}`

  const secondText = `${packageTitle}, ${control.context.t(
    globalBookingMessages.text.dateAtTime,
    {
      date: formatDateInUTC(newBookingDate, 'd MMM yy'),
      time: getTimeFromDateTimeString(newBookingTime),
    }
  )} ${control.context.t(globalBookingMessages.text.forGuests, {
    count: guests,
  })}`

  let pickupBefore: string,
    dropoffBefore: string,
    pickupAfter: string,
    dropoffAfter: string

  if (previousTransportation) {
    // Children don't need to pay/reserve a seat in the bus so we display them in the guest count
    const transportationGuests =
      Number(previousTransportation.guests) +
      Number(currentAdmission.numberOfChildren)

    pickupBefore = `${control.context.t(
      globalBookingMessages.labels.pickup
    )}: ${previousTransportation.pickupLocationName}, ${control.context.t(
      globalBookingMessages.text.dateAtTime,
      {
        date: formatDateInUTC(
          new Date(previousTransportation?.departureDate),
          'd MMM yy'
        ),
        time: previousTransportation?.pickupTime,
      }
    )} ${control.context.t(globalBookingMessages.text.forGuests, {
      count: transportationGuests,
    })}`

    dropoffBefore = `${control.context.t(
      globalBookingMessages.labels.dropoff
    )}: ${previousTransportation.dropoffLocationName}, ${control.context.t(
      globalBookingMessages.text.dateAtTime,
      {
        date: formatDateInUTC(
          new Date(previousTransportation?.departureDate),
          'd MMM yy'
        ),
        time: previousTransportation?.departureTime,
      }
    )} ${control.context.t(globalBookingMessages.text.forGuests, {
      count: transportationGuests,
    })}`

    pickupAfter = `${control.context.t(globalBookingMessages.labels.pickup)}: ${
      newTransportation.pickupLocationName
    }, ${control.context.t(globalBookingMessages.text.dateAtTime, {
      date: formatDateInUTC(
        new Date(newTransportation?.departureDate),
        'd MMM yy'
      ),
      time: newTransportation?.pickupTime,
    })} ${control.context.t(globalBookingMessages.text.forGuests, {
      count: transportationGuests,
    })}`

    dropoffAfter = `${control.context.t(
      globalBookingMessages.labels.dropoff
    )}: ${newTransportation.dropoffLocationName}, ${control.context.t(
      globalBookingMessages.text.dateAtTime,
      {
        date: formatDateInUTC(
          new Date(newTransportation?.departureDate),
          'd MMM yy'
        ),
        time: newTransportation?.departureTime,
      }
    )} ${control.context.t(globalBookingMessages.text.forGuests, {
      count: transportationGuests,
    })}`
  }

  return previousTransportation
    ? [
        {
          firstText: pickupBefore,
          secondText: pickupAfter,
        },
        {
          firstText,
          secondText,
        },
        {
          firstText: dropoffBefore,
          secondText: dropoffAfter,
        },
      ]
    : [
        {
          firstText,
          secondText,
        },
      ]
}

export const getItemsForTransportationOverview = (control: FlowControl) => {
  const isEditing = control.flow.setupHook?.isEditing
  const t = control.context.t

  const transportationBefore = control.flow.setupHook?.initialTransportation
  const transportationCurrent = control.flow.stateRef?.current

  const locale = control.context.locale

  let pickupBefore: string, dropoffBefore: string

  if (isEditing) {
    pickupBefore = `${t(transportationMessages.info.pickup)} ${
      control.flow.stateRef?.current?.initialPickupLocationName
        ? control.flow.stateRef?.current?.initialPickupLocationName
        : transportationBefore.pickupLocation?.location?.name
    }, ${formatDateInUTC(
      transportationBefore?.pickupTime?.date,
      'd MMM',
      locale
    )} ${transportationBefore?.pickupTime?.time}`
    dropoffBefore = `${t(transportationMessages.info.dropoff)} ${
      control.flow.stateRef?.current?.initialDropoffLocationName
        ? control.flow.stateRef?.current?.initialDropoffLocationName
        : transportationBefore.dropoffLocation?.location?.name
    }, ${formatDateInUTC(
      transportationBefore?.departureTime?.date,
      'd MMM',
      locale
    )} ${transportationBefore?.departureTime?.time}`
  }

  const pickupLocationName =
    transportationCurrent?.pickup?.type === LocationType.airport
      ? t(transportationMessages.info.locationAirport)
      : transportationCurrent.pickupLocation?.location?.busStop
        ? transportationCurrent.pickupLocation?.location?.busStop.name
        : transportationCurrent.pickupLocation?.location?.name

  const dropoffLocationName =
    transportationCurrent.dropoff?.type === LocationType.airport
      ? t(transportationMessages.info.locationAirport)
      : transportationCurrent.dropoffLocation?.location?.busStop
        ? transportationCurrent.dropoffLocation?.location?.busStop.name
        : transportationCurrent.dropoffLocation?.location?.name

  const pickup =
    Object.keys(transportationCurrent).length !== 0 &&
    `${t(
      transportationMessages.info.pickup
    )} ${pickupLocationName}, ${formatDateInUTC(
      new Date(transportationCurrent?.pickupTime?.date),
      'd MMM',
      locale
    )} ${transportationCurrent?.pickupTime?.time}`

  const dropoff =
    Object.keys(transportationCurrent).length !== 0 &&
    `${t(
      transportationMessages.info.dropoff
    )} ${dropoffLocationName}, ${formatDateInUTC(
      new Date(transportationCurrent?.departureTime?.date),
      'd MMM',
      locale
    )} ${transportationCurrent?.departureTime?.time}`

  return !isEditing
    ? [{ firstText: pickup }, { firstText: dropoff }]
    : [
        { firstText: pickupBefore, secondText: pickup },
        { firstText: dropoffBefore, secondText: dropoff },
      ]
}

export const getItemsForBluelagoonTransportationOverview = (
  control: FlowControl
) => {
  const t = control.context.t

  const transportationCurrent = control.flow.stateRef?.current

  const pickupDate = transportationCurrent?.pickupTime?.date
    ? new Date(transportationCurrent.pickupTime.date)
    : null
  const pickupTime = transportationCurrent?.pickupTime?.time
  const departureDate = transportationCurrent?.departureTime?.date
    ? new Date(transportationCurrent.departureTime.date)
    : null
  const departureTime = transportationCurrent?.departureTime?.time

  // Make sure we have the necessary data to display the overview
  // otherwise return an empty array.
  if (
    Object.keys(transportationCurrent).length === 0 ||
    pickupTime == null ||
    pickupDate == null ||
    departureDate == null ||
    departureTime == null
  ) {
    sentryLogging({
      error: new Error(
        'Required data for getItemsForBluelagoonTransportationOverview was missing'
      ),
      extras: {
        isTransportationCurrentEmpty:
          Object.keys(transportationCurrent).length === 0,
        isPickupTimeNull: pickupTime == null,
        isPickupDateNull: pickupDate == null,
        isDepartureDateNull: departureDate == null,
        isDepartureTimeNull: departureTime == null,
      },
    })
    return []
  }
  const locale = control.context.locale

  const isAirportPickup =
    transportationCurrent?.pickup?.type === LocationType.airport
  const isAirportDropoff =
    transportationCurrent?.dropoff?.type === LocationType.airport
  const isReykjavikTerminalPickup =
    transportationCurrent.pickupLocation?.location?.id === REYKJAVIK_TERMINAL_ID
  const isReykjavikTerminalDropoff =
    transportationCurrent.dropoffLocation?.location?.id ===
    REYKJAVIK_TERMINAL_ID
  const showShuttlePickupDisclaimer =
    !isReykjavikTerminalPickup && !isAirportPickup
  const isShuttleDropoff = !isReykjavikTerminalDropoff && !isAirportDropoff

  const fromLocationName = isAirportPickup
    ? t(transportationMessages.info.fromKeflavikAirport)
    : t(transportationMessages.info.fromReykjavik)

  const pickupLocationName = isAirportPickup
    ? t(transportationMessages.info.locationAirport)
    : transportationCurrent.pickupLocation?.location?.busStop
      ? transportationCurrent.pickupLocation?.location?.busStop.name
      : transportationCurrent.pickupLocation?.location?.name

  const dropoffLocationName = isAirportDropoff
    ? t(transportationMessages.info.locationAirport)
    : transportationCurrent.dropoffLocation?.location?.busStop
      ? transportationCurrent.dropoffLocation?.location?.busStop.name
      : transportationCurrent.dropoffLocation?.location?.name

  // Concat together date and time since pickupDate doesn't have the correct time in it.
  const pickupDateTime = new Date(
    formatDateInUTC(pickupDate, 'yyyy-MM-dd', locale) +
      'T' +
      pickupTime +
      ':00.000Z'
  )

  // Shuttle pickup time is 30 minutes earlier.
  const shuttlePickupTime = formatDateInUTC(
    sub(pickupDateTime, { minutes: 30 }),
    'HH:mm',
    locale
  )

  const pickupDateTimeFormatted = `
    ${formatDateInUTC(
      departureDate,
      'd MMMM',
      locale
    )} ${t(transportationMessages.info.atTime)} ${departureTime}`

  const pickup = `<strong>${fromLocationName}:</strong> ${formatDateInUTC(
    pickupDate,
    'd MMMM',
    locale
  )} ${t(transportationMessages.info.atTime)} ${pickupTime}<br />${
    showShuttlePickupDisclaimer
      ? `${t(
          transportationMessages.info.shuttlePickupAt
        )} ${pickupLocationName}. <u>${t(
          transportationMessages.info.beReadyAt
        )} ${shuttlePickupTime}</u>`
      : pickupLocationName
  }`

  const dropoff = `<strong>${t(
    transportationMessages.info.fromTheBlueLagoon
  )}:</strong> ${pickupDateTimeFormatted}${
    isShuttleDropoff
      ? `<br />${t(
          transportationMessages.info.shuttleDropoffAt
        )} ${dropoffLocationName}`
      : `<br />${dropoffLocationName}`
  }`

  return [{ firstText: pickup }, { firstText: dropoff }]
}

export const getItemsForMassageOverview = (control: FlowControl) => {
  const isEditing = control.flow.setupHook?.isEditing

  const previousMassageActivity =
    control.flow.setupHook?.previousMassageActivity
  const massages = filterMassages(control.flow.stateRef.current?.time.massages)
  const { currency, exchangeRates } = useContext(CurrencyContext)

  const getMassageInfo = (massage: MassageState) => {
    const title =
      control.context.t(productIdsMessages[massage.productNo]) ??
      (massage.title || massage.massageInfo.type)

    const date = displayDate(new Date(massage.date), {
      locale: control.context.locale,
    })
    const time = massage.time || getTimeFromDateTimeString(massage.date)
    return `${control.context.t(
      globalBookingMessages.labels.overviewSummaryTitleDateTime,
      { title, date, time }
    )} ${control.context.t(massageMessages.info.overviewSummaryQuantity, {
      count: massage.quantity || 1,
    })}`
  }

  return !isEditing
    ? massages.map(massage => {
        return {
          firstText: getMassageInfo(massage),
          firstPrice: formatPrice(
            calcPrice(
              massage?.massageInfo?.price * (massage.quantity || 1),
              // exchange rate for ISK is 1, so fallback to that for MYB
              exchangeRates?.[currency] ?? 1
            ),
            currency.length ? currency : 'ISK',
            false
          ),
        }
      })
    : massages.map(massage => {
        return {
          firstText: getMassageInfo(previousMassageActivity),
          secondText: getMassageInfo(massage),
        }
      })
}

export const getItemsForUpgradeAdmissionOverview = (
  control: FlowControl,
  adultsInBooking: number
) => {
  const isRetreatSpaUpgrade =
    control.flow.state.select?.selectedPackageType === PRODUCT_IDS.SpaRetreat
  if (!isRetreatSpaUpgrade) {
    const adults = []
    for (let index = 0; index < adultsInBooking; index++) {
      adults.push(index)
    }

    return adults.map(() => {
      return {
        firstText: control.context.t(
          globalBookingMessages.text.overviewUpgrade,
          {
            package: control.flow.state.select.selectedPackageName,
          }
        ),
        firstPrice: formatPrice(
          control.flow.stateRef.current?.select?.price,
          'ISK',
          false
        ),
      }
    })
  } else {
    // For Retreat spa we have the same slot price for 1 or two persons, so we only show one line
    // since the flow only support upgrading for bookings with max 2 adults
    const retreatSpaOverviewInfo = {
      firstText: control.context.t(globalBookingMessages.text.overviewUpgrade, {
        package: control.flow.state.select.selectedPackageName,
      }),
      firstPrice: formatPrice(
        control.flow.state.time.selectedPrice,
        'ISK',
        false
      ),
    }
    return [retreatSpaOverviewInfo]
  }
}

export const getItemsForPrivateTransferOverview = (control: FlowControl) => {
  const transferTitles = {
    [PRODUCT_IDS.PrivateTransferArrivalSmall]: control.context.t(
      privateTransferMessages.info.arrivalSmallOverview
    ),
    [PRODUCT_IDS.PrivateTransferArrivalLarge]: control.context.t(
      privateTransferMessages.info.arrivalLargeOverview
    ),
    [PRODUCT_IDS.PrivateTransferDepartureSmall]: control.context.t(
      privateTransferMessages.info.departureSmallOverview
    ),
    [PRODUCT_IDS.PrivateTransferDepartureLarge]: control.context.t(
      privateTransferMessages.info.departureLargeOverview
    ),
    // Servio
    [PRODUCT_IDS.PrivateTransferArrivalServioSmall]: control.context.t(
      privateTransferMessages.info.arrivalServioSmallOverview
    ),
    [PRODUCT_IDS.PrivateTransferArrivalServioMedium]: control.context.t(
      privateTransferMessages.info.arrivalServioMediumOverview
    ),
    [PRODUCT_IDS.PrivateTransferArrivalServioLarge]: control.context.t(
      privateTransferMessages.info.departureServioLargeOverview
    ),
    [PRODUCT_IDS.PrivateTransferDepartureServioSmall]: control.context.t(
      privateTransferMessages.info.departureServioSmallOverview
    ),
    [PRODUCT_IDS.PrivateTransferDepartureServioMedium]: control.context.t(
      privateTransferMessages.info.departureServioMediumOverview
    ),
    [PRODUCT_IDS.PrivateTransferDepartureServioLarge]: control.context.t(
      privateTransferMessages.info.departureServioLargeOverview
    ),
  }
  const getTransferInfo = (
    productId: string,
    info: {
      transferInfo: ActivityProductAvailabilityQuery['activityProductAvailability'][number]
    }
  ) => {
    const title = transferTitles[productId]
    const date = formatDateInUTC(info?.transferInfo?.time, 'dd MMM')
    const time = formatDateInUTC(info?.transferInfo?.time, 'HH:mm')
    const { currency, exchangeRates } = useContext(CurrencyContext)

    return {
      firstText: control.context.t(
        globalBookingMessages.labels.overviewSummaryTitleDateTime,
        { title, date, time }
      ),
      firstPrice: formatPrice(
        calcPrice(
          info?.transferInfo?.price,
          // exchange rate for ISK is 1, so fallback to that for MYB
          exchangeRates?.[currency] ?? 1
        ),
        currency.length ? currency : 'ISK',
        false
      ),
    }
  }

  const transfers = []
  if (control.flow.state?.type?.selectedArrival) {
    transfers.push(
      getTransferInfo(
        control.flow.state.type?.selectedArrival,
        control.flow.state?.arrival
      )
    )
  }
  if (control.flow.state?.type?.selectedDeparture) {
    transfers.push(
      getTransferInfo(
        control.flow.state.type?.selectedDeparture,
        control.flow.state?.departure
      )
    )
  }

  return transfers
}

export const getItemsForFloatOverview = (control: FlowControl) => {
  const isEditing = control.flow.setupHook?.isEditing
  const previousFloatActivity: SubFlowActivity =
    control.flow.setupHook?.previousFloatActivity
  const floats = filterMassages(control.flow.stateRef.current?.time.massages)
  const { currency, exchangeRates } = useContext(CurrencyContext)

  const getPreviousFloatInfo = (float: SubFlowActivity) => {
    const title =
      control.context.t(productIdsMessages[float.productNo]) || float.title
    const date = displayDate(new Date(float.date), {
      locale: control.context.locale,
    })
    const time = getTimeFromDateTimeString(float.date)
    return `${control.context.t(
      globalBookingMessages.labels.overviewSummaryTitleDateTime,
      { title, date, time }
    )} ${control.context.t(floatMessages.info.overviewSummaryQuantity, {
      count: float.numberOfPersons * float.quantity || float.quantity || 1,
    })}`
  }

  const getFloatInfo = (float: MassageState) => {
    const title =
      control.context.t(productIdsMessages[float.productNo]) ||
      float.massageInfo.title ||
      float.massageInfo.type
    const date = displayDate(new Date(float.date), {
      locale: control.context.locale,
    })
    const time = float.time || getTimeFromDateTimeString(float.date)
    return `${control.context.t(
      globalBookingMessages.labels.overviewSummaryTitleDateTime,
      { title, date, time }
    )} ${control.context.t(floatMessages.info.overviewSummaryQuantity, {
      count:
        float.massageInfo?.validForCount * float.quantity ||
        float.quantity ||
        1,
    })}`
  }

  return !isEditing
    ? floats.map(float => {
        return {
          firstText: getFloatInfo(float),
          firstPrice: formatPrice(
            calcPrice(
              float?.massageInfo?.price * (float.quantity || 1),
              // exchange rate for ISK is 1, so fallback to that for MYB
              exchangeRates?.[currency] ?? 1
            ),
            currency.length ? currency : 'ISK',
            false
          ),
        }
      })
    : floats.map(float => {
        return {
          firstText: getPreviousFloatInfo(previousFloatActivity),
          secondText: getFloatInfo(float),
        }
      })
}

export const getItemsForHotelExtrasOverview = (control: FlowControl) => {
  const extras = control.flow.setupHook.cart?.items?.filter(
    item => item.type === CartItemType.Item
  )

  return extras?.map(extra => {
    return {
      firstText: productIdsMessages?.[extra.productId]
        ? control.context.t(productIdsMessages?.[extra.productId])
        : extra.name,
      firstPrice: formatPrice(extra.price, 'ISK', false),
      ...(extra.fixedDiscountPercentage && {
        discountedFirstPrice: formatPrice(
          calculateDiscountedPrice({
            discountPercentage: extra.fixedDiscountPercentage,
            originalPrice: extra.price,
          }),
          'ISK',
          false
        ),
      }),
    }
  })
}

export const getItemsForLavaOverview = (control: FlowControl) => {
  const title = control.context.t(lavaMessages.info.lavaReservation)
  const currentGuests = control.context.t(globalBookingMessages.text.guests, {
    guests: control.flow.state.guests.guests,
  })
  const currentDate = displayDate(new Date(control.flow.state.time.date), {
    locale: control.context.locale,
  })

  const currentTime =
    control.flow.state.time.time ||
    getTimeFromDateTimeString(control.flow.state.time.date)
  const currentComment = control.flow.state.guests.comment

  const currentReservationInfo = {
    firstText: control.context.t(lavaMessages.info.titleGuestsDateTime, {
      title,
      guests: currentGuests,
      date: currentDate,
      time: currentTime,
    }),
  }
  const currentCommentInfo = {
    firstText: currentComment ? currentComment : '',
  }

  return [currentReservationInfo, currentCommentInfo]
}

export const getItemsForSpaRestaurantOverview = (control: FlowControl) => {
  const title = control.context.t(
    spaRestaurantMessages.info.spaRestaurantReservation
  )
  const currentGuests = control.context.t(globalBookingMessages.text.guests, {
    guests: control.flow.state.guests.guests,
  })
  const currentDate = displayDate(new Date(control.flow.state.time.date), {
    locale: control.context.locale,
  })

  const currentTime =
    control.flow.state.time.time ||
    getTimeFromDateTimeString(control.flow.state.time.date)
  const currentComment = control.flow.state.guests.comment

  const currentReservationInfo = {
    firstText: control.context.t(
      spaRestaurantMessages.info.titleGuestsDateTime,
      {
        title,
        guests: currentGuests,
        date: currentDate,
        time: currentTime,
      }
    ),
  }
  const currentCommentInfo = {
    firstText: currentComment ? currentComment : '',
  }

  return [currentReservationInfo, currentCommentInfo]
}

import { css } from 'styled-components'

import { breakpoints } from './breakpoints'

const breakpointKeys = Object.keys(breakpoints) as Array<
  keyof typeof breakpoints
>

type MediaBreakpoint = Record<
  keyof typeof breakpoints,
  (...args: Parameters<typeof css>) => ReturnType<typeof css>
>

type MediaQueryStrings = Record<keyof typeof breakpoints, string>

export const media = breakpointKeys.reduce<MediaBreakpoint>((acc, label) => {
  acc[label] = (...args: Parameters<typeof css>) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {} as MediaBreakpoint)

export const mediaMax = breakpointKeys.reduce<MediaBreakpoint>((acc, label) => {
  acc[label] = (...args: Parameters<typeof css>) => css`
    @media (max-width: ${breakpoints[label] - 1}px) {
      ${css(...args)};
    }
  `
  return acc
}, {} as MediaBreakpoint)

export const mediaObj = breakpointKeys.reduce<MediaQueryStrings>(
  (acc, label) => {
    acc[label] = `@media (min-width: ${breakpoints[label]}px)`
    return acc
  },
  {} as MediaQueryStrings
)

export const mediaMaxObj = breakpointKeys.reduce<MediaQueryStrings>(
  (acc, label) => {
    acc[label] = `@media (max-width: ${breakpoints[label] - 1}px)`
    return acc
  },
  {} as MediaQueryStrings
)

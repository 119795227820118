import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { zIndex } from 'bl-common/src/constants/zIndex'
import { media } from 'bl-common/src/utils/media'

import { WithScreenTheme } from '../../types/typeUtils'

const easing = [0.24, 0.28, 0.63, 1]

export const layoutAnimation = {
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  initial: { y: 15, opacity: 0 },
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[2]}  0`};
  ${media.bmd(css`
    justify-content: space-between;
    flex-direction: row;
  `)};
`

interface InnerContainerProps {
  desktopWidth?: number
}

interface LeftContainerProps extends InnerContainerProps, WithScreenTheme {
  hideImageOnMobile: boolean
}

export const LeftContainer = styled(motion.div)<LeftContainerProps>`
  margin-top: ${({ theme }) => theme.spacing[2]};
  display: ${({ hideImageOnMobile }) => (hideImageOnMobile ? 'none' : 'flex')};
  position: relative;
  min-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => css`
    ${media.bmd(css`
      margin: auto 0;
      max-width: 60%;
      min-width: 0;
    `)}
    ${props.screentheme &&
    props.screenthemes[props.screentheme] &&
    css`
      background: ${props.screenthemes[props.screentheme].background ||
      'inherit'};
      color: ${props.screenthemes[props.screentheme].color || 'inherit'};
    `};
  `};
`

export const RightContainer = styled.div<InnerContainerProps>`
  height: 100%;
  width: 100%;
  max-width: 60vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.bmd(css`
    height: auto;
    flex: 0 0 30%;
    padding: ${({ theme }) => theme.spacing[2]};
    position: relative;
    left: ${({ theme }) => theme.spacing[2]};
  `)}
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: ${zIndex.above};
  height: calc(90vw * calc(1080 / 1920));
  ${media.bmd(css`
    justify-content: initial;
    height: calc(calc(100vw - 160px) * calc(2 / 3) * 0.9 * calc(1080 / 1920));
  `)}
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: -10%;
    top: 10%;
    background: linear-gradient(90deg, #6793ae 0%, #50a7ba 97.99%);
    opacity: 0.1;
    z-index: ${zIndex.behind};
  }
`

import { useEffect } from 'react'
import { isSameDay } from 'date-fns/isSameDay'
import styled, { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { globalBookingMessages } from 'bl-flows/src/messages/global'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { buildSelectField } from '../../builders'
import { FieldRenderer } from '../../renderers'
import {
  FlowDateTimeSelectField,
  FlowInputComponent,
  TimeSlot,
} from '../../types'
import { AtLeastOne } from '../../types/typeUtils'

type SelectProps = AtLeastOne<{
  label: string
  value: string
  disabled?: boolean
}>

type DateTimeSelectFieldProps = FlowInputComponent &
  FlowDateTimeSelectField['props'] & {
    id: string
  }

const Card = styled.div<{
  isCard?: boolean
  themeStyle?: PartialBookingEngine['dateTimeSelectField']
}>(({ isCard, themeStyle, theme }) => ({
  boxShadow:
    isCard && (themeStyle?.boxShadow ?? '0px 8px 24px rgba(0, 0, 0, 0.1)'),
  padding: isCard && theme.spacing[1.5],
  paddingBottom: isCard && theme.spacing[2],
  background: themeStyle?.cardBackground ?? colors.white,
  borderRadius: theme.borderRadius?.elements ?? 0,
}))

const Options = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: theme.spacing[1],
  marginTop: theme.spacing[1],
})

const Option = styled.div({
  flex: '1 1 50%',
  flexDirection: 'column',
  display: 'flex',
  rowGap: theme.spacing[0.5],
})

const getLabel = (type: string) => {
  switch (type) {
    case 'lava':
      return globalBookingMessages.labels.lavaReservation
    case 'transportationPickup':
      return globalBookingMessages.labels.pickupTime
    case 'transportationDeparture':
      return globalBookingMessages.labels.departureTime
    default:
      return globalBookingMessages.labels.lavaReservation
  }
}

export const DateTimeSelectField = ({
  screenTheme,
  ...props
}: DateTimeSelectFieldProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.dateTimeSelectField

  const bookableQuantity =
    props?.bookableQuantity ||
    props.control.flow.stateRef?.current?.quantity?.quantity ||
    props?.guests ||
    3

  const generateTimeOptionsLabel = (time: TimeSlot) => {
    let label =
      // If the time is less than 5, we show the limited availability message
      time.available < 5
        ? props.control?.context?.t(
            globalBookingMessages.labels.selectLimitedAvailability,
            {
              time: time?.time,
              available: time?.available,
              // We disable the option if the available quantity is less than the bookable quantity
              disabled: time.available < bookableQuantity,
            }
          )
        : time.time
    const currentReservationDate = props.currentReservationDate
    const currentReservationTime = getTimeFromDateTimeString(
      props.currentReservationDate
    )
    const currentScreenDate =
      props.control.screen.state.date || props.currentSelectedDate

    // We know that we are looking at the same date as the currentReservationDate if
    // currentScreenDate is falsy(means that we have a screen with only a single date),
    // or if the currentScreenDate is on the same date as the currentReservationDate
    const isSameDateAsCurrentReservation =
      !currentScreenDate ||
      isSameDay(new Date(currentScreenDate), new Date(currentReservationDate))

    const isSameTimeAsCurrentReservation = currentReservationTime === time.time

    if (
      isSameTimeAsCurrentReservation &&
      isSameDateAsCurrentReservation &&
      props.showCurrentReservationLabel
    ) {
      label = `${label} (${props.control.context.t(
        globalBookingMessages.labels.currentReservationLabel
      )})`
    }
    return label
  }
  const datesOptions = []
  props?.dates?.forEach(date => {
    datesOptions.push({
      label: formatDateInUTC(date, 'MMMM dd'),
      value: formatDateInUTC(date),
    })
  })
  const timesOptions = []
  props?.timeSlots?.forEach(time => {
    timesOptions.push({
      label: generateTimeOptionsLabel(time),
      value: time.time,
      disabled: time.available < bookableQuantity,
    })
  })
  useEffect(() => {
    if (props.multiple && !props.currentSelectedDate) {
      props.control.screen.setState({
        [props.id]: { date: formatDateInUTC(props?.dates[0]) },
      })
    }
  }, [])

  return props?.dates?.length > 0 ? (
    <Card isCard={props.isCard} themeStyle={themeStyle}>
      {props.isCard && (
        <Type
          preset="textLarge"
          weight="bold"
          color={themeStyle?.cardHeadingColor ?? colors.deepBlue}
        >
          {props.control.context.t(getLabel(props.type))}
        </Type>
      )}

      <Options>
        {(props?.dates?.length > 1 || props?.isHotelBooking) && (
          <Option>
            <Type preset="textSmall" weight="bold">
              {props.control.context.t(globalBookingMessages.labels.selectDate)}
            </Type>
            <FieldRenderer
              control={props.control}
              screenTheme={screenTheme}
              item={buildSelectField({
                id: props.multiple ? `${props.id}.date` : 'date',
                props: {
                  initialLabel: props.dateInitialLabel,
                  options: (datesOptions as SelectProps) ?? [
                    {
                      label: props.control.context.t(
                        globalBookingMessages.labels.loading
                      ),
                      value: '',
                    },
                  ],
                  onChange: props.onChangeDate,
                },
              })}
            />
          </Option>
        )}

        <Option>
          {!timesOptions.length && !props.skipOwnError && (
            <Type color={colors.deepRed}>
              {props.control.context.t(
                globalBookingMessages.errors.noTimeSlotsAvailable
              )}
            </Type>
          )}
          <Type preset="textSmall" weight="bold">
            {props.control.context.t(globalBookingMessages.labels.selectTime)}
          </Type>
          <FieldRenderer
            control={props.control}
            screenTheme={screenTheme}
            item={buildSelectField({
              id: props.multiple ? `${props.id}.time` : 'time',
              props: {
                initialLabel: props.timeInitialLabel,
                options: (timesOptions as SelectProps) ?? [
                  {
                    label: props.control.context.t(
                      globalBookingMessages.labels.loading
                    ),
                    value: '',
                  },
                ],
              },
            })}
          />
        </Option>
      </Options>
    </Card>
  ) : (
    <Type preset="textLarge" color={colors.errorRed}>
      {props.control.context.t(
        globalBookingMessages.errors.noAvailabilityError
      )}
    </Type>
  )
}

import { CSSValue } from 'bl-common/src/elements/Typography/Typography'
import { Breakpoint } from 'bl-common/src/types/custom'

import { FlowField, FlowFieldLayout, FlowValue } from '../types'
import { AtLeastOne } from '../types/typeUtils'
import { buildBreadcrumbField, buildImageField, buildText } from './fields'

interface BuildHeading {
  title: FlowValue
  subTitle?: FlowValue
  includeBreadcrumb?: boolean
  imageSrc?: FlowValue
  imageLayout?: FlowFieldLayout
  small?: boolean
  mtTitle?: Partial<Record<Breakpoint, number>>
  mbTitle?: Partial<Record<Breakpoint, number>>
  mbSubtitle?: Partial<Record<Breakpoint, number>>
  showMobileCart?: boolean
  removeMarginTop?: boolean
  maxWidth?: CSSValue<'maxWidth'>
}

export const buildHeading = ({
  title,
  subTitle,
  includeBreadcrumb = false,
  imageSrc,
  imageLayout,
  small,
  mtTitle,
  mbTitle,
  mbSubtitle,
  showMobileCart = false,
  removeMarginTop = false,
  maxWidth,
}: BuildHeading): AtLeastOne<FlowField> => {
  const result: AtLeastOne<FlowField> = [
    buildText({
      props: {
        type: small ? 'headingMedium' : 'heading',
        value: title,
        maxWidth,
      },
      layout: {
        spacing: {
          mt: mtTitle
            ? mtTitle
            : { xs: includeBreadcrumb || removeMarginTop ? 0 : small ? 3 : 6 },
          mb: mbTitle
            ? mbTitle
            : { xs: subTitle ? 1.5 : 1, md: subTitle ? 1.5 : 2 },
        },
      },
    }),
  ]

  if (imageSrc) {
    result.splice(
      0,
      0,
      buildImageField({
        props: {
          alt: '',
          imageSrc,
          height: '200px',
        },
        layout: {
          ...imageLayout,
          spacing: {
            mb: { xs: 2 },
            ...imageLayout?.spacing,
          },
        },
      })
    )
  }

  if (includeBreadcrumb) {
    result.splice(
      0,
      0,
      buildBreadcrumbField({
        props: { showMobileCart },
        layout: {
          spacing: {
            mb: { xs: 2.5 },
          },
        },
      })
    )
  }

  if (subTitle) {
    result.push(
      buildText({
        props: {
          type: 'html',
          as: 'h2',
          value: subTitle,
          preset: 'subtitle',
          maxWidth: maxWidth ?? 660,
        },
        layout: {
          spacing: {
            mb: mbSubtitle ? mbSubtitle : { xs: 1, md: 2 },
          },
        },
      })
    )
  }

  return result
}

import { FlowControl, FlowRoot } from '../types'

type HookRendererProps = {
  flow: FlowRoot
  control: FlowControl
}

export const HookRenderer = ({ flow, control }: HookRendererProps) => {
  if (flow.watchHook) {
    flow.watchHook(flow, control)
  }

  return null
}

import { Rates } from 'bl-common/src/context/Currency/currency'
import {
  productIdToItemListId,
  productIdToItemListName,
  productIdToName,
} from 'bl-utils/src/analytics'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'

import { MassageState } from '../subflows/massage/types'

export const item_list_id = ({
  productId,
  isHotelBooking,
  isFloat,
}: {
  productId: string
  isHotelBooking?: boolean
  isFloat?: boolean
}) =>
  isHotelBooking
    ? `activities_${isFloat ? 'float' : 'massage'}`
    : productIdToItemListId[productId]

export const item_list_name = ({
  productId,
  isHotelBooking,
  isFloat,
}: {
  productId: string
  isHotelBooking?: boolean
  isFloat?: boolean
}) =>
  isHotelBooking
    ? `Activities ${isFloat ? 'Float' : 'Massage'}`
    : productIdToItemListName[productId]

export const triggerMultiSelectItemEvent = ({
  massages,
  isHotelBooking,
  isFloat,
  exchangeRates,
}: {
  massages: MassageState[]
  isHotelBooking?: boolean
  isFloat?: boolean
  exchangeRates?: Rates
}) => {
  massages?.forEach(massage => {
    const productId = massage.productNo
    const price =
      massage?.massageInfo?.price > 0 && exchangeRates?.EUR
        ? calcPrice(massage.massageInfo.price, exchangeRates.EUR)
        : 0

    const listId = item_list_id({ productId, isHotelBooking, isFloat })
    const listName = item_list_name({ productId, isHotelBooking, isFloat })
    const itemCategory = isHotelBooking ? 'Accommodation' : 'Day Visit'

    triggerEvent({
      event: 'select_item',
      ecommerce: {
        item_list_id: listId,
        item_list_name: listName,
        currency: 'EUR',
        items: [
          {
            item_id: productId,
            item_name: productIdToName[productId],
            currency: 'EUR',
            index: massage.selectedIndex,
            item_brand: 'Blue Lagoon',
            item_category: itemCategory,
            item_list_id: listId,
            item_list_name: listName,
            price,
            quantity: 1,
          },
        ],
      },
    })
  })
}

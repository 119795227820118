import styled from 'styled-components'

import { colors } from '../constants/colors'
import { CheckmarkIcon } from '../elements/Icons/CheckmarkIcon'
import { theme } from '../styles/theme'

const List = styled.ul({
  display: 'flex',
  flexDirection: 'column',
})

const ItemContainer = styled.div({
  marginTop: theme.spacing[0.5],
  marginBottom: theme.spacing[0.5],
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[1.5],
})

const Item = styled.li({
  fontSize: '16px',
})

export const CheckList = ({ items }) => {
  return (
    <List>
      {items.map((item, i) => (
        <ItemContainer key={i}>
          <CheckmarkIcon
            color={colors.deepBlue}
            width={18}
            height={18}
            style={{ flexShrink: 0 }}
          />
          <Item>{item}</Item>
        </ItemContainer>
      ))}
    </List>
  )
}

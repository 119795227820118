import styled from 'styled-components'

import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'

import { FlowComponent, FlowRichTextField } from '../../types'

type RichTextFieldProps = FlowComponent & FlowRichTextField['props']

const RichTextWrapper = styled.div<{
  style?: React.CSSProperties
}>(({ style }) => ({
  ...style,
}))

export const RichTextField = (props: RichTextFieldProps) => {
  const value =
    typeof props.value === 'function' ? props.value(props.control) : props.value

  if (value === undefined) {
    return null
  }

  return (
    <RichTextWrapper style={props.style}>
      <RichTextRenderer document={value} customOptions={props.customOptions} />
    </RichTextWrapper>
  )
}

import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:time.breadcrumbs.title',
    defaultMessage: 'Time',
    description: 'Time: Title of package step breadcrumb',
  },
  breadcrumbSubtitle: {
    id: 'spaBooking:time.breadcrumbs.subtitle',
    defaultMessage: '{formattedTime}',
    description: 'Time: Subtitle of package step breadcrumb',
  },
  title: {
    id: 'spaBooking:time.title',
    defaultMessage: '{admissionType} admission',
    description: 'Time: title of package step',
  },
  subscriptionAdmissionTitle: {
    id: 'spaBooking:admission.subscriptionAdmissionTitle',
    defaultMessage: 'Subscription admission',
    description: 'Admission: Title of subscription admission step',
  },
  subtitle: {
    id: 'spaBooking:time.subtitle',
    defaultMessage:
      'When would you like to visit the Blue Lagoon? Prices vary depending on time of day.',
    description: 'Time: Subtitle of package step',
  },
  subtitleRetreat: {
    id: 'spaBooking:time.subtitleRetreat',
    defaultMessage:
      'When would you like to visit the Blue Lagoon? Prices vary depending on time of day.',
    description: 'Time: Subtitle of package step for Retreat Spa',
  },
  subtitleMembership: {
    id: 'spaBooking:time.subtitleMembership',
    defaultMessage: 'When would you like to visit the Blue Lagoon?',
    description: 'Time: Subtitle of package step for membership',
  },
  drawerTitle: {
    id: 'spaBooking:time.drawer.title',
    defaultMessage: '{date} at {time}',
    description: 'Time: Selected package',
  },
  drawerSummaryChildren: {
    id: 'spaBooking:time.drawer.drawerSummaryChildren',
    defaultMessage: '{package} admission Children x {children}',
    description: 'Time: Selected package',
  },
  drawerSummaryAdultMobile: {
    id: 'spaBooking:time.drawer.drawerSummaryAdultMobile',
    defaultMessage: 'Adult x {adults}',
    description: 'Time: Selected package',
  },
  drawerSummaryChangingRoom: {
    id: 'spaBooking:time.drawer.drawerSummaryChangingRoom',
    defaultMessage: 'changing room',
    description: 'Confirmation drawer: changing room',
  },
  drawerSummaryChildrenMobile: {
    id: 'spaBooking:time.drawer.drawerSummaryChildrenMobile',
    defaultMessage: 'Children x {children}',
    description: 'Time: Selected package',
  },
  drawerFreeLabel: {
    id: 'spaBooking:time.drawer.drawerFreeLabel',
    defaultMessage: 'Free',
    description: 'Time: Admission for children is free label in drawer',
  },
  drawerChildrenAdmission: {
    id: 'spaBooking:time.drawer.drawerChildrenAdmission',
    defaultMessage: 'Children',
    description: 'Time: Admission for children in drawer',
  },
  drawerConfirmButton: {
    id: 'spaBooking:time.drawer.confirm',
    defaultMessage: 'Select and continue',
    description: 'Time: Confirm selection',
  },
  drawerContinueComfortButton: {
    id: 'spaBooking:time.drawer.drawerContinueComfortButton',
    defaultMessage: 'Continue with Comfort admission',
    description: 'Time: Confirm selection with comfort',
  },
  drawerDisclaimer: {
    id: 'spaBooking:time.drawer.disclaimer',
    defaultMessage:
      'This is the date and time we will be expecting you. You can use the lagoon until closing',
    description: 'Time: Disclaimer in drawer',
  },
  drawerRetreatDisclaimer: {
    id: 'spaBooking:time.drawer.drawerRetreatDisclaimer',
    defaultMessage:
      'Retreat admission has fixed 5-hour time slots. Arrive on time to fully enjoy the five hours.',
    description: 'Time: Disclaimer in drawer for Retreat spa',
  },
  drawerUpsellTitle: {
    id: 'spaBooking:time.drawer.upsellTitle',
    defaultMessage: 'Upgrade to Premium for only {price}*',
    description: 'Time: Upsell title in drawer',
  },
  drawerUpsellText: {
    id: 'spaBooking:time.drawer.upsellText',
    defaultMessage:
      'You get: An extra drink, a choice of an extra face mask and use of robe during your stay.',
    description: 'Time: Upsell text in drawer',
  },
  drawerUpsellDisclaimer: {
    id: 'spaBooking:time.drawer.upsellDisclaimer',
    defaultMessage: '*Per person',
    description: 'Time: Upsell disclaimer in drawer',
  },
  drawerUpsellButton: {
    id: 'spaBooking:time.drawer.upsellButton',
    defaultMessage: 'Upgrade to Premium (Total: {price})',
    description: 'Time: Upsell upgrade button',
  },
  drawerUpsellButtonMobile: {
    id: 'spaBooking:time.drawer.upsellButtonMobile',
    defaultMessage: 'Upgrade to Premium',
    description: 'Time: Upsell upgrade button',
  },
  cartSubtitle: {
    id: 'spaBooking:time.cart.subtitle',
    defaultMessage: 'We are expecting you on {date}',
    description: 'Time: Subtitle text about arrival time',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  missingPackage: {
    id: 'spaBooking:package.errors.missingPackage',
    defaultMessage: 'No package selected.',
    description: 'Package: Error message when no package is selected',
  },
  addAdmissionTitle: {
    id: 'spaBooking:package.errors.addAdmissionTitle',
    defaultMessage: 'No slots available',
    description:
      'Admission: Error message when no slots are availeble for selected time when adding to cart',
  },
  addAdmissionDescription: {
    id: 'spaBooking:package.errors.addAdmissionDescription',
    defaultMessage:
      'Unfortunately, there are no slots available for the selected time. Please select another time.',
    description:
      'Admission: Error message when no slots are availeble for selected time when adding to cart',
  },
})

export const timeMessages = {
  info,
  warnings,
  errors,
}

import styled, { useTheme } from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { PhoneInput } from 'bl-common/src/form/PhoneInput'
import { ErrorMessage } from 'bl-common/src/units/Form/styles'

import { FlowInputComponent, FlowPhoneInputField, FlowValue } from '../../types'
import { getFlowValue } from '../../utils'

type InputTextProps = FlowInputComponent &
  FlowPhoneInputField['props'] & {
    id: string
    error?: FlowValue
  }

const PhoneInputWrapper = styled.div<{ hasError?: boolean }>(
  ({ hasError }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '.form-control': {
      borderWidth: hasError && '2px !important',
    },
  })
)

const TopWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
})

const Label = styled(Type)({
  fontWeight: 'bold',
  marginBottom: '8px',
  flex: '0 0 auto',
})

export const PhoneInputField = ({ screenTheme, ...props }: InputTextProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.phoneInputField
  const errorColor = theme?.bookingEngine?.[screenTheme]?.errorMessage?.color

  const onChange = () => (value: string) => {
    props.control.screen.setState({
      [props.id]: value,
    })
  }

  // Sometimes the id has dot in it, e.g. "primaryGuest.phone",
  // which doesn't work with screen.state[props.id]
  // The code below splits the id by dot and then uses
  // reduce to get the value from screen.state
  const idArray = props.id.split('.')
  const value = idArray.reduce(
    (obj, key) => obj && obj[key],
    props.control.screen.state
  )

  return (
    <PhoneInputWrapper hasError={!!props?.error}>
      <TopWrapper>
        <Label as="label" size={{ xs: 14, md: 14 }} htmlFor={props.id}>
          {getFlowValue(props.label, props.control)}
          {props.required ? '*' : ''}
        </Label>
        {props?.error && (
          <ErrorMessage color={errorColor}>{props?.error}</ErrorMessage>
        )}
      </TopWrapper>
      <PhoneInput
        id={props.id}
        name={props.id}
        onChange={onChange}
        value={value}
        selectedCode={getFlowValue(props.countryCode, props.control)}
        themeStyle={themeStyle?.input}
        hasError={!!props?.error}
        gap={props.inputGap}
      />
    </PhoneInputWrapper>
  )
}

import { defineMessages } from 'react-intl'

export const preCheckinMessages = defineMessages({
  completeCheckin: {
    id: 'sharedFlows:preCheckinMessages.completeCheckin',
    defaultMessage: 'Complete your check-in',
    description: 'Pre check-in: Title for pre check-in completion',
  },
  checkBoxLabel: {
    id: 'sharedFlows:preCheckinMessages.checkBoxLabel',
    defaultMessage:
      'I have read all of the instructions in this Welcome guide.',
    description:
      'Pre check-in: Label for checkbox when completing pre check-in',
  },
  confirmButtonLabel: {
    id: 'sharedFlows:preCheckinMessages.confirmButtonLabel',
    defaultMessage: 'Complete Pre check-in',
    description: 'Pre check-in: Button text for completing pre check-in',
  },
  preCheckInClosedError: {
    id: 'sharedFlows:preCheckinMessages.preCheckInClosedError',
    defaultMessage: 'Pre check-in is not open yet, please check back later.',
    description: 'Pre check-in: Error message when pre check in is closed',
  },
  copyLinkButtonLabel: {
    id: 'sharedFlows:preCheckinMessages.copyLinkButtonLabel',
    defaultMessage: 'Copy link',
    description: 'Pre check-in: Button text for copying welcome guide link',
  },
  checkListItem1: {
    id: 'sharedFlows:preCheckinMessages.checkListItem1',
    defaultMessage: 'Be sure to remove jewelry',
    description: 'Pre check-in: item 1 in pre check-in checklist',
  },
  checkListItem2: {
    id: 'sharedFlows:preCheckinMessages.checkListItem2',
    defaultMessage: 'Shower before entering the Lagoon',
    description: 'Pre check-in: item 2 in pre check-in checklist',
  },
  checkListItem3: {
    id: 'sharedFlows:preCheckinMessages.checkListItem3',
    defaultMessage:
      'Tip: Leave hair conditioner in your hair before entering the Lagoon',
    description: 'Pre check-in: item 3 in pre check-in checklist',
  },
  checkListItem4: {
    id: 'sharedFlows:preCheckinMessages.checkListItem4',
    defaultMessage: 'Be careful of slippery pathways',
    description: 'Pre check-in: item 4 in pre check-in checklist',
  },
  checkListItem5: {
    id: 'sharedFlows:preCheckinMessages.checkListItem5',
    defaultMessage: 'Recycle your glass',
    description: 'Pre check-in: item 5 in pre check-in checklist',
  },
  checkInCompleteTitle: {
    id: 'sharedFlows:preCheckinMessages.checkInCompleteTitle',
    defaultMessage: 'Check-in Complete',
    description: 'Pre check-in: Title for pre check-in confirmation',
  },
  checkInCompleteInfo: {
    id: 'sharedFlows:preCheckinMessages.checkInCompleteInfo',
    defaultMessage:
      'You are now checked in. You may now use the express check-in line when you arrive at Blue Lagoon.',
    description: 'Pre check-in: Info for pre check-in confirmation',
  },
  copyLinkButtonInfo: {
    id: 'sharedFlows:preCheckinMessages.copyLinkButtonInfo',
    defaultMessage: 'Share this Welcome guide with your fellow travellers',
    description: 'Pre check-in: Button info for copying welcome guide link',
  },
  alreadyCheckedInTitle: {
    id: 'sharedFlows:preCheckinMessages.alreadyCheckedInTitle',
    defaultMessage: 'You are already checked in',
    description: 'Pre check-in: Title shown when user is already checked in',
  },
  alreadyCheckedInSubtitle: {
    id: 'sharedFlows:preCheckinMessages.alreadyCheckedInSubtitle',
    defaultMessage:
      'You have already successfully checked in. You can now go to the express check-in lane at The Blue lagoon Iceland.',
    description: 'Pre check-in: Subtitle shown when user is already checked in',
  },
  alreadyCheckedButtonLabel: {
    id: 'sharedFlows:preCheckinMessages.alreadyCheckedButtonLabel',
    defaultMessage: 'Close window',
    description:
      'Pre check-in: Button label shown when user is already checked in',
  },
  preCheckinFailedTitle: {
    id: 'sharedFlows:preCheckinMessages.preCheckinFailedTitle',
    defaultMessage: 'Check in Failed',
    description: 'Pre check-in: Error title shown when attempting to check in',
  },
  preCheckinFailedSubtitle: {
    id: 'sharedFlows:preCheckinMessages.preCheckinFailedSubtitle',
    defaultMessage:
      'We’re sorry but it seems like something went wrong with your pre check-in. Please try again and if the problem persists contact us at contact@bluelagoon.is',
    description: 'Pre check-in: Error shown when attempting to check in',
  },
  startOverLabel: {
    id: 'sharedFlows:preCheckinMessages.startOverLabel',
    defaultMessage: 'Start over',
    description:
      'Start over label for a button to restart pre check-in process',
  },
  linkCopiedLabel: {
    id: 'sharedFlows:preCheckinMessages.linkCopiedLabel',
    defaultMessage: 'Link copied',
    description: 'Pre check-in: Label for button when link copy is successful',
  },
})

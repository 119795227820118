import { useEffect } from 'react'

import { SearchInput } from 'bl-common/src/booking/SearchInput/SearchInput'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import {
  TransportationLocation,
  useDropoffLocationsQuery,
  usePickupLocationsQuery,
} from 'bl-graphql/src/generated/hooks-with-types'

import { Direction } from '../../types/Transportation'
import { busStops } from './busStops'
import LocationList from './LocationList'

type LocationSearchProps = {
  direction: Direction
  onSelectLocation: (location) => void
  selectedLocation: TransportationLocation | null
  placeholder: string
  listTitle: string
  isEditing?: boolean
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']
}

export const LocationSearch = ({
  direction,
  onSelectLocation,
  selectedLocation,
  placeholder,
  listTitle,
  isEditing,
  themeStyle,
}: LocationSearchProps) => {
  const { data: pickupData } = usePickupLocationsQuery({
    skip: direction === Direction.dropoff,
  })

  const { data: dropoffData } = useDropoffLocationsQuery({
    skip: direction === Direction.pickup,
  })

  useEffect(() => {
    // If editing the pickup we only have the id so we find the rest of the information
    if (isEditing && direction === Direction.pickup && pickupData) {
      const pickupIndexes = pickupData?.pickupLocations?.map(pl => pl.id)
      const selectedIndex = pickupIndexes?.indexOf(selectedLocation?.id)
      selectedLocation = pickupData?.pickupLocations[selectedIndex]
      onSelectLocation(selectedLocation)
    }

    // If editing the dropoff we only have the id so we find the rest of the information
    if (isEditing && direction === Direction.dropoff && dropoffData) {
      const dropoffIndexes = dropoffData?.dropoffLocations?.map(pl => pl.id)
      const selectedIndex = dropoffIndexes?.indexOf(selectedLocation?.id)
      selectedLocation = dropoffData?.dropoffLocations[selectedIndex]
      onSelectLocation(selectedLocation)
    }
  }, [isEditing, direction, pickupData, dropoffData])

  const data =
    (pickupData && pickupData.pickupLocations) ||
    (dropoffData && dropoffData.dropoffLocations)

  const locations = data || []

  const locationsWithoutKefAirport = locations.filter(
    location => location.name !== 'Keflavík Airport'
  )

  const popularLocations = Object.values(busStops)

  return (
    <>
      <SearchInput
        items={locationsWithoutKefAirport}
        onSelect={onSelectLocation}
        placeholder={placeholder}
        selectedItem={selectedLocation}
        themeStyle={themeStyle?.searchInput}
      />
      <LocationList
        title={listTitle}
        locations={popularLocations}
        selectedLocation={selectedLocation}
        onItemSelect={onSelectLocation}
        themeStyle={themeStyle?.locationList}
      />
    </>
  )
}

export default LocationSearch
